
class Panel {
  constructor ( name, fg, bg ) {
    this.name = name || null;
    this.fg = fg || null;
    this.bg = bg || null;

    this.min = Infinity;
    this.max = 0;
    this.round = Math.round;
    this.PR = Math.round( window.devicePixelRatio || 1 );

    this.WIDTH = 80 * this.PR;
    this.HEIGHT = 48 * this.PR;
    this.TEXT_X = 3 * this.PR;
    this.TEXT_Y = 2 * this.PR;
    this.GRAPH_X = 3 * this.PR;
    this.GRAPH_Y = 15 * this.PR;
    this.GRAPH_WIDTH = 74 * this.PR;
    this.GRAPH_HEIGHT = 30 * this.PR;

    this.canvas = document.createElement( 'canvas' );
    this.canvas.width = this.WIDTH;
    this.canvas.height = this.HEIGHT;
    if ( !this.bg ) this.canvas.style.cssText = 'width:80px;height:48px;opacity:0.0';
    else this.canvas.style.cssText = 'width:80px;height:48px;';

    this.context = this.canvas.getContext( '2d' );
    this.context.font = 'bold ' + ( 9 * this.PR ) + 'px Helvetica,Arial,sans-serif';
    this.context.textBaseline = 'top';

    this.context.fillStyle = this.bg;
    this.context.fillRect( 0, 0, this.WIDTH, this.HEIGHT );

    this.context.fillStyle = this.fg;
    this.context.fillText( this.name, this.TEXT_X, this.TEXT_Y );
    this.context.fillRect( this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH, this.GRAPH_HEIGHT );

    this.context.fillStyle = bg;
    this.context.globalAlpha = 0.9;
    this.context.fillRect( this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH, this.GRAPH_HEIGHT );
  }

  update ( value, maxValue ) {
    if ( !this.name ) return;
    this.min = Math.min( this.min, value );
    this.max = Math.max( this.max, value );

    this.context.fillStyle = this.bg;
    this.context.globalAlpha = 1;
    this.context.fillRect( 0, 0, this.WIDTH, this.GRAPH_Y );
    this.context.fillStyle = this.fg;
    this.context.fillText( Math.round( value ) + ' ' + this.name + ' (' + Math.round( this.min ) + '-' + Math.round( this.max ) + ')', this.TEXT_X, this.TEXT_Y );

    this.context.drawImage( this.canvas, this.GRAPH_X + this.PR, this.GRAPH_Y, this.GRAPH_WIDTH - this.PR, this.GRAPH_HEIGHT, this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH - this.PR, this.GRAPH_HEIGHT );

    this.context.fillRect( this.GRAPH_X + this.GRAPH_WIDTH - this.PR, this.GRAPH_Y, this.PR, this.GRAPH_HEIGHT );

    this.context.fillStyle = this.bg;
    this.context.globalAlpha = 0.9;
    this.context.fillRect( this.GRAPH_X + this.GRAPH_WIDTH - this.PR, this.GRAPH_Y, this.PR, Math.round( ( 1 - ( value / maxValue ) ) * this.GRAPH_HEIGHT ) );
  }
}
class Stats {
  constructor () {
    this.mode = 0;

    this.container = document.createElement( 'div' );
    this.container.style.cssText = 'position:fixed;top:0;left:0;cursor:pointer;opacity:0.9;z-index:10000';
    let self = this;
    this.container.addEventListener( 'click', function ( event ) {
      event.preventDefault();
      self.showPanel( ++self.mode % self.container.children.length );
    }, false );

    this.beginTime = ( performance || Date ).now();
    this.prevTime = this.beginTime;
    this.frames = 0;

    this.dom = this.container;

    this.addPanel( new Panel() );
    this.fpsPanel = this.addPanel( new Panel( 'FPS', '#0ff', '#002' ) );

    this.showPanel( 0 );
  }
  addPanel ( panel ) {
    this.container.appendChild( panel.canvas );
    return panel;
  }

  showPanel ( id ) {
    for ( var i = 0; i < this.container.children.length; i++ ) {
      this.container.children[ i ].style.display = i === id ? 'block' : 'none';
      if ( i ) this.container.children[ i ].style.visibility = 'visible';
    }

    this.mode = id;
  }
  update () {
    this.beginTime = this._end();
  }
  _begin () {
    this.beginTime = ( performance || Date ).now();
  }
  _end () {
    this.frames++;

    let time = ( performance || Date ).now();

    if ( time >= this.prevTime + 1000 ) {
      this.fpsPanel.update( ( this.frames * 1000 ) / ( time - this.prevTime ), 100 );

      this.prevTime = time;
      this.frames = 0;
    }

    return time;
  }
}
export { Stats as default };
