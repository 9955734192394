/*
 * `assets` module
 * ===============
 *
 * Declares static asset packs to be loaded using the `Phaser.Loader#pack`
 * method. Use this module to declare game assets.
 */
//  The Google WebFont Loader will look for this object, so create it before loading the script.
window.WebFontConfig = {
  //  'active' means all requested fonts have finished loading
  active: function () { /* fonts are loaded */ },

  //  The Google Fonts we want to load (specify as many as you like in the array)
  google: {
    families: [ 'Open Sans:b', 'Quicksand', 'Roboto Condensed', 'Roboto' ]
  }
};

export default {
  // -- Splash screen assets used by the Preloader.
  boot: [
    { key: 'Preloader', type: 'atlasJSONArray', textureURL: './Preloader.png', atlasURL: './Preloader.json', 'atlasData': null },
    { key: 'PopupLogin', type: 'atlasJSONArray', textureURL: 'PopupLogin.png', atlasURL: 'PopupLogin.json', 'atlasData': null },
    { key: 'Keypad', type: 'atlasJSONArray', textureURL: 'Keypad.png', atlasURL: 'Keypad.json', 'atlasData': null }
  ],
  window: [
    { type: 'script', key: 'webfont', url: '//ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js' },
    { key: 'WindowBG', type: 'image', url: './WindowBackground.png' },
    { key: 'WindowUnavailable', type: 'image', url: './PopupUnavailable.png' },
    { key: 'WindowFieldsMask', type: 'image', url: './WindowFieldsMask.png' },
    { key: 'PlayAnywhere', type: 'image', url: './playAnywhere.png' },
    { key: 'WinnerBG', type: 'image', url: './PopupBackground.png' },
    { key: 'SparklesAnimation', type: 'atlasJSONArray', textureURL: './SparklesAnimation.png', atlasURL: './SparklesAnimation.json', 'atlasData': null },
    { key: 'StarAnimation', type: 'atlasJSONArray', textureURL: './StarAnimation.png', atlasURL: './StarAnimation.json', 'atlasData': null },
    { key: 'LeaderboardFire', type: 'atlasJSONArray', textureURL: './LeaderboardFire.png', atlasURL: './LeaderboardFire.json', 'atlasData': null },
    { key: 'LeaderboardFireRoyal', type: 'atlasJSONArray', textureURL: './LeaderboardFireRoyal.png', atlasURL: './LeaderboardFireRoyal.json', 'atlasData': null },
    { key: 'CoinsAnimation', type: 'atlasJSONArray', textureURL: 'CoinsAnimation.png', atlasURL: 'CoinsAnimation.json', 'atlasData': null },
    { key: 'Popups', type: 'atlasJSONArray', textureURL: 'Popups.png', atlasURL: 'Popups.json', 'atlasData': null },
    { key: 'PopupJackpotEagleComunity', type: 'image', url: './PopupJackpot/logoComunity.png' },
    { key: 'PopupJackpotEaglePersonal', type: 'image', url: './PopupJackpot/logoPersonal.png' },
    { key: 'PopupJackpotBlueRays', type: 'image', url: './PopupJackpot/blueRays.png' },
    { key: 'PopupJackpotCongratulationsSign', type: 'image', url: './PopupJackpot/congratsSign.png' },
    { key: 'PopupJackpotPinkRays', type: 'image', url: './PopupJackpot/pinkRays.png' },
    { key: 'PopupJackpot', type: 'atlasJSONArray', textureURL: 'PopupJackpot.png', atlasURL: 'PopupJackpot.json', 'atlasData': null },
    { key: 'PopupWinner', type: 'atlasJSONArray', textureURL: 'PopupWinner.png', atlasURL: 'PopupWinner.json', 'atlasData': null },
    { key: 'JackpotIcons', type: 'atlasJSONArray', textureURL: 'Icons.png', atlasURL: 'Icons.json', 'atlasData': null },
    { key: 'Window', type: 'atlasJSONArray', textureURL: 'Window.png', atlasURL: 'Window.json', 'atlasData': null },
    { key: 'GameIcons', type: 'atlasJSONArray', textureURL: 'GameIcons.png', atlasURL: 'GameIcons.json', 'atlasData': null },
    { key: 'JackpotTitles', type: 'atlasJSONArray', textureURL: 'JackpotTitles.png', atlasURL: 'JackpotTitles.json', 'atlasData': null },
    { key: 'JackpotFrames', type: 'atlasJSONArray', textureURL: 'JackpotFrames.png', atlasURL: 'JackpotFrames.json', 'atlasData': null },
    { type: 'bitmapFont', key: 'JackpotPopupFont', textureURL: '../fonts/JackpotPopupFont.png', atlasURL: '../fonts/JackpotPopupFont.xml' },
    { type: 'bitmapFont', key: 'JackpotRoyalFont', textureURL: '../fonts/JackpotRoyalFont.png', atlasURL: '../fonts/JackpotRoyalFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFont', textureURL: '../fonts/TournamentTimeFont.png', atlasURL: '../fonts/TournamentTimeFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFontMiddle', textureURL: '../fonts/TournamentTimeFontMiddle.png', atlasURL: '../fonts/TournamentTimeFontMiddle.xml' },
    { type: 'bitmapFont', key: 'TournamentWinFont', textureURL: '../fonts/TournamentWinFont.png', atlasURL: '../fonts/TournamentWinFont.xml' },
    { type: 'audio', key: 'jackpotFireSound', urls: [ '../audio/jackpotFireSound.ogg', '../audio/jackpotFireSound.mp3', '../audio/jackpotFireSound.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotBG', urls: [ '../audio/bg.ogg', '../audio/bg.mp3', '../audio/bg.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotEntrance', urls: [ '../audio/entrance.ogg', '../audio/entrance.mp3', '../audio/entrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotVoiceEntrance', urls: [ '../audio/voiceEntrance.ogg', '../audio/voiceEntrance.mp3', '../audio/voiceEntrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin1', urls: [ '../audio/win_reel_1.ogg', '../audio/win_reel_1.mp3', '../audio/win_reel_1.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin2', urls: [ '../audio/win_reel_2.ogg', '../audio/win_reel_2.mp3', '../audio/win_reel_2.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin3', urls: [ '../audio/win_reel_3.ogg', '../audio/win_reel_3.mp3', '../audio/win_reel_3.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongrats', urls: [ '../audio/grand_jackpot.ogg', '../audio/grand_jackpot.mp3', '../audio/grand_jackpot.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongratsVoice', urls: [ '../audio/congrats_03.ogg', '../audio/congrats_03.mp3', '../audio/congrats_03.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotYouWinVoice', urls: [ '../audio/you_win_02.ogg', '../audio/you_win_02.mp3', '../audio/you_win_02.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCoins', urls: [ '../audio/coins_loop.ogg', '../audio/coins_loop.mp3', '../audio/coins_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleOut', urls: [ '../audio/eagle_out.ogg', '../audio/eagle_out.mp3', '../audio/eagle_out.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleIn', urls: [ '../audio/eagle_swoosh.ogg', '../audio/eagle_swoosh.mp3', '../audio/eagle_swoosh.acc' ], autoDecode: true },
    { type: 'audio', key: 'flip', urls: [ '../audio/flip.ogg', '../audio/flip.mp3', '../audio/flip.acc' ], autoDecode: true },
    { type: 'audio', key: 'musicLoop', urls: [ '../audio/music_loop.ogg', '../audio/music_loop.mp3', '../audio/music_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleShow', urls: [ '../audio/win_prize_popup_eagle.ogg', '../audio/win_prize_popup_eagle.mp3', '../audio/win_prize_popup_eagle.acc' ], autoDecode: true },
    { type: 'audio', key: 'wooshBottom', urls: [ '../audio/woosh_bottom.ogg', '../audio/woosh_bottom.mp3', '../audio/woosh_bottom.acc' ], autoDecode: true },
    { type: 'audio', key: 'anticipationFire', urls: [ '../audio/anticipationFire.ogg', '../audio/anticipationFire.mp3', '../audio/anticipationFire.acc' ], autoDecode: true }
  ],
  windowSandman: [
    { type: 'script', key: 'webfont', url: '//ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js' },
    { key: 'WindowBG', type: 'image', url: './WindowBackground.png' },
    { key: 'WindowUnavailable', type: 'image', url: './PopupUnavailable.png' },
    { key: 'WindowFieldsMask', type: 'image', url: './WindowFieldsMask.png' },
    { key: 'PlayAnywhere', type: 'image', url: './playAnywhere3.png' },
    { key: 'WinnerBG', type: 'image', url: './PopupBackground.png' },
    { key: 'SparklesAnimation', type: 'atlasJSONArray', textureURL: './SparklesAnimation.png', atlasURL: './SparklesAnimation.json', 'atlasData': null },
    { key: 'StarAnimation', type: 'atlasJSONArray', textureURL: './StarAnimation.png', atlasURL: './StarAnimation.json', 'atlasData': null },
    { key: 'LeaderboardFire', type: 'atlasJSONArray', textureURL: './LeaderboardFire.png', atlasURL: './LeaderboardFire.json', 'atlasData': null },
    { key: 'LeaderboardFireRoyal', type: 'atlasJSONArray', textureURL: './LeaderboardFireRoyal.png', atlasURL: './LeaderboardFireRoyal.json', 'atlasData': null },
    { key: 'CoinsAnimation', type: 'atlasJSONArray', textureURL: 'CoinsAnimation.png', atlasURL: 'CoinsAnimation.json', 'atlasData': null },
    { key: 'Popups', type: 'atlasJSONArray', textureURL: 'Popups.png', atlasURL: 'Popups.json', 'atlasData': null },
    { key: 'PopupJackpotEagleComunity', type: 'image', url: './PopupJackpot/logoComunity3.png' },
    { key: 'PopupJackpotEaglePersonal', type: 'image', url: './PopupJackpot/logoPersonal3.png' },
    { key: 'PopupJackpotBlueRays', type: 'image', url: './PopupJackpot/blueRays.png' },
    { key: 'PopupJackpotCongratulationsSign', type: 'image', url: './PopupJackpot/congratsSign.png' },
    { key: 'PopupJackpotPinkRays', type: 'image', url: './PopupJackpot/pinkRays.png' },
    { key: 'PopupJackpot', type: 'atlasJSONArray', textureURL: 'PopupJackpot.png', atlasURL: 'PopupJackpot.json', 'atlasData': null },
    { key: 'PopupWinner', type: 'atlasJSONArray', textureURL: 'PopupWinner.png', atlasURL: 'PopupWinner.json', 'atlasData': null },
    { key: 'JackpotIcons', type: 'atlasJSONArray', textureURL: 'Icons.png', atlasURL: 'Icons.json', 'atlasData': null },
    { key: 'Window', type: 'atlasJSONArray', textureURL: 'Window.png', atlasURL: 'Window.json', 'atlasData': null },
    { key: 'Window2', type: 'atlasJSONArray', textureURL: 'Window2.png', atlasURL: 'Window2.json', 'atlasData': null },
    { key: 'GameIcons', type: 'atlasJSONArray', textureURL: 'GameIcons.png', atlasURL: 'GameIcons.json', 'atlasData': null },
    { key: 'JackpotTitles', type: 'atlasJSONArray', textureURL: 'JackpotTitles.png', atlasURL: 'JackpotTitles.json', 'atlasData': null },
    { key: 'JackpotFrames', type: 'atlasJSONArray', textureURL: 'JackpotFrames.png', atlasURL: 'JackpotFrames.json', 'atlasData': null },
    { type: 'bitmapFont', key: 'JackpotPopupFont', textureURL: '../fonts/JackpotPopupFont.png', atlasURL: '../fonts/JackpotPopupFont.xml' },
    { type: 'bitmapFont', key: 'JackpotRoyalFont', textureURL: '../fonts/JackpotRoyalFont.png', atlasURL: '../fonts/JackpotRoyalFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFont', textureURL: '../fonts/TournamentTimeFont.png', atlasURL: '../fonts/TournamentTimeFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFontMiddle', textureURL: '../fonts/TournamentTimeFontMiddle.png', atlasURL: '../fonts/TournamentTimeFontMiddle.xml' },
    { type: 'bitmapFont', key: 'TournamentWinFont', textureURL: '../fonts/TournamentWinFont.png', atlasURL: '../fonts/TournamentWinFont.xml' },
    { type: 'audio', key: 'jackpotFireSound', urls: [ '../audio/jackpotFireSound.ogg', '../audio/jackpotFireSound.mp3', '../audio/jackpotFireSound.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotBG', urls: [ '../audio/bg.ogg', '../audio/bg.mp3', '../audio/bg.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotEntrance', urls: [ '../audio/entrance.ogg', '../audio/entrance.mp3', '../audio/entrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotVoiceEntrance', urls: [ '../audio/voiceEntrance.ogg', '../audio/voiceEntrance.mp3', '../audio/voiceEntrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin1', urls: [ '../audio/win_reel_1.ogg', '../audio/win_reel_1.mp3', '../audio/win_reel_1.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin2', urls: [ '../audio/win_reel_2.ogg', '../audio/win_reel_2.mp3', '../audio/win_reel_2.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin3', urls: [ '../audio/win_reel_3.ogg', '../audio/win_reel_3.mp3', '../audio/win_reel_3.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongrats', urls: [ '../audio/grand_jackpot.ogg', '../audio/grand_jackpot.mp3', '../audio/grand_jackpot.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongratsVoice', urls: [ '../audio/congrats_03.ogg', '../audio/congrats_03.mp3', '../audio/congrats_03.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotYouWinVoice', urls: [ '../audio/you_win_02.ogg', '../audio/you_win_02.mp3', '../audio/you_win_02.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCoins', urls: [ '../audio/coins_loop.ogg', '../audio/coins_loop.mp3', '../audio/coins_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleOut', urls: [ '../audio/eagle_out.ogg', '../audio/eagle_out.mp3', '../audio/eagle_out.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleIn', urls: [ '../audio/eagle_swoosh.ogg', '../audio/eagle_swoosh.mp3', '../audio/eagle_swoosh.acc' ], autoDecode: true },
    { type: 'audio', key: 'flip', urls: [ '../audio/flip.ogg', '../audio/flip.mp3', '../audio/flip.acc' ], autoDecode: true },
    { type: 'audio', key: 'musicLoop', urls: [ '../audio/music_loop.ogg', '../audio/music_loop.mp3', '../audio/music_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleShow', urls: [ '../audio/win_prize_popup_eagle.ogg', '../audio/win_prize_popup_eagle.mp3', '../audio/win_prize_popup_eagle.acc' ], autoDecode: true },
    { type: 'audio', key: 'wooshBottom', urls: [ '../audio/woosh_bottom.ogg', '../audio/woosh_bottom.mp3', '../audio/woosh_bottom.acc' ], autoDecode: true },
    { type: 'audio', key: 'anticipationFire', urls: [ '../audio/anticipationFire.ogg', '../audio/anticipationFire.mp3', '../audio/anticipationFire.acc' ], autoDecode: true }
  ],
  windowTexas: [
    { type: 'script', key: 'webfont', url: '//ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js' },
    { key: 'BGVideo', type: 'video', urls: './BGVideo.mp4' },
    { key: 'WindowBG', type: 'image', url: './WindowBackground2.png' },
    { key: 'WindowUnavailable', type: 'image', url: './PopupUnavailable.png' },
    { key: 'WindowFieldsMask', type: 'image', url: './WindowFieldsMask.png' },
    { key: 'PlayAnywhere', type: 'image', url: './playAnywhere2.png' },
    { key: 'WinnerBG', type: 'image', url: './PopupBackground2.png' },
    { key: 'SparklesAnimation', type: 'atlasJSONArray', textureURL: './SparklesAnimation.png', atlasURL: './SparklesAnimation.json', 'atlasData': null },
    { key: 'StarAnimation', type: 'atlasJSONArray', textureURL: './StarAnimation.png', atlasURL: './StarAnimation.json', 'atlasData': null },
    { key: 'LeaderboardFire', type: 'atlasJSONArray', textureURL: './LeaderboardFire.png', atlasURL: './LeaderboardFire.json', 'atlasData': null },
    { key: 'LeaderboardFireRoyal', type: 'atlasJSONArray', textureURL: './LeaderboardFireRoyal.png', atlasURL: './LeaderboardFireRoyal.json', 'atlasData': null },
    { key: 'CoinsAnimation', type: 'atlasJSONArray', textureURL: 'CoinsAnimation.png', atlasURL: 'CoinsAnimation.json', 'atlasData': null },
    { key: 'Popups', type: 'atlasJSONArray', textureURL: 'Popups.png', atlasURL: 'Popups.json', 'atlasData': null },
    { key: 'PopupJackpotEagleComunity', type: 'image', url: './PopupJackpot/logoComunity2.png' },
    { key: 'PopupJackpotEaglePersonal', type: 'image', url: './PopupJackpot/logoPersonal2.png' },
    { key: 'PopupJackpotBlueRays', type: 'image', url: './PopupJackpot/blueRays.png' },
    { key: 'PopupJackpotCongratulationsSign', type: 'image', url: './PopupJackpot/congratsSign.png' },
    { key: 'PopupJackpotPinkRays', type: 'image', url: './PopupJackpot/pinkRays.png' },
    { key: 'PopupJackpot', type: 'atlasJSONArray', textureURL: 'PopupJackpot.png', atlasURL: 'PopupJackpot.json', 'atlasData': null },
    { key: 'PopupWinner', type: 'atlasJSONArray', textureURL: 'PopupWinner.png', atlasURL: 'PopupWinner.json', 'atlasData': null },
    { key: 'JackpotIcons', type: 'atlasJSONArray', textureURL: 'Icons.png', atlasURL: 'Icons.json', 'atlasData': null },
    { key: 'Window', type: 'atlasJSONArray', textureURL: 'Window.png', atlasURL: 'Window.json', 'atlasData': null },
    { key: 'GameIcons', type: 'atlasJSONArray', textureURL: 'GameIcons.png', atlasURL: 'GameIcons.json', 'atlasData': null },
    { key: 'JackpotTitles', type: 'atlasJSONArray', textureURL: 'JackpotTitles.png', atlasURL: 'JackpotTitles.json', 'atlasData': null },
    { key: 'JackpotFrames', type: 'atlasJSONArray', textureURL: 'JackpotFrames.png', atlasURL: 'JackpotFrames.json', 'atlasData': null },
    { type: 'bitmapFont', key: 'JackpotPopupFont', textureURL: '../fonts/JackpotPopupFont.png', atlasURL: '../fonts/JackpotPopupFont.xml' },
    { type: 'bitmapFont', key: 'JackpotRoyalFont', textureURL: '../fonts/JackpotRoyalFont.png', atlasURL: '../fonts/JackpotRoyalFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFont', textureURL: '../fonts/TournamentTimeFont.png', atlasURL: '../fonts/TournamentTimeFont.xml' },
    { type: 'bitmapFont', key: 'TournamentTimeFontMiddle', textureURL: '../fonts/TournamentTimeFontMiddle.png', atlasURL: '../fonts/TournamentTimeFontMiddle.xml' },
    { type: 'bitmapFont', key: 'TournamentWinFont', textureURL: '../fonts/TournamentWinFont.png', atlasURL: '../fonts/TournamentWinFont.xml' },
    { type: 'audio', key: 'jackpotFireSound', urls: [ '../audio/jackpotFireSound.ogg', '../audio/jackpotFireSound.mp3', '../audio/jackpotFireSound.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotBG', urls: [ '../audio/bg.ogg', '../audio/bg.mp3', '../audio/bg.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotEntrance', urls: [ '../audio/entrance.ogg', '../audio/entrance.mp3', '../audio/entrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotVoiceEntrance', urls: [ '../audio/voiceEntrance.ogg', '../audio/voiceEntrance.mp3', '../audio/voiceEntrance.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin1', urls: [ '../audio/win_reel_1.ogg', '../audio/win_reel_1.mp3', '../audio/win_reel_1.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin2', urls: [ '../audio/win_reel_2.ogg', '../audio/win_reel_2.mp3', '../audio/win_reel_2.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotWin3', urls: [ '../audio/win_reel_3.ogg', '../audio/win_reel_3.mp3', '../audio/win_reel_3.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongrats', urls: [ '../audio/grand_jackpot.ogg', '../audio/grand_jackpot.mp3', '../audio/grand_jackpot.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCongratsVoice', urls: [ '../audio/congrats_03.ogg', '../audio/congrats_03.mp3', '../audio/congrats_03.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotYouWinVoice', urls: [ '../audio/you_win_02.ogg', '../audio/you_win_02.mp3', '../audio/you_win_02.acc' ], autoDecode: true },
    { type: 'audio', key: 'jackpotCoins', urls: [ '../audio/coins_loop.ogg', '../audio/coins_loop.mp3', '../audio/coins_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleOut', urls: [ '../audio/eagle_out.ogg', '../audio/eagle_out.mp3', '../audio/eagle_out.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleIn', urls: [ '../audio/eagle_swoosh.ogg', '../audio/eagle_swoosh.mp3', '../audio/eagle_swoosh.acc' ], autoDecode: true },
    { type: 'audio', key: 'flip', urls: [ '../audio/flip.ogg', '../audio/flip.mp3', '../audio/flip.acc' ], autoDecode: true },
    { type: 'audio', key: 'musicLoop', urls: [ '../audio/music_loop.ogg', '../audio/music_loop.mp3', '../audio/music_loop.acc' ], autoDecode: true },
    { type: 'audio', key: 'eagleShow', urls: [ '../audio/win_prize_popup_eagle.ogg', '../audio/win_prize_popup_eagle.mp3', '../audio/win_prize_popup_eagle.acc' ], autoDecode: true },
    { type: 'audio', key: 'wooshBottom', urls: [ '../audio/woosh_bottom.ogg', '../audio/woosh_bottom.mp3', '../audio/woosh_bottom.acc' ], autoDecode: true },
    { type: 'audio', key: 'anticipationFire', urls: [ '../audio/anticipationFire.ogg', '../audio/anticipationFire.mp3', '../audio/anticipationFire.acc' ], autoDecode: true }
  ]
};
