/*
 * Popups
 * ======
 *
 * Popups API
 */
import PluginPopupService from '../plugins/PluginPopupService.js';

class Popups {
  constructor () {
    this.popupService = null;

    this.TYPE = {
      NoInternetConnection: 1,
      Error: 2,
      Login: 11,
      JackpotMinor: 50,
      JackpotMajor: 51,
      JackpotGrand: 52,
      Jackpot: 100,
      Winner: 101
    };
  }

  getPopupService ( game ) {
    if ( !game ) return null;

    if ( !this.popupService )
      this.popupService = game.plugins.add( PluginPopupService );

    return this.popupService;
  }
}
export default new Popups();
