/*
 * Preloader state
 * ===============
 *
 * Takes care of loading the main game assets, including graphics and sound
 * effects, while displaying a busy splash screen.
 */

import assets from '../assets';
import BaseGarbageCollectionState from '../base/BaseGarbageCollectionState.js';
import get from '../objects/Get.js';
import server from '../objects/Server.js';
export default class Preloader extends BaseGarbageCollectionState {
  preload () {
    this.showSplashScreen();
    if ( get.projectType ) server.projectType = get.projectType;
    let loadString = 'window';
    if ( server.projectType === 2 ) loadString = 'windowTexas';
    else if ( server.projectType === 3 ) loadString = 'windowSandman';

    this.load.pack( loadString, null, assets );
  }

  init () {
    this._loadingProgressText = null;
    this._onLoginCallback = null;
  }
  shutdown () {
    if ( this.bg ) this.bg.destroy( true ); this.bg = null;
    if ( this.gradientBitmap ) this.gradientBitmap.destroy(); this.gradientBitmap = null;
    if ( this.leftEdge ) this.leftEdge.destroy( true ); this.leftEdge = null;
    if ( this.gradient ) this.gradient.destroy( true ); this.gradient = null;
    if ( this._loadingProgressText ) this._loadingProgressText.destroy( true ); this._loadingProgressText = null;
    this._onLoginCallback = null;
    super.shutdown();
  }

  create ( ) {
    this.state.start( 'Window', true, false );
  }

  update () {
    if ( this._loadingProgressText ) this._loadingProgressText.setText( 100 + '%' );
  }
  loadUpdate () {
    if ( !this._loadingProgressText ) return;
    this._loadingProgressText.setText( this.load.progress + '%' );
  }

  showSplashScreen () {
    this.bg = this.make.tileSprite( 0, 0, this.world.width, this.world.height, 'Preloader', 'BgBase.jpg' );
    this.add.existing( this.bg );

    this.gradientBitmap = this.game.make.bitmapData( this.world.width, this.world.height );
    this.leftEdge = this.game.make.image( 0, 0, 'Preloader', 'EdgeGradient.png' );
    this.gradientBitmap.draw( this.leftEdge, 0, 0, this.leftEdge.width, this.world.height );
    this.leftEdge.scale.x = -1;
    this.gradientBitmap.draw( this.leftEdge, this.world.width + this.leftEdge.width, 0, this.leftEdge.width, this.world.height );
    this.gradient = this.game.make.image( 0, 0, this.gradientBitmap );
    this.add.existing( this.gradient );

    let progressBg = this.make.image( this.world.centerX, this.world.centerY, 'Preloader', 'LoadingBase.png' );
    progressBg.anchor.set( 0, 0.5 );
    progressBg.x -= progressBg.width * 0.5;
    this.add.existing( progressBg );
    this.localGarbage.push( progressBg );

    let progressFill = this.add.image( this.world.centerX, this.world.centerY, 'Preloader', 'LoadingFill.png' );
    progressFill.anchor.set( 0, 0.5 );
    progressFill.x -= progressFill.width * 0.5;
    this.load.setPreloadSprite( progressFill );
    this.localGarbage.push( progressFill );

    this._loadingProgressText = this.add.text( this.world.centerX, this.world.centerY + progressBg.height * 1.5, '0%' );
    this._loadingProgressText.anchor.set( 0.5 );
    this._loadingProgressText.fill = '#ffffff';
  }
}
