/*
 * Keypad
 * =======
 *
 * Kaypad Object for Login
 */
import BaseGarbageCollectionGroup from '../base/BaseGarbageCollectionGroup.js';
export default class Keypad extends BaseGarbageCollectionGroup {
  constructor ( game, width, height, positionX, positionY, delegate ) {
    super( game );

    this.groupWidth = width || 300;
    this.groupHeight = height || 400;
    this.positionX = positionX;
    this.positionY = positionY;
    /**
     * KeypadNumberClicked(number);
     * KeypadDeleteClicked();
     * KeypadEnterClicked();
     */
    this.delegate = delegate || null;

    this._generateKeypad();
  }
  destroy () {
    delete this.groupWidth;
    delete this.groupHeight;
    delete this.delegate;
    if ( this.bmd ) this.bmd.destroy(); this.bmd = null;
    if ( this.background ) this.background.destroy( true ); this.background = null;
    if ( this.contextGroup ) this.contextGroup.removeAll( true ); this.contextGroup.destroy( true ); this.contextGroup = null;
    super.destroy();
  }
  _generateKeypad () {
    this.bmd = this.game.make.bitmapData( this.groupWidth, this.groupHeight );
    this.bmd.ctx.beginPath();
    this.bmd.ctx.rect( 0, 0, this.groupWidth, this.groupHeight );
    this.bmd.ctx.fillStyle = '#000000';
    this.bmd.ctx.fill();

    this.background = this.game.add.sprite( this.positionX, this.positionY, this.bmd );
    this.background.anchor.set( 0.0, 1.0 );
    this.background.alpha = 0.5;
    this.background.inputEnabled = true;
    this.background.input.priorityID = 1;
    this.add( this.background );

    this.contextGroup = this.game.add.group();

    let widthStep = this.groupWidth / 4;
    let heightStep = this.groupHeight / 4;

    // 0 - 0 btn
    // 1 - number btn
    // 2 - delete btn
    // 3 - enter btn
    let btnTypes = [
      [ 1, 1, 1, 2 ],
      [ 1, 1, 1, 3 ],
      [ 1, 1, 1, 3 ],
      [ -1, 0, 0, -1 ]
    ];

    for ( let r = 0; r < 4; r++ ) // rows
      for ( let c = 0; c < 4; c++ ) { // columns
        let btnType = btnTypes[ r ][ c ];
        let btnFileName = 'btnDarkSmall';
        let btnX = this.background.x + ( c + 0.5 ) * widthStep;
        let btnY = this.positionY - this.groupHeight + ( r + 0.5 ) * heightStep;
        let textY = this.positionY - this.groupHeight + ( r + 0.55 ) * heightStep;
        let textValue = '';
        let textStyle = { font: 0.09 * this.groupWidth + 'px Open Sans', fill: '#ffffff', align: 'center' };
        let skip = false;
        switch ( btnType ) {
          case 0: {
            if ( btnTypes[ r ][ c - 1 ] ) skip = true;
            else btnX -= 0.5 * widthStep;

            btnType = 10;
            textValue = '0';
            btnFileName = 'btnDark';
            break;
          }
          case 1: {
            let value = r * 3 + c + 1;
            btnType = 10 + value;
            textValue = value.toString();
            btnFileName = 'btnDarkSmall';
            break;
          }
          case 2: {
            textValue = 'Delete';
            textStyle = { font: 0.045 * this.groupWidth + 'px Open Sans', fill: '#ffffff', align: 'center' };
            btnFileName = 'btnLightSmall';
            break;
          }
          case 3: {
            if ( btnTypes[ r - 1 ][ c ] === 3 ) skip = true;
            else {
              btnY += 0.5 * heightStep;
              textY += 0.5 * heightStep;
            }
            textValue = 'Enter';
            textStyle = { font: 0.045 * this.groupWidth + 'px Open Sans', fill: '#ffffff', align: 'center' };
            btnFileName = 'btnLight';
            break;
          }
          default:
            skip = true;
            break;
        }

        if ( skip ) continue;

        let btn = this.game.make.button( btnX, btnY, 'Keypad', this._btnClick, this );
        btn.setFrames( btnFileName + '.png', btnFileName + '.png', btnFileName + 'Pressed.png', btnFileName + '.png' );
        btn.anchor.set( 0.5 );
        btn.data = btnType;
        btn.input.priorityID = 2;
        this.contextGroup.add( btn );
        this.localGarbage.push( btn );

        let number = this.game.make.text( btnX, textY, textValue, textStyle );
        number.anchor.set( 0.5 );
        this.contextGroup.add( number );
        this.localGarbage.push( number );
      }
  }
  _btnClick ( target, event ) {
    if ( !this.delegate ) return;

    let type = parseInt( target.data );
    let number = 0;
    if ( type >= 10 ) {
      number = type - 10;
      type = 0;
    }

    switch ( type ) {
      case 0: {
        this.delegate.KeypadNumberClicked( number );
        break;
      }
      case 1: {
        this.delegate.KeypadNumberClicked( number );
        break;
      }
      case 2: {
        this.delegate.KeypadDeleteClicked();
        break;
      }
      case 3: {
        this.delegate.KeypadEnterClicked();
        break;
      }
      default:
        break;
    }
  }
}
