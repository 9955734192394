/*
 * PluginPopupService
 * ==========
 *
 * Popup Service Plugin
 */
import PopupNoInternetConnection from '../popup/PopupNoInternetConnection.js';
import PopupLogin from '../popup/PopupLogin.js';
import PopupJackpot from '../popup/PopupJackpot.js';
import PopupWinner from '../popup/PopupWinner.js';
import popups from '../objects/Popups.js';
import BaseGarbageCollectionPlugin from './../base/BaseGarbageCollectionPlugin.js';
export default class PluginPopupService extends BaseGarbageCollectionPlugin {
  constructor ( game ) {
    super( game, game.plugins );
    this.type = null;
    this.popupStack = [];
    this.activePopup = null;
    this.removingPopup = false;
  }
  destroy () {
    this.clean();
    super.destroy();
  }

  update () {
    if ( this.removingPopup ) return;
    if ( this.activePopup && this.activePopup.isClosed ) {
      this._removeActivePopup();
      return;
    }
    if ( this.activePopup || !this.popupStack || !this.popupStack.length ) return;

    this._showPopupFromStack();
  }

  addPopup ( type, params, sounds ) {
    // exclude double Internet Connection popup
    if ( type === popups.TYPE.NoInternetConnection && !params ) {
      let isCurrent = this.type === parseInt( popups.TYPE.NoInternetConnection );
      let isAny = false;
      this.popupStack.forEach( element => {
        if ( parseInt( element[ 'type' ] ) === parseInt( popups.TYPE.NoInternetConnection ) ) isAny = true;
      } );
      if ( isCurrent || isAny ) return;
    }

    this.popupStack.push( { 'type': type, 'params': params, 'sounds': sounds } );
  }

  isActivePopup () {
    return this.activePopup !== null;
  }

  isPopupStackFull () {
    return this.popupStack.length;
  }
  clean () {
    this.popupStack.splice( 0, this.popupStack.length );
    delete this.popupStack; this.popupStack = null;
    this._removeActivePopup();
  }
  _removeActivePopup () {
    this.removingPopup = true;
    if ( this.activePopup ) {
      this.activePopup.onDestroy.add( function () {
        this.removingPopup = false;
        this.activePopup = null;
      }, this );
      this.activePopup.destroy();
      this.activePopup = null;
    }
    if ( this.popupStack && this.popupStack.length ) this.type = 0;
  }

  _showPopupFromStack () {
    let popupInfo = this.popupStack[ 0 ];
    this.type = parseInt( popupInfo[ 'type' ] );
    let params = popupInfo[ 'params' ];
    let sounds = popupInfo[ 'sounds' ];
    if ( this.activePopup ) this.activePopup.destroy(); this.activePopup = null;
    switch ( this.type ) {
      case popups.TYPE.NoInternetConnection:
        this.activePopup = new PopupNoInternetConnection( this.game, params, sounds );
        break;
      case popups.TYPE.Login:
        this.activePopup = new PopupLogin( this.game, params, sounds );
        break;
      case popups.TYPE.Jackpot:
        this.activePopup = new PopupJackpot( this.game, params, sounds );
        break;
      case popups.TYPE.Winner:
        this.activePopup = new PopupWinner( this.game, params, sounds );
        break;
      default:
        break;
    }
    this.popupStack.shift();
    popupInfo = null;
    params = null;
  }
}
