/*
* PluginDecimalText
* ====
*
* Plugin for text Object with custom behaviour
*/
import BaseGarbageCollectionPluginText from './../base/BaseGarbageCollectionPluginText.js';
export default class PluginDecimalText extends BaseGarbageCollectionPluginText {
  constructor ( game ) {
    super( game, game.plugins );

    this.text = null;
    this._finalValue = 0;
    this._updateStep = 1;
    this._continueslyUpdateStep = 0;

    this._stringBehind = '';
    this._stringFront = '';
  }
  init ( x, y, number, style, fontName, fontSize, stringFront = '', stringBehind = '' ) {
    this._stringBehind = stringBehind;
    this._stringFront = stringFront;
    let numberText = this._numberFormat( number );
    if ( fontName && fontSize ) this.text = this.game.make.bitmapText( x, y, fontName, numberText, fontSize );
    else this.text = this.game.make.text( x, y, numberText, style );
    numberText = null;
    this.setText( number );
    number = null;
  }
  setup ( x, y, number, style, fontName, fontSize, stringFront = '', stringBehind = '' ) {
    if ( this._stringBehind ) this.stringBehind = null; delete this.stringBehind;
    if ( this._stringFront ) this._stringFront = null; delete this._stringFront;
    this._stringBehind = stringBehind;
    this._stringFront = stringFront;
    let numberText = this._numberFormat( number );
    if ( this.text ) this.text.destroy( true ); this.text = null;
    if ( fontName && fontSize ) this.text = this.game.make.bitmapText( x, y, fontName, numberText, fontSize );
    else this.text = this.game.make.text( x, y, numberText, style );
    numberText = null;
    this.setText( number );
    number = null;
  }

  destroy () {
    if ( this.s ) this.s = null; delete this.s;
    if ( this._finalValue ) this._finalValue = null; delete this._finalValue;
    if ( this._updateStep ) this._updateStep = null; delete this._updateStep;
    if ( this._continueslyUpdateStep ) this._continueslyUpdateStep = null; delete this._continueslyUpdateStep;
    if ( this._stringBehind ) this._stringBehind = null; delete this._stringBehind;
    if ( this._stringFront ) this._stringFront = null; delete this._stringFront;
    if ( this.text ) this.text.destroy( true ); this.text = null;
    super.destroy();
  }
  cleanLocalGarbage () {
    this.localGarbage = null;
  }

  update () {
    let currentValue = this.getCurrentIntegerValue();

    if ( this._continueslyUpdateStep ) {
      this._continueslyUpdateCounter++;
      if ( this._continueslyUpdateCounter >= this._continueslyUpdateDelay ) {
        this._continueslyUpdateCounter = 0;
        currentValue += this._continueslyUpdateStep;
        this.setText( currentValue, true );
      }
      currentValue = null;
      return;
    }

    if ( currentValue === this._finalValue ) {
      currentValue = null;
      return;
    }

    let diff = this._finalValue - currentValue;

    if ( Math.abs( diff ) < Math.abs( this._updateStep ) ) this._updateStep = diff;

    currentValue += this._updateStep;
    this.setText( currentValue, true );
    currentValue = null;
    diff = null;
  }

  getText () {
    return this.text;
  }

  setText ( number, notFinal ) {
    let numberText = this._numberFormat( number );
    if ( !notFinal ) this._finalValue = Math.round( number );
    numberText = this._stringFront + numberText;
    numberText += this._stringBehind;
    this.text.setText( numberText );
    numberText = null;
  }

  updateTo ( number, updateStep ) {
    let currentValue = this.getCurrentIntegerValue();
    if ( number === currentValue ) return;

    let diff = number - currentValue;
    this._updateStep = updateStep || ( Math.round( diff * 0.1 ) || 1 * Math.abs( diff ) / diff );
    this._finalValue = Math.round( number );
    currentValue = null;
    diff = null;
  }

  updateContinuously ( updateStep, updateDelay ) {
    this._continueslyUpdateDelay = updateDelay || 0;
    this._continueslyUpdateStep = updateStep;
    this._continueslyUpdateCounter = 0;
  }
  stopUpdateContinuously () {
    this._continueslyUpdateStep = 0;
    this._continueslyUpdateDelay = 0;
    this._continueslyUpdateCounter = 0;
  }

  getCurrentIntegerValue () {
    return parseInt( this.text.text.match( /\d/g ).join( '' ) );
  }
  _numberFormat ( number, decimals, decPoint, thousandsSep ) {
    if ( this.s ) this.s = null; delete this.s;
    this.s = ( ( !isFinite( +decimals ) ? 0 : Math.abs( decimals ) ) ? Math.round( ( !isFinite( +number ) ? 0 : +number ) * ( Math.pow( 10, !isFinite( +decimals ) ? 0 : Math.abs( decimals ) ) ) ) / ( Math.pow( 10, !isFinite( +decimals ) ? 0 : Math.abs( decimals ) ) ) : Math.round( !isFinite( +number ) ? 0 : +number ) ).toString().split( '.' );

    if ( this.s[ 0 ].length > 3 )
      this.s[ 0 ] = this.s[ 0 ].replace( /\B(?=(?:\d{3})+(?!\d))/g, ( typeof thousandsSep === 'undefined' ) ? ',' : thousandsSep );

    if ( ( this.s[ 1 ] || '' ).length < !isFinite( +decimals ) ? 0 : Math.abs( decimals ) ) {
      this.s[ 1 ] = this.s[ 1 ] || '';
      this.s[ 1 ] += new Array( !isFinite( +decimals ) ? 0 : Math.abs( decimals ) - this.s[ 1 ].length + 1 ).join( '0' );
    }

    return this.s.join( ( typeof decPoint === 'undefined' ) ? '.' : decPoint );
  }
}

