import server from '../objects/Server.js';
export default class BaseGarbageCollectionGroup extends Phaser.Plugin {
  constructor ( game ) {
    super( game );
    this.localGarbage = [];
  }
  cleanLocalGarbage () {
    if ( !this.localGarbage ) {
      server.log( 'Clean Local Garbage: NULL' );
      return;
    }
    server.log( 'Clean Local Garbage at: ' + this.constructor.name );
    while ( this.localGarbage.length ) this.localGarbage.pop().destroy( true, true );

    this.localGarbage = null;
  }
  destroy () {
    this.cleanLocalGarbage();
    super.destroy();
  }
}
