/*
 * Loading Overlay
 * ==========
 *
 * Overlay Object to show during server requests if needed
 */
import BaseTintAnimatedBackground from '../base/BaseTintAnimatedBackground.js';
export default class LoadingOverlay extends BaseTintAnimatedBackground {
  constructor ( game, loadingText ) {
    super( game );

    let text = loadingText || '';

    // if ( this.loading ) this.loading.destroy( true ); this.loading = null;
    // this.loading = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'Lobby', 'loading.png' );
    // this.loading.anchor.set( 0.5 );
    // this.add( this.loading );

    // this.loadingTween = this.game.add.tween( this.loading ).to( { angle: 360 }, 2000, Phaser.Easing.Linear.None, true, 0, -1 );

    if ( this.loadingText ) this.loadingText.destroy( true ); this.loadingText = null;
    this.loadingText = this.game.make.text( this.game.width * 0.5, this.game.height * 0.5, text, { font: this.game.width * 0.015 + 'px Open Sans', fill: '#D3D3D3', align: 'center' } );
    this.loadingText.anchor.set( 0.5 );
    this.add( this.loadingText );

    this.game.add.tween( this.loadingText ).to( { alpha: 0.0 }, 1000, Phaser.Easing.Linear.None, true, 0, -1, true );
  }

  destroy () {
    if ( this.loadingTween ) {
      this.loadingTween.stop();
      this.loadingTween = null;
    }
    if ( this.loading ) this.loading.destroy( true ); this.loading = null;
    if ( this.loadingText ) this.loadingText.destroy( true ); this.loadingText = null;
    super.destroy();
  }
}
