/*
 * Base Slot Machine
 * ===========
 *
 * Base Slot Machine Group Object with Reel object inside
 */
import server from '../objects/Server.js';
import BaseGarbageCollectionGroup from './BaseGarbageCollectionGroup.js';
export default class BaseSlotMachine extends BaseGarbageCollectionGroup {
  constructor ( game, delegate ) {
    super( game, game.world, 'slotMachine' );

    this.game = game;

    /**
      * Delegate.
      * Work with next methods:
      * * SlotMachineTookASLEEPState();
      * * SlotMachineShowsWinLine( lineData, withSound );
      * * SlotMachineAnimationIsEnded();
      * * SlotMachineReelStopped(isLastReel);
      * * SlotMachineReelHyperSpinStarted();
      * * SlotMachineRunTwist();
     */
    this.delegate = delegate;
    this.arrayOfReelIDs = [];
    if ( server.CONFIG.MACHINE )
      for ( let i = 0; i < server.CONFIG.MACHINE[ 'reels' ].length; i++ )
        if ( server.CONFIG.MACHINE[ 'reels' ][ i ].length ) this.arrayOfReelIDs.push( server.CONFIG.MACHINE[ 'reels' ][ i ] );

    this.backgroundFreeSpinsSide = null;

    this.visibleRows = server.CONFIG.MACHINE ? server.CONFIG.MACHINE[ 'verticalSize' ] : 3;

    this.reels = [];
    this.spinResultArray = [];
    this.spinResultLinesArray = [];
    this.spinSpecialEffectArray = [];

    this.isNewFreeSpins = false;
    this.isInsideFreeSpins = false;
    this.spinBounsAmount = 0;

    this.quickStop = false;

    this.slotMachineWinAnimationGroup = null;

    this.STATES = {
      STATE_INIT: 0,
      STATE_SPINNING: 1,
      STATE_SHOW_RESULT: 2,
      STATE_A_SLEEP: 3,
      STATE_SHOW_CASCADE_RESULT: 4
    };

    this.currentState = this.STATES.STATE_INIT;

    this.defaultSpinTime = 1;

    this.tileWidth = 0;
    this.tileHeight = 0;

    this.machineWidth = 0;
    this.machineHeight = 0;

    this.groupHeight = 0;
    this.groupWidth = 0;

    this.defaultAnimationLoopsAmount = 3;
    this.winAnimationLoopsAmount = 3;

    this._generateSlotMachine();
  }

  destroy () {
    if ( this.timer ) this.timer.destroy( true ); this.timer = null;

    delete this.spinResultArray;
    delete this.spinResultLinesArray;
    delete this.spinSpecialEffectArray;

    delete this.IsNewFreeSpins;
    delete this.isInsideFreeSpins;
    delete this.spinBounsAmount;
    delete this.quickStop;

    delete this.STATES;

    delete this.currentState;

    delete this.defaultSpinTime;

    delete this.tileWidth;
    delete this.tileHeight;

    delete this.machineWidth;
    delete this.machineHeight;

    delete this.groupHeight;
    delete this.groupWidth;

    delete this.defaultAnimationLoopsAmount;
    delete this.winAnimationLoopsAmount;

    if ( this.slotMachineWinAnimationGroup ) this.slotMachineWinAnimationGroup.destroy(); this.slotMachineWinAnimationGroup = null;

    if ( this.reels ) {
      this.reels.forEach( function ( element, index ) {
        if ( element )element.destroy();
      }, this );
      delete this.reels;
    }
    super.destroy();
  }

  update () {
    for ( let i = 0; i < this.reels.length; i++ ) {
      this.reels[ i ].update();
      switch ( this.currentState ) {
        case this.STATES.STATE_SPINNING:
          this.reels[ i ].move();
          break;

        case this.STATES.STATE_SHOW_RESULT:
          if ( !this.spinResultArray ) break;

          if ( !this.quickStop && ( !i || ( this.reels[ i - 1 ].isInactive() ) ) )
            this.reels[ i ].stopAt( this.spinResultArray[ i ] );
          else if ( this.quickStop )
            this.reels[ i ].stopAt( this.spinResultArray[ i ] );

          if ( this.reels.length && this.reels[ this.reels.length - 1 ].isInactive() ) {
            this.quickStop = false;
            this.currentState = this.STATES.STATE_A_SLEEP;
          }
          break;
        case this.STATES.STATE_A_SLEEP:
          this._allReelsStoped();
          this.currentState = this.STATES.STATE_INIT;
          break;
        default:
          break;
      }
    }
  }
  /**
   * constant getters/setters
   */
  setIsNewFreeSpins ( isNewFreeSpins ) {
    this.isNewFreeSpins = isNewFreeSpins;
  }
  setInsideFreeSpins ( insideFreeSpins ) {
    server.setCurrentRequestTime();
    this.isInsideFreeSpins = insideFreeSpins;
    if ( insideFreeSpins ) {
      server.setCurrentRequestTime();
      this.winAnimationLoopsAmount = 1;
    }
    this.reels.forEach( function ( reel ) {
      if ( !this.isInsideFreeSpins ) reel.endFreeSpins();
    }, this );
  }

  setBounsAmount ( spinBonusAmount ) {
    this.spinBounsAmount = spinBonusAmount;
  }
  setDefaultSpinTime ( spinTimeInMilliSeconds ) {
    this.defaultSpinTime = spinTimeInMilliSeconds;
  }
  setResultArray ( spinResultArray ) {
    this.spinResultArray = spinResultArray;
  }
  getResultArray () {
    return this.spinResultArray;
  }
  setResultLinesArray ( spinResultLinesArray ) {
    this.spinResultLinesArray = spinResultLinesArray;
  }
  getResultLinesArray () {
    return this.spinResultLinesArray;
  }
  setSpecialEffectArray ( speacialEffectArray ) {
    this.spinSpecialEffectArray = speacialEffectArray;
  }
  getSpecialEffectArray () {
    return this.spinSpecialEffectArray;
  }

  /**
  * machine mechanics methods
  */

  spin ( animationLoopsAmount, isFreeSpin ) {
    this.slotMachineWinAnimationGroup.stopAnimation();
    if ( this.currentState === this.STATES.STATE_SPINNING ) return false;

    this.winAnimationLoopsAmount = animationLoopsAmount || this.defaultAnimationLoopsAmount;

    this.setResultLinesArray( null );
    this.setResultArray( null );
    this.setSpecialEffectArray( null );

    this._startSpin();
    this._startSpinTimer( this.defaultSpinTime * ( isFreeSpin ? 0.75 : 1 ) );

    return true;
  }

  stopSpinWithResult ( resultArray ) {
    this.timer.stop();
    this.setResultArray( resultArray );
    this._stopSpin();
  }

  stopSpin () {
    this.timer.stop();
    this.quickStop = true;
    this._stopSpin();
  }

  isInactive () {
    let isInactiveState = ( this.currentState === this.STATES.STATE_A_SLEEP || this.currentState === this.STATES.STATE_INIT );
    return this.reels[ this.reels.length - 1 ].isInactive() && isInactiveState;
  }

  hideHyper () {
    this.reels.forEach( function ( element, index ) { element.hideHyper(); } );
  }

  demonstrateLines ( currentLinesAmount ) {
    if ( !this.slotMachineWinAnimationGroup ) return;
    this.slotMachineWinAnimationGroup.demonstrateLines( currentLinesAmount );
  }

  showFreeSpinsDecorations () {
    if ( !this.backgroundFreeSpinsSide ) return;
    this.backgroundFreeSpinsSide.alpha = 1;
  }
  hideFreeSpinsDecorations () {
    if ( !this.backgroundFreeSpinsSide ) return;
    this.backgroundFreeSpinsSide.alpha = 0;
  }
  /**
  * Private methods
  */
  _generateSlotMachine () {
    server.error( 'overwrite _generateSlotMachine method in SlotMachine.js' );
  }

  _startSpin () {
    this.currentState = this.STATES.STATE_SPINNING;
  }

  _stopSpin ( ) {
    this.currentState = this.STATES.STATE_SHOW_RESULT;
  }

  _stopWithRandomResult () {
    let resultArray = [];
    for ( let i = 0; i < this.reels.length; i++ )
      resultArray[ i ] = Math.floor( this.visibleRows / 2 );

    this.setResultArray( resultArray );
    this._stopSpin();
  }

  _startSpinTimer ( timeValueInSeconds ) {
    if ( this.timer ) this.timer.destroy(); this.timer = null;
    this.timer = this.game.time.create();

    // Create a delayed event 1m and 30s from now
    this.timer.add( Phaser.Timer.SECOND * ( timeValueInSeconds || this.defaultSpinTime ), this._spinTimerEnd, this );

    // Start the timer
    this.timer.start();
  }

  _spinTimerEnd () {
    this.timer.stop();
    if ( this.getResultArray() === null )
      if ( server.isSpinInProgress() ) this._startSpinTimer( 1 );
      else this._stopWithRandomResult();
    else
      this._stopSpin();
  }

  _allReelsStoped () {
    new Promise( ( resolve ) => setTimeout( resolve, 1200 ) ).then( () => {
      let self = this;
      this.hideHyper();
      if ( this.spinBounsAmount ) {
        this.slotMachineWinAnimationGroup.showScatterOrBonusAnimation( 3, function () {
          self._showWinningAnimations( function () { self._tookASLEEPState(); } );
        } );
        return;
      }
      if ( this.isNewFreeSpins ) {
        this.slotMachineWinAnimationGroup.showScatterOrBonusAnimation( 2, function () {
          self.reels.forEach( function ( reel ) {
            reel.startFreeSpins();
          }, self );
          self._showWinningAnimations( function () { self._tookASLEEPState(); } );
        } );
        return;
      }
      this._showWinningAnimations( function () { self._tookASLEEPState(); } );
    } );
  }

  _showWinningAnimations ( callback ) {
    let self = this;
    let callbackFunction = function () {
      let lines = self.getResultLinesArray();
      self.slotMachineWinAnimationGroup.showWinningLines( lines, self.winAnimationLoopsAmount );
      if ( callback ) callback.call();
    };

    let effectArray = this.getSpecialEffectArray();
    this.slotMachineWinAnimationGroup.showTwist( effectArray, callbackFunction );
  }

  _tookASLEEPState () {
    if ( !this.delegate ) return;

    this.delegate.SlotMachineTookASLEEPState();
  }

  /**
   * reel delegate
   */

  reelStartedStop () {
    // check hyper spin
    let bonusReels = [];
    let scatterReels = [];
    for ( let i = 1; i < this.reels.length; i++ ) {
      if ( this.reels[ i - 1 ].isBonusPresent() ) bonusReels.push( i - 1 );
      if ( this.reels[ i - 1 ].isScatterPresent() ) scatterReels.push( i - 1 );
      if ( bonusReels.length >= 2 || scatterReels.length >= 2 ) this.reels[ i ].isHyper = true;
    }

    if ( bonusReels.length >= 2 && this.reels[ this.reels.length - 1 ].isBonusPresent() ) bonusReels.push( this.reels.length - 1 );
    if ( scatterReels.length >= 2 && this.reels[ this.reels.length - 1 ].isScatterPresent() ) scatterReels.push( this.reels.length - 1 );

    if ( bonusReels.length >= 2 )
      bonusReels.forEach( function ( element, index ) {
        this.reels[ element ].showBonus();
      }, this );

    if ( scatterReels.length >= 2 )
      scatterReels.forEach( function ( element, index ) {
        this.reels[ element ].showScatter();
      }, this );
  }

  reelStopped () {
    if ( this.delegate ) this.delegate.SlotMachineReelStopped( this.reels[ this.reels.length - 1 ].isInactive() );
  }

  reelHyperSpinStarted () {
    if ( this.delegate ) this.delegate.SlotMachineReelHyperSpinStarted();
  }
  /**
   * SlotMachineWinAnimationGroup delegate
   */
  animationShowsLine ( lineData, withSound ) {
    if ( this.delegate ) this.delegate.SlotMachineShowsWinLine( lineData, withSound );
  }
  animationEnded () {
    if ( this.delegate ) this.delegate.SlotMachineAnimationIsEnded();
  }
  animationRunTwist () {
    if ( this.delegate ) this.delegate.SlotMachineRunTwist();
  }
}
