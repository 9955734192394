import PluginDecimalText from './PluginDecimalText';

export default class PluginDecimalFloatText extends PluginDecimalText {
  constructor ( game ) {
    super( game );

    this._decimals = 2;
  }
  init ( x, y, number, style, fontName, fontSize, stringFront = '', stringBehind = '', decimals = 2 ) {
    this._stringBehind = stringBehind;
    this._stringFront = stringFront;
    this._decimals = decimals;
    let numberText = this._numberFormat( number, this._decimals );
    if ( fontName && fontSize ) this.text = this.game.make.bitmapText( x, y, fontName, numberText, fontSize );
    else this.text = this.game.make.text( x, y, numberText, style );
    this.setText( number );
  }
  updateTo ( number, updateStep ) {
    let currentValue = this.getCurrentIntegerValue();
    if ( number === currentValue ) return;

    let diff = number - currentValue;
    this._updateStep = updateStep || ( Math.round( diff * 0.1 ) || 1 * Math.abs( diff ) / diff );
    if ( number < 0.17 ) this.setText( number ); // When number is smaller than 0.17, it is too small to update
    this._finalValue = number.toFixed( this._decimals );
  }
  setText ( number, notFinal ) {
    let numberText = this._numberFormat( number, this._decimals );
    if ( !notFinal ) this._finalValue = number.toFixed( this._decimals );
    numberText = this._stringFront + numberText;
    numberText += this._stringBehind;
    this.text.setText( numberText );
  }
  getCurrentIntegerValue () {
    let text = this.text.text;
    return parseFloat( text.replace( /[^0-9.]/g, '' ) );
  }
}
