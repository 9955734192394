/*
 * `states` module
 * ===============
 *
 * Declares all present game states.
 * Expose the required game states using this module.
 */

export { default as Boot } from './states/Boot';
export { default as Login } from './states/Login';
export { default as Preloader } from './states/Preloader';
export { default as Window } from './states/Window';
