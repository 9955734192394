/*
 * BaseTintAnimatedBackground
 * ===========
 *
 * Base Group Object for Tint Animated Background
 */
import BaseGarbageCollectionGroup from './BaseGarbageCollectionGroup.js';
export default class BaseTintAnimatedBackground extends BaseGarbageCollectionGroup {
  constructor ( game ) {
    super( game, game.world );

    this._setupTintAnimatedBackground();
  }
  destroy () {
    if ( this.bmd ) this.bmd.destroy( true ); this.bmd = null;
    if ( this.tintAnimatedBackground ) this.tintAnimatedBackground.destroy( true ); this.tintAnimatedBackground = null;
    super.destroy();
  }
  _setupTintAnimatedBackground () {
    this.bmd = this.game.make.bitmapData( this.game.width, this.game.height );
    this.bmd.ctx.beginPath();
    this.bmd.ctx.rect( 0, 0, this.game.width, this.game.height );
    this.bmd.ctx.fillStyle = '#000000';
    this.bmd.ctx.fill();

    this.tintAnimatedBackground = this.game.add.sprite( this.game.width * 0.5, this.game.height * 0.5, this.bmd );
    this.tintAnimatedBackground.anchor.set( 0.5 );
    this.tintAnimatedBackground.alpha = 0;
    this.tintAnimatedBackground.inputEnabled = true;
    this.add( this.tintAnimatedBackground );
    this.game.make.tween( this.tintAnimatedBackground ).to( { alpha: 0.5 }, 150, Phaser.Easing.Linear.None, true ).onComplete.removeAll();
  }
}
