/*
 * Reel
 * ====
 *
 * Reel Group Object
 */
import BaseReel from '../base/BaseReel.js';
export default class Reel extends BaseReel {
  constructor ( game, arrayOfTilesIDs, visibleRows, delegate ) {
    super( game, arrayOfTilesIDs, visibleRows, delegate );
    this.alpha = 0;
    this.speedParam = 0.2;
  }
  update () {
    super.update();
    this._checkVisibility();
  }
  /**
   * Private methods
   */
  _checkVisibility () {
    if ( this.mask || !this.worldPosition.x ) return;

    let mask = this.game.add.graphics( 0, 0 );
    mask.beginFill( 0xffffff );
    mask.drawRect( this.worldPosition.x, this.worldPosition.y + this.tileHeight * 0.9 * this.worldScale.y, this.tileWidth * this.worldScale.x, this.tileHeight * 1.2 * this.worldScale.y );
    this.mask = mask;

    this.alpha = 1;
    if ( this.delegate ) this.delegate.reelStopped();
  }
  _updateIcons () {
    if ( !this.activeIcons.length ) {
      for ( let i = -this.visibleRows; i <= this.visibleRows; i++ ) {
        let iconID = ( i < 0 ) ? this.IDs.length + i : i;
        let iconFileNameID = this.IDs[ iconID ];
        let lastElementID = i + this.visibleRows;

        this.activeIcons.push( this.game.make.image( 0, 0, 'JackpotIcons', 'tile' + ( iconFileNameID + '.png' ) ) );
        this.activeIcons[ lastElementID ].y = i * this.activeIcons[ lastElementID ].height;
        this.activeIcons[ lastElementID ].key = iconID.toString();

        let iconWidth = this.activeIcons[ lastElementID ].width;
        let iconHeight = this.activeIcons[ lastElementID ].height;

        this._setupBonusBG( iconWidth, iconHeight );
        this._setupScatterBG( iconWidth, iconHeight );

        this.add( this.activeIcons[ lastElementID ] );
      }
      this.tileWidth = this.activeIcons[ 0 ].width;
      this.tileHeight = this.activeIcons[ 0 ].height;

      this.reelWidth = this.tileWidth;
      this.reelHeight = this.tileHeight * this.visibleRows;
      return;
    }

    let lastIcon = this.activeIcons[ 0 ];

    if ( lastIcon.y > this.tileHeight * -this.visibleRows ) {
      let iconID = parseInt( lastIcon.key );
      let newIconID = ( iconID || this.IDs.length ) - 1;
      let newIconFileNameID = this.IDs[ newIconID ];
      this.activeIcons.unshift( this.game.make.image( 0, 0, 'JackpotIcons', 'tile' + ( newIconFileNameID + '.png' ) ) );
      this.activeIcons[ 0 ].y = lastIcon.y - this.tileHeight;
      this.activeIcons[ 0 ].key = newIconID.toString();
      this.add( this.activeIcons[ 0 ] );
    }

    let firstIcon = this.activeIcons[ this.activeIcons.length - 1 ];

    if ( firstIcon.y > this.tileHeight * this.visibleRows ) {
      firstIcon = this.activeIcons.pop();
      firstIcon.destroy();
    }
  }

  _updateIconsForStop ( hyperSpin ) {
    let resultID = this.resultID || this.IDs.length;
    let lastReelID = this.IDs.length - 1;
    let lastIcon = this.activeIcons[ 0 ] || null;

    for ( let i = -1; i < 5; i++ ) {
      let idToadd = resultID - i;

      if ( idToadd < 0 ) idToadd = lastReelID + idToadd;
      else if ( idToadd > lastReelID ) idToadd = idToadd - this.IDs.length;

      let iconFileNameToAdd = this.IDs[ idToadd ];
      this.activeIcons.unshift( this.game.make.image( 0, 0, 'JackpotIcons', 'tile' + ( iconFileNameToAdd + '.png' ) ) );
      this.activeIcons[ 0 ].y = ( lastIcon ? lastIcon.y : 0 ) - this.tileHeight * ( i + 2 );
      this.activeIcons[ 0 ].key = idToadd.toString();
      this.add( this.activeIcons[ 0 ] );
    }
  }

  _setupScatterBG ( iconWidth, iconHeight ) {}
  _setupBonusBG ( iconWidth, iconHeight ) {}
  /**
   * Public methods
   */
  showScatter () {}
  showBonus () {}
  updateVisibleIcons ( iconsArray ) {
    iconsArray.forEach( function ( element, index ) {
      this.activeIcons[ index + this.visibleRows ].loadTexture( 'JackpotIcons', 'tile' + element + '.png' );
      this.activeIcons[ index + this.visibleRows ].key = this.getTileIDByIconID( element );
    }, this );
  }

  updateIcons ( iconsArray ) {
    iconsArray.forEach( function ( element, index ) {
      this.activeIcons[ element.index + this.visibleRows ].scale.set( 1.0 );
      this.activeIcons[ element.index + this.visibleRows ].y = element.index * this.tileHeight;
      this.activeIcons[ element.index + this.visibleRows ].loadTexture( 'JackpotIcons', 'tile' + element.iconID + '.png' );
      this.activeIcons[ element.index + this.visibleRows ].key = this.getTileIDByIconID( element.iconID );
    }, this );
  }
}
