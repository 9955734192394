/*
 * Popup Jackpot
 * ==========
 *
 * Popup Jackpot Object
 */
import PopupBase from '../base/BasePopup.js';
import PluginDecimalFloatText from '../plugins/PluginDecimalFloatText.js';
import JackpotSlotMachine from '../jackpotMachine/SlotMachine.js';
import { Math } from 'phaser-ce';
import server from '../objects/Server';
export default class PopupJackpotGrand extends PopupBase {
  constructor ( game, params, sounds ) {
    super( game );
    server.isShowingPopup = true;
    params = params || {};
    this.sounds = sounds;
    this.winValue = params[ 'winValue' ] || 0;
    this.type = params[ 'type' ] || 0;
    this.JPPayoutType = params[ 'JPPayoutType' ] || 0;
    this.personal = parseInt( this.JPPayoutType ) === 1;
    this.callback = params[ 'callback' ] || null;
    this.personalValue = params[ 'personalValue' ];
    this.minorValue = params[ 'minorValue' ];
    this.majorValue = params[ 'majorValue' ];
    this.grandValue = params[ 'grandValue' ];
    this.royalValue = params[ 'royalValue' ] || 0;

    this.gradientArray = [
      [ 0, '#9C9C9C' ],
      [ 0.1, '#E0E0E0' ],
      [ 0.2, '#FFFFFF' ],
      [ 0.7, '#B3B3B3' ],
      [ 0.85, '#D1D1D1' ],
      [ 1.0, '#9D9D9D' ]
    ];
    this.isInSecondStage = false;
    this.firstStageTimer = null;
    this.jackpotSlotMachine = null;
    this.topMessageBarBG = null;
    this.topMessageBarBitmap = null;
    this.topMessageBar = null;
    this.bottomMessageBar = null;
    this.jackpotTextPlugin = null;
    this.bottomMessageGroup = null;
    this.thirdStageTimer = null;
    this.thirdStageSoundTimer = null;
    if ( server.isRoyal )
      this.jackpots = [
        {
          'name': 'jackpotRoyal',
          'object': null,
          'value': this.royalValue / 100
        },
        {
          'name': 'jackpotGrand',
          'value': this.grandValue / 100,
          'object': null
        },
        {
          'name': 'jackpotMinor',
          'value': this.minorValue / 100,
          'object': null
        },
        {
          'name': 'jackpotSingle',
          'object': null,
          'value': this.personalValue / 100
        }
      ];
    else
      this.jackpots = [
        {
          'name': 'jackpotGrand',
          'value': this.grandValue / 100,
          'object': null
        },
        {
          'name': 'jackpotMinor',
          'value': this.minorValue / 100,
          'object': null
        },
        {
          'name': 'jackpotSingle',
          'object': null,
          'value': this.personalValue / 100
        }
      ];

    this.coinsAmount = 30;
    this.coinsBack = [];
    this.coinsFront = [];

    this.soundBG = this.game.add.audio( 'jackpotBG' ); this.soundBG.loopFull(); this.soundBG.fadeIn( 1500 );
    this.soundEntrance = this.game.add.audio( 'jackpotEntrance' );
    this.soundVoiceEntrance = this.game.add.audio( 'jackpotVoiceEntrance' ); this.soundVoiceEntrance.addMarker( 'start', 1.5, 3.5 );
    this.soundReelStops = [
      { sound: this.game.add.audio( 'jackpotWin1' ), played: false },
      { sound: this.game.add.audio( 'jackpotWin2' ), played: false },
      { sound: this.game.add.audio( 'jackpotWin3' ), played: false }
    ];

    this.soundCongrats = this.game.add.audio( 'jackpotCongrats' );
    this.soundVoiceGongrats = this.game.add.audio( 'jackpotCongratsVoice' );
    this.soundVoiceYouWin = this.game.add.audio( 'jackpotYouWinVoice' );
    this.soundCoins = this.game.add.audio( 'jackpotCoins' );

    this._generateJackpotSlotMachine();
    this._generateBackCoins();
    this._generateTopMessageBar( 'NOW PLAYING!' );
    this._generateJackpotsBars();

    this._runFirstStage();
  }
  destroy () {
    server.isShowingPopup = false;
    if ( this.firstStageTimer ) { this.firstStageTimer.stop( true ); this.firstStageTimer.destroy(); } this.firstStageTimer = null;
    if ( this.jackpotSlotMachine ) this.jackpotSlotMachine.destroy( true ); this.jackpotSlotMachine = null;
    if ( this.topMessageBarBG ) this.topMessageBarBG.destroy( true ); this.topMessageBarBG = null;
    if ( this.topMessageBarBitmap ) this.topMessageBarBitmap.destroy( true ); this.topMessageBarBitmap = null;
    if ( this.topMessageBar ) this.topMessageBar.destroy( true ); this.topMessageBar = null;
    if ( this.jackpotTextPlugin ) this.jackpotTextPlugin.destroy( true ); this.jackpotTextPlugin = null;
    if ( this.bottomMessageBar ) this.bottomMessageBar.destroy( true ); this.bottomMessageBar = null;
    if ( this.bottomMessageGroup ) { this.bottomMessageGroup.removeAll(); this.bottomMessageGroup.destroy( true ); } this.bottomMessageGroup = null;
    if ( this.thirdStageTimer ) { this.thirdStageTimer.stop( true ); this.thirdStageTimer.destroy(); } this.thirdStageTimer = null;
    if ( this.thirdStageSoundTimer ) { this.thirdStageSoundTimer.stop( true ); this.thirdStageSoundTimer.destroy(); } this.thirdStageSoundTimer = null;
    this.jackpots.forEach( function ( element, index ) {
      if ( element[ 'object' ] ) element[ 'object' ].destroy( true ); element[ 'object' ] = null;
    }, this );
    this.coinsBack.forEach( function ( element, index ) {
      if ( element.tween ) element.tween.stop( true ); element.tween = null;
      if ( element.coins ) element.coins.destroy( true ); element.coins = null;
    }, this );
    this.coinsFront.forEach( function ( element, index ) {
      if ( element.tween ) element.tween.stop( true ); element.tween = null;
      if ( element.coins ) element.coins.destroy( true ); element.coins = null;
    }, this );

    if ( this.soundEntrance ) this.soundEntrance.destroy( true ); this.soundEntrance = null;
    if ( this.soundVoiceEntrance ) this.soundVoiceEntrance.destroy( true ); this.soundVoiceEntrance = null;
    if ( this.soundBG ) this.soundBG.destroy( true ); this.soundBG = null;
    while ( this.soundReelStops.length ) this.soundReelStops.pop().sound.destroy( true );
    if ( this.soundCongrats ) this.soundCongrats.destroy( true ); this.soundCongrats = null;
    if ( this.soundVoiceGongrats ) this.soundVoiceGongrats.destroy( true ); this.soundVoiceGongrats = null;
    if ( this.soundVoiceYouWin ) this.soundVoiceYouWin.destroy( true ); this.soundVoiceYouWin = null;
    if ( this.soundCoins ) this.soundCoins.destroy( true ); this.soundCoins = null;
    super.destroy();
  }

  _runFirstStage () {
    // sound entrance
    this.soundEntrance.play();
    this.soundVoiceEntrance.play( 'start' );

    let blueRays = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotBlueRays' );
    blueRays.anchor.set( 0.5 );
    blueRays.scale.set( 2.0 );
    blueRays.alpha = 0.0;
    this.add( blueRays );

    let firstStageTime = Phaser.Timer.SECOND * 1;

    let blueRaysTween = this.game.make.tween( blueRays ).to( { angle: 360 }, firstStageTime * 5, Phaser.Easing.Linear.None, true, 0, -1 );

    this.game.make.tween( blueRays )
      .to( { alpha: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime )
      .onComplete.removeAll();

    this.game.make.tween( blueRays.scale )
      .to( { x: 1.0, y: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime )
      .onComplete.removeAll();

    let eagle = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotEagle' + ( this.personal ? 'Personal' : 'Comunity' ) );
    eagle.anchor.set( 0.5 );
    eagle.scale.set( 2.0 );
    eagle.alpha = 0.0;
    this.add( eagle );

    this.game.make.tween( eagle )
      .to( { alpha: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime )
      .onComplete.removeAll();

    this.game.make.tween( eagle.scale )
      .to( { x: 1.0, y: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime )
      .onComplete.add( function () {
        blueRaysTween.stop( true );
        blueRaysTween = null;
        blueRays.destroy( true );
        blueRays = null;
        this._runSecondStage();
      }, this );

    this.firstStageTimer = this.game.time.create( true );
    this.firstStageTimer.add( firstStageTime * 1.5, this._runSecondStage, this );
    this.firstStageTimer.start();

    this.localGarbage.push( blueRays );
    this.localGarbage.push( eagle );
  }
  _runSecondStage () {
    this.isInSecondStage = true;
    // animate top bar
    this.game.make.tween( this.topMessageBar )
      .to( { y: -this.topMessageBar.height * 0.15 }, 200, Phaser.Easing.Linear.None, true )
      .onComplete.removeAll();
    // jackpots bars animation
    this.jackpots.forEach( function ( element, index ) {
      if ( element[ 'object' ] )
        this.game.make.tween( element[ 'object' ] )
          .to( { y: this.game.height * 0.85 }, 200, Phaser.Easing.Linear.None, true )
          .onComplete.removeAll();
    }, this );
    // jackpot slotmachine animation
    this.jackpotSlotMachine.spin();
    let stopPosition = this.type;

    // type 0: super; type 1: major; type 2: grand
    // stop 1. SHOW QUICK HIT   if ( type === 1 ) type = 2
    // stop 2. SHOW MAJOR       if ( type === 2 ) type = 0
    // stop 3. SHOW NOTHING if ( type === 3 ) type = 1
    // stop 4. ROYAL SHOULD BE ROYAL
    // stop 0. SHOW SUPER
    if ( this.type === 1 ) stopPosition = 3;
    else if ( this.type === 2 ) stopPosition = 2;
    else if ( this.type === 3 ) stopPosition = 1;
    else if ( this.type === 4 ) stopPosition = 4;
    else stopPosition = 0;

    this.jackpotSlotMachine.setResultArray( [ stopPosition, stopPosition, stopPosition ] );
    this.game.make.tween( this.jackpotSlotMachine )
      .to( { alpha: 1.0 }, 500, Phaser.Easing.Linear.None, true )
      .onComplete.removeAll();
  }
  _runThirdStage () {
    let thirdStageTime = Phaser.Timer.SECOND * 1;

    this.soundCongrats.play();
    this.soundVoiceGongrats.play();
    this.soundCoins.loopFull();

    this.thirdStageSoundTimer = this.game.time.create( true );
    this.thirdStageSoundTimer.add( thirdStageTime * 1.5, function () {
      this.soundVoiceYouWin.play();
    }, this );
    this.thirdStageSoundTimer.start();

    this._generateTopMessageBar( 'WINNER!!!' );
    this._generateBottomMessageBar();
    // animate bottom bar
    this.game.make.tween( this.bottomMessageGroup )
      .to( { y: -this.bottomMessageBar.height * 0.85 }, 200, Phaser.Easing.Linear.None, true )
      .onComplete.removeAll();
    let winValue = this.winValue / 100;
    this.jackpotTextPlugin.updateTo( winValue, winValue * 0.03 );
    // pink rays
    let pinkRays = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotPinkRays' );
    pinkRays.anchor.set( 0.5 );
    pinkRays.alpha = 0.0;
    this.add( pinkRays );

    this.game.add.tween( pinkRays ).to( { alpha: 1.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true ).onComplete.removeAll();
    this.game.add.tween( pinkRays ).to( { angle: 360 }, 2000, Phaser.Easing.Linear.None, true, 0, -1 );

    // congrats sign
    let sign = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotCongratulationsSign' );
    sign.anchor.set( 0.5 );
    sign.scale.set( 2.0 );
    sign.alpha = 0.0;
    this.add( sign );

    this.game.make.tween( sign )
      .to( { alpha: 1.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true )
      .onComplete.removeAll();

    this.game.make.tween( sign.scale )
      .to( { x: 1.0, y: 1.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true )
      .onComplete.add( function () {
        this.soundBG.fadeOut( thirdStageTime * 3 );
        this.soundCoins.fadeOut( thirdStageTime * 3 );
        this.thirdStageTimer = this.game.time.create( true );
        this.thirdStageTimer.add( thirdStageTime * 3, this._close, this );
        this.thirdStageTimer.start();
      }, this );

    this.localGarbage.push( pinkRays );
    this.localGarbage.push( sign );

    this._generateFrontCoins();
    this._startCoinsAnimations();
  }
  _generateJackpotSlotMachine () {
    // Jackpot Slot Machine
    if ( !this.jackpotSlotMachine ) {
      this.jackpotSlotMachine = new JackpotSlotMachine( this.game, this );
      this.jackpotSlotMachine.x = this.game.width * 0.5;
      this.jackpotSlotMachine.y = this.game.height * 0.5 - this.jackpotSlotMachine.groupHeight * 0.45;
      this.add( this.jackpotSlotMachine );
      this.jackpotSlotMachine.alpha = 0;
    }
  }
  _generateTopMessageBar ( word ) {
    if ( this.topMessageBarBitmap ) this.topMessageBarBitmap.destroy( true ); this.topMessageBarBitmap = null;
    if ( this.topMessageBarBG ) this.topMessageBarBG.destroy( true ); this.topMessageBarBG = null;
    // background
    this.topMessageBarBG = this.game.make.image( 0, 0, 'PopupJackpot', 'messageBase.png' );
    this.topMessageBarBitmap = this.game.make.bitmapData( this.topMessageBarBG.width, this.topMessageBarBG.height );

    this.topMessageBarBitmap.draw( this.topMessageBarBG );
    // texts
    let text = this.game.make.text( 0, 0, word, { font: 'bold ' + this.topMessageBarBG.width * 0.1 + 'px Open Sans', fill: '#ffd700' } );
    text.anchor.set( 0.5 );
    let gradient = text.context.createLinearGradient( 0, 0, 0, text.height );
    for ( let i = 0; i < this.gradientArray.length; i++ )
      gradient.addColorStop( this.gradientArray[ i ][ 0 ], this.gradientArray[ i ][ 1 ] );
    text.fill = gradient;
    text.updateText();

    this.topMessageBarBitmap.draw( text, this.topMessageBarBitmap.width * 0.5, this.topMessageBarBitmap.height * 0.5 );
    if ( !this.topMessageBar ) {
      this.topMessageBar = this.game.make.image( this.game.width * 0.5, -this.topMessageBarBitmap.height, this.topMessageBarBitmap );
      this.topMessageBar.anchor.set( 0.5, 0.0 );
      this.add( this.topMessageBar );
    } else
      this.topMessageBar.loadTexture( this.topMessageBarBitmap );

    this.localGarbage.push( text );
  }

  _generateBottomMessageBar ( ) {
    if ( this.bottomMessageGroup ) { this.bottomMessageGroup.removeAll(); this.bottomMessageGroup.destroy( true ); } this.bottomMessageGroup = null;
    if ( this.bottomMessageBar ) this.bottomMessageBar.destroy( true ); this.bottomMessageBar = null;

    this.bottomMessageGroup = this.game.make.group();
    this.add( this.bottomMessageGroup );

    // background
    let bottomMessageBarBG = this.game.make.image( 0, 0, 'PopupJackpot', 'messageBase.png' );
    let sign = this.game.make.image( 0, 0, 'PopupJackpot', 'prizeWonSign.png' );
    let bottomMessageBarBitmap = this.game.make.bitmapData( bottomMessageBarBG.width, bottomMessageBarBG.height + sign.height );

    bottomMessageBarBG.anchor.set( 0.0, 1.0 );
    bottomMessageBarBitmap.draw( bottomMessageBarBG, 0, bottomMessageBarBitmap.height );

    sign.anchor.set( 0.5, 1.0 );
    bottomMessageBarBitmap.draw( sign, bottomMessageBarBitmap.width * 0.5, bottomMessageBarBitmap.height - bottomMessageBarBG.height * 0.75 );
    this.bottomMessageBar = this.game.make.image( this.game.width * 0.5, this.game.height + bottomMessageBarBitmap.height, bottomMessageBarBitmap );
    this.bottomMessageBar.anchor.set( 0.5, 1.0 );
    this.bottomMessageGroup.add( this.bottomMessageBar );

    // texts
    if ( parseInt( this.type ) === 4 ) this.jackpotTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.5, this.game.height + bottomMessageBarBitmap.height * 0.725, 0, null, 'JackpotRoyalFont', this.game.width * 0.033, '$' );
    else this.jackpotTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.5, this.game.height + bottomMessageBarBitmap.height * 0.725, 0, null, 'JackpotPopupFont', this.game.width * 0.026, '$' );
    this.jackpotTextPlugin.getText().anchor.set( 0.5 );

    this.bottomMessageGroup.add( this.jackpotTextPlugin.getText() );

    this.localGarbage.push( bottomMessageBarBitmap );
    bottomMessageBarBG.destroy( true ); bottomMessageBarBG = null;
    sign.destroy( true ); sign = null;
  }

  _generateJackpotsBars () {
    let x = 0;
    for ( let i = 0; i < this.jackpots.length; i++ ) {
      let jackpotBase = this.game.make.image( 0, 0, 'PopupJackpot', this.jackpots[ i ][ 'name' ] + '.png' );
      let jackpotBitmap = this.game.make.bitmapData( jackpotBase.width, jackpotBase.height * 2 );
      jackpotBitmap.draw( jackpotBase );

      let jackpotText = this.game.make.image( 0, 0, 'PopupJackpot', this.jackpots[ i ][ 'name' ] + 'Text.png' );
      jackpotText.anchor.set( 0.5, 0 );

      if ( server.isRoyal ) {
        if ( i > 0 ) x = x + this.jackpots[ i - 1 ][ 'object' ].width + this.game.width * 0.015;
        else x = this.game.width * 0.32;
        jackpotBitmap.draw( jackpotText, jackpotBase.width * 0.5, jackpotBase.height * ( 0.6 - i * 0.008 ) );
      } else {
        if ( i > 0 ) x = x + this.jackpots[ i - 1 ][ 'object' ].width + this.game.width * 0.02;
        else x = this.game.width * 0.4;
        jackpotBitmap.draw( jackpotText, jackpotBase.width * 0.5, jackpotBase.height * ( 0.6 - ( i + 1 ) * 0.008 ) );
      }

      let jackpotTextPlugin = this.game.plugins.add( PluginDecimalFloatText, 0, 0, this.jackpots[ i ][ 'value' ], { font: 0.175 * jackpotBase.width + 'px Open Sans', fill: '#ffffff' }, null, null, '$' );
      jackpotTextPlugin.getText().anchor.set( 0.5 );
      jackpotTextPlugin.getText().updateText();
      let gradient = jackpotTextPlugin.getText().context.createLinearGradient( 0, 0, 0, jackpotTextPlugin.getText().height );
      for ( let i = 0; i < this.gradientArray.length; i++ )
        gradient.addColorStop( this.gradientArray[ i ][ 0 ], this.gradientArray[ i ][ 1 ] );
      jackpotTextPlugin.getText().fill = gradient;
      jackpotTextPlugin.getText().updateText();
      jackpotBitmap.draw( jackpotTextPlugin.getText(), jackpotBase.width * 0.5, jackpotBase.height * 0.49 );

      this.jackpots[ i ][ 'object' ] = this.game.make.image( x, this.game.height, jackpotBitmap );
      this.jackpots[ i ][ 'object' ].anchor.set( 1, 0 );
      this.add( this.jackpots[ i ][ 'object' ] );

      this.localGarbage.push( jackpotBitmap );
      jackpotBase.destroy( true ); jackpotBase = null;
      jackpotText.destroy( true ); jackpotText = null;
      jackpotTextPlugin.destroy( true ); jackpotTextPlugin = null;
    }
  }
  _generateBackCoins () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      let variant = Math.random() >= 0.5;

      let frames = Phaser.Animation.generateFrameNames( 'CoinsAnimation_', variant ? 0 : 8, variant ? 7 : 21, '.png', 4 );

      let coinBack = this.game.make.sprite( this.game.width * Math.random(), -this.game.height * ( 0.5 + Math.random() * 0.8 ), 'CoinsAnimation' );
      coinBack.animations.add( 'CoinsAnimation', frames, 4 );
      coinBack.animations.play( 'CoinsAnimation', 12, true );

      coinBack.angle = 360 + ( variant ? 1 : -1 ) * ( 60 + Math.random() * 20 );
      coinBack.alpha = 0.5 + Math.random() * 0.3;

      this.coinsBack.push( { coin: coinBack, tween: null } );

      this.add( coinBack );
    }
  }
  _generateFrontCoins () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      let variant = Math.random() >= 0.5;

      let frames = Phaser.Animation.generateFrameNames( 'CoinsAnimation_', variant ? 0 : 8, variant ? 7 : 21, '.png', 4 );

      let coinFront = this.game.make.sprite( this.game.width * Math.random(), -this.game.height * ( 0.5 + Math.random() * 0.8 ), 'CoinsAnimation' );
      coinFront.animations.add( 'CoinsAnimation', frames, 4 );
      coinFront.animations.play( 'CoinsAnimation', 12, true );

      coinFront.angle = 360 + ( variant ? 1 : -1 ) * ( 60 + Math.random() * 20 );

      this.coinsFront.push( { coin: coinFront, tween: null } );

      this.add( coinFront );
    }
  }
  _startCoinsAnimations () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      this.coinsFront[ i ].tween = this.game.make.tween( this.coinsFront[ i ].coin );
      this.coinsFront[ i ].tween.to( { y: this.game.height + this.coinsFront[ i ].coin.height }, 200 + Math.random() * 400, Phaser.Easing.Linear.None, false );

      this.coinsFront[ i ].tween.onComplete.add( function () {
        this.coinsFront.forEach( function ( element, index ) {
          if ( element.coin.y >= this.game.height + element.coin.height ) {
            element.coin.y = -element.coin.height;
            element.coin.x = this.game.width * Math.random();
            element.tween.start();
          }
        }, this );
      }, this );

      this.coinsFront[ i ].tween.start();

      this.coinsBack[ i ].tween = this.game.make.tween( this.coinsBack[ i ].coin );
      this.coinsBack[ i ].tween.to( { y: this.game.height + this.coinsBack[ i ].coin.height }, 200 + Math.random() * 400, Phaser.Easing.Linear.None, false );

      this.coinsBack[ i ].tween.onComplete.add( function () {
        this.coinsBack.forEach( function ( element, index ) {
          if ( element.coin.y >= this.game.height + element.coin.height ) {
            element.coin.y = -element.coin.height;
            element.coin.x = this.game.width * Math.random();
            element.tween.start();
          }
        }, this );
      }, this );

      this.coinsBack[ i ].tween.start();
    }
  }
  /**
   * Jackpot Slot Machine Delegate
   */
  SlotMachineTookASLEEPState () {
    this._runThirdStage();
  }
  SlotMachineShowsWinLine ( lineData, withSound ) {}
  SlotMachineAnimationIsEnded () {}
  SlotMachineReelStopped ( isLastReel ) {
    if ( !this.isInSecondStage ) return;
    for ( let i = 0; i < this.soundReelStops.length; i++ )
      if ( !this.soundReelStops[ i ].played ) {
        this.soundReelStops[ i ].played = true;
        this.soundReelStops[ i ].sound.play();
        return;
      } else continue;
  }
  SlotMachineReelHyperSpinStarted () {}
  SlotMachineRunTwist () {}
}
