/*
 * Slot Machine for Jackpot
 * ===========
 *
 * SlotMachine Group Object
 */
// import server from '../../objects/Server.js';
import BaseSlotMachine from '../base/BaseSlotMachine.js';
import Reel from './Reel.js';
export default class SlotMachine extends BaseSlotMachine {
  spin ( animationLoopsAmount, isFreeSpin ) {
    if ( this.currentState === this.STATES.STATE_SPINNING ) return false;

    this.winAnimationLoopsAmount = animationLoopsAmount || this.defaultAnimationLoopsAmount;

    this.setResultLinesArray( null );
    this.setResultArray( null );
    this.setSpecialEffectArray( null );

    this._startSpin();
    this._startSpinTimer( this.defaultSpinTime * ( isFreeSpin ? 0.75 : 1 ) );

    return true;
  }
  stopSpin () {
    if ( this.timer ) this.timer.stop();
    this.quickStop = true;
    this._stopSpin();
  }
  destroy () {
    if ( this.panelBase ) this.panelBase.destroy( true ); this.panelBase = null;
    if ( this.jackpotBaseBorder ) this.jackpotBaseBorder.destroy( true ); this.jackpotBaseBorder = null;
    super.destroy();
  }
  /**
   * Private Methods
   */
  _spinTimerEnd () {
    this.timer.stop();
    if ( this.getResultArray() === null )
      this._stopWithRandomResult();
    else
      this._stopSpin();
  }
  _generateSlotMachine () {
    let reelsAmount = 3;
    let reel = null;

    this.jackpotBaseBorder = this.game.make.image( 0, 0, 'PopupJackpot', 'reelsBaseFrame.png' );

    this.groupWidth = this.jackpotBaseBorder.width;
    this.groupHeight = this.jackpotBaseBorder.height;

    this.panelBase = this.game.make.image( -this.groupWidth * 0.5, this.groupHeight, 'PopupJackpot', 'reelsBaseBG.png' );
    this.panelBase.anchor.set( 0.0, 1.0 );
    this.add( this.panelBase );

    let possibleReelWidth = this.groupWidth * 0.9 / reelsAmount;
    for ( let i = 0; i < reelsAmount; i++ ) {
      reel = new Reel( this.game, [ 1, 2, 3, 4, 5 ], 3, this );
      let scale = possibleReelWidth / reel.tileWidth;
      reel.scale.set( scale );
      reel.x = -this.groupWidth * 0.45 + i * reel.width;
      reel.y = this.groupHeight - this.panelBase.height - reel.tileHeight * scale * 0.85;
      this.add( reel );
      this.reels.push( reel );
    }

    this.jackpotBaseBorder.x = -this.groupWidth * 0.5;
    this.jackpotBaseBorder.y = this.groupHeight;
    this.jackpotBaseBorder.anchor.set( 0.0, 1.0 );
    this.add( this.jackpotBaseBorder );
  }
  _showWinningAnimations ( callback ) {
    if ( callback ) callback.call();
  }
}
