/*
 * Configuration GET params
 * ======
 *
 * GET params
 */
class Get {
  constructor () {
    this.projectType = 0;
    this.userName = '';
    this.password = '';
    this.readParams();
  }
  readParams () {
    let getParams = this.getSearchParameters();
    if ( 'projectType' in getParams ) this.projectType = parseInt( getParams.projectType );
    if ( 'username' in getParams ) this.userName = getParams.username;
    if ( 'password' in getParams ) this.password = getParams.password;
  }
  getSearchParameters () {
    var prmstr = window.location.search.substr( 1 );
    return prmstr != null && prmstr !== '' ? this.transformToAssocArray( prmstr ) : {};
  }
  getHost () {
    return window.location.host;
  }
  transformToAssocArray ( prmstr ) {
    var params = {};
    var prmarr = prmstr.split( '&' );
    for ( var i = 0; i < prmarr.length; i++ ) {
      let indexOfEq = prmarr[ i ].indexOf( '=' );
      if ( indexOfEq < 0 ) continue;
      let key = prmarr[ i ].substr( 0, indexOfEq );
      let value = prmarr[ i ].substr( indexOfEq + 1 );
      params[ key ] = value;
    }
    return params;
  }
}
export default new Get();
