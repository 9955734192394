/*
 * `app` module
 * ============
 *
 * Provides the game initialization routine.
 */

// Required: import the Babel runtime module.
import 'babel-polyfill';

// Import game states.
import * as states from './states';
import server from './objects/Server.js';

export function init () {
  let resolutions = [
    { x: 960, y: 540 },
    { x: 1280, y: 720 },
    { x: 1920, y: 1080 }
  ];

  if ( !window.currentResolution || !resolutions.includes( window.currentResolution ) ) {
    let currentResolution = resolutions[ 0 ];
    let w = window.innerWidth;
    let h = window.innerHeight;
    server.log( 'window width : ' + w + ' window height: ' + h );

    for ( let i = 0; i < resolutions.length; i++ ) {
      let element = resolutions[ i ];
      if ( element.x <= w || element.y <= h )
        currentResolution = element;
    }

    window.currentResolution = currentResolution;
  }

  server.log( 'resolution chosen:' + window.currentResolution.x + 'X' + window.currentResolution.y );
  console.log( 'version ' + window.version + ' ' + window.environment ); // eslint-disable-line no-console

  const game = new Phaser.Game( window.currentResolution.x, window.currentResolution.y, Phaser.CANVAS );
  game.currentResolution = window.currentResolution;

  // Dynamically add all required game states.
  Object
    .entries( states )
    .forEach( ( [ key, state ] ) => game.state.add( key, state ) );

  game.state.start( 'Boot' );

  return game;
}
