/*
 * Popup Purchases
 * ==========
 *
 * Popup Purchases Object
 */
import PopupBase from './../base/BasePopup.js';
import server from './../objects/Server.js';
import Keypad from './../objects/Keypad.js';
import get from '../objects/Get.js';
export default class PopupLogin extends PopupBase {
  constructor ( game, params, sounds ) {
    super( game );
    this.sounds = sounds;
    this.callbackFunction = params[ 'callbackFunction' ] || null;
    this.errorPOSUser = params[ 'posUser' ] || false;
    this.wrongUsernamePassword = params[ 'wrong' ] || false;
    this.attempts = params[ 'attempts' ] || 0;
    this.isBlocked = params[ 'isBlocked' ] || false;
    this.onDeclineCallback = params[ 'onDeclineCallback' ] || null;

    // this.bottomYPosition = this.game.height * 0.625;
    this.bottomYPosition = this.game.height * ( this.game.device.iOS ? ( ( this.wrongUsernamePassword || this.errorPOSUser ) ? 0.575 : 0.45 ) : 0.625 );
    this.bottomPart = null;
    this.middlePart = null;
    this.topPart = null;

    this.popupWidth = 0;
    this.popupHeight = 0;

    this.inputLogin = null;
    this.inputPassword = null;

    this.keypad = null;

    this.currentInput = null;

    this.scaleBounds = new Phaser.Rectangle();

    this._generatePopup();
    if ( !this.isBlocked ) {
      this._setupKeypad();
      this._setupTexts();
    } else this._setupBlockedDesign();

    this._setupTintBackgroundClick();
  }
  destroy () {
    if ( this.bottomPart ) this.bottomPart.destroy( true ); this.bottomPart = null;
    if ( this.middlePart ) this.middlePart.destroy( true ); this.middlePart = null;
    if ( this.middlePart ) this.middlePart.destroy( true ); this.middlePart = null;
    if ( this.topPart ) this.topPart.destroy( true ); this.topPart = null;
    delete this.popupWidth;
    delete this.popupHeight;
    if ( this.inputLogin ) this.inputLogin.destroy(); this.inputLogin = null;
    if ( this.inputPassword ) this.inputPassword.destroy(); this.inputPassword = null;
    if ( this.keypad ) this.keypad.destroy( true ); delete this.keypad; this.keypad = null;
    if ( this.currentInput ) delete this.currentInput; this.currentInput = null;
    // if ( this.fakeInput ) delete this.fakeInput; this.fakeInput = null;
    delete this.scaleBounds;
    if ( this.textFirstLine ) this.textFirstLine.destroy( true ); this.textFirstLine = null;
    delete this.callbackFunction; this.callbackFunction = null;
    // $( '.g-recaptcha' ).hide();
    super.destroy();
  }
  update () {
    if ( this.scaleBounds.equals( this.game.scale.bounds ) ) return;

    let bounds = this.game.scale.bounds;
    this.scaleBounds = new Phaser.Rectangle( bounds.x, bounds.y, bounds.width, bounds.height );
    this._setupCaptcha();
  }
  /**
   * Private Methods
   */
  _generatePopup () {
    let self = this;

    if ( !this.bottomPart ) {
      this.bottomPart = this.game.make.image( this.game.width * 0.5, this.bottomYPosition, 'PopupLogin', 'PopupLoginBottom.png' );
      this.bottomPart.anchor.set( 0.5, 1.0 );
      this.bottomPart.inputEnabled = true;
      this.bottomPart.input.priorityID = 1;
      this.add( this.bottomPart );
    }

    if ( !this.middlePart ) {
      this.middlePart = this.game.make.image( this.game.width * 0.5, Math.ceil( this.bottomYPosition - this.bottomPart.height ), 'PopupLogin', 'PopupLoginMiddle.png' );
      this.middlePart.anchor.set( 0.5, 1.0 );
      this.middlePart.inputEnabled = true;
      this.middlePart.input.priorityID = 1;

      let scaleY = 1.0;

      if ( this.wrongUsernamePassword || this.errorPOSUser ) scaleY = 1.35;
      if ( this.attempts ) scaleY = 1.8;
      if ( this.wrongUsernamePassword && !this.errorPOSUser ) scaleY = 1.68;
      if ( this.isBlocked )scaleY = 1.0;
      if ( this.middlePart ) {
        this.middlePart.scale.set( 1.0, scaleY );
        this.add( this.middlePart );
      }
    }

    if ( !this.topPart ) {
      this.topPart = this.game.make.image( this.game.width * 0.5, Math.ceil( this.bottomYPosition - Math.floor( this.bottomPart.height + this.middlePart.height ) ), 'PopupLogin', 'PopupLoginTop.png' );
      this.topPart.anchor.set( 0.5, 1.0 );
      this.topPart.inputEnabled = true;
      this.topPart.input.priorityID = 1;
      this.add( this.topPart );
    }

    this.popupWidth = this.middlePart.width;
    this.popupHeight = this.middlePart.height + this.topPart.height + this.bottomPart.height;

    let inputWidth = this.middlePart.width * 0.8;
    let inputFontSize = this.middlePart.width * 0.07;
    let inputPadding = this.middlePart.width * 0.02;

    // to prevent autocomplete
    // if ( !this.fakeInput ) {
    //   this.fakeInput = this.game.add.inputField( -this.game.width * 0.5, -this.game.height * 0.5, {} );
    //   this.fakeInput.blockInput = false;
    //   this.fakeInput.focusOutOnEnter = false;
    //   this.add( this.fakeInput );
    // }
    // real inputs
    if ( !this.inputLogin ) {
      this.inputLogin = this.game.add.inputField( this.game.width * 0.5 - inputWidth * 0.535, this.bottomYPosition - this.bottomPart.height - this.middlePart.height * ( ( this.attempts & !this.isBlocked ) ? 1.0 : 0.95 ), {
        font: inputFontSize + 'px Arial',
        fill: '#212121',
        width: inputWidth,
        padding: inputPadding,
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 6,
        placeHolder: 'PLAYER ID',
        textAlign: 'center',
        type: PhaserInput.InputType.text
      } );
      this.inputLogin.blockInput = false;
      this.inputLogin.focusOutOnEnter = false;
      if ( get.userName ) {
        this.inputLogin.value = get.userName;
        this.inputLogin.setText( this.inputLogin.value );
      }
      this.add( this.inputLogin );
      this.inputLogin.focusIn.add( function () {
        if ( self.isBlocked && self.inputLogin.focus ) {
          self.inputLogin.endFocus();
          return;
        }
        self.currentInput = self.inputLogin;
        if ( !self.game.device.desktop )self.currentInput.domElement.element.blur();
      } );
    }
    // if ( !this.isBlocked ) this.inputLogin.startFocus();
    if ( !this.inputPassword ) {
      this.inputPassword = this.game.add.inputField( this.game.width * 0.5 - inputWidth * 0.535, this.inputLogin.y + this.inputLogin.height * ( ( this.attempts & !this.isBlocked ) ? 1.25 : 1.5 ), {
        font: inputFontSize + 'px Arial',
        fill: '#212121',
        width: inputWidth,
        padding: inputPadding,
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 6,
        placeHolder: 'PASSWORD',
        textAlign: 'center',
        type: PhaserInput.InputType.text
      } );
      this.inputPassword.blockInput = false;
      this.inputPassword.focusOutOnEnter = false;
      if ( get.password ) {
        this.inputPassword.value = get.password;
        this.inputPassword.setText( this.inputPassword.value );
      }
      this.add( this.inputPassword );
      this.inputPassword.focusIn.add( function () {
        if ( self.isBlocked && self.inputPassword.focus ) {
          self.inputPassword.endFocus();
          return;
        }
        self.currentInput = self.inputPassword;
        if ( !self.currentInput.value.length ) self.currentInput.text.style.font = '16px';
        self.currentInput.text.style.font = '16px DotsFont';
        if ( !self.game.device.desktop ) self.currentInput.domElement.element.blur();
      } );
    }

    let btnEnter = this.game.make.button( this.game.width * 0.5, this.bottomYPosition - this.bottomPart.height * 0.9, 'PopupLogin', this._enterClicked, this );
    btnEnter.setFrames( 'BtnEnter.png', 'BtnEnter.png', 'BtnEnterPressed.png', 'BtnEnter.png' );
    btnEnter.anchor.set( 0.5 );
    btnEnter.input.priorityID = 2;
    this.add( btnEnter );
    this.localGarbage.push( btnEnter );

    this.inputLogin.domElement.element.onkeydown = function ( event ) {
      if ( self.isBlocked ) return;
      if ( ( event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 ) && self.inputLogin.focus ) {
        self._clickEnterOnInputLogin();
        event.preventDefault();
      } else {
        if ( self.inputLogin.focus )self.inputLogin.endFocus();
        self.inputLogin.startFocus();
      }
    };

    this.inputPassword.domElement.element.onkeydown = function ( event ) {
      if ( self.isBlocked ) return;
      if ( event.key === 'Enter' && self.inputPassword.focus ) self._clickEnterOnInputPassword();
      else {
        if ( self.inputPassword.focus )self.inputPassword.endFocus();
        self.inputPassword.startFocus();
      }
    };
    if ( get.userName && get.password ) {
      if ( !this.wrongUsernamePassword ) this._enterClicked();
    }
  }
  _setupKeypad () {
    if ( !this.keypad )
      this.keypad = new Keypad(
        this.game,
        this.popupWidth,
        // this.game.height - ( this.bottomYPosition - this.bottomPart.height * 0.5 ),
        this.game.height - ( this.bottomYPosition - this.bottomPart.height * 0.5 ) * ( this.game.device.iOS ? ( ( this.wrongUsernamePassword || this.errorPOSUser ) ? 1.1 : 1.5 ) : 1 ),
        this.game.width * 0.5 - this.popupWidth * 0.5,
        // this.game.height,
        this.game.height - ( this.bottomYPosition - this.bottomPart.height * 0.5 ) * ( this.game.device.iOS ? ( ( this.wrongUsernamePassword || this.errorPOSUser ) ? 0.1 : 0.4 ) : 0 ),
        this );
      // this.add( this.keypad );
  }
  _setupTexts () {
    if ( this.isBlocked ) return;
    let textWrongLastLine = null;
    if ( this.errorPOSUser ) {
      let posText = this.game.make.text( this.game.width * 0.5,
        this.inputPassword.y + this.inputPassword.height * 1.1,
        'You tried to log in with a POS user,\nPlsese use a JP user instead',
        { font: this.game.width * 0.013 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
      posText.anchor.set( 0.5, 0.0 );
      this.add( posText );
      this.localGarbage.push( posText );
    }
    if ( this.wrongUsernamePassword && !this.errorPOSUser ) {
      server.wrongAttemps--;
      let clickableText = this.game.make.text( this.game.width * 0.5,
        this.inputPassword.y + this.inputPassword.height * 1.1,
        'Can\'t remember your password?',
        { font: this.game.width * 0.014 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
      clickableText.anchor.set( 0.5, 0.0 );
      this.add( clickableText );
      // input
      clickableText.inputEnabled = true;
      clickableText.input.useHandCursor = true;
      clickableText.input.priorityID = 1;
      clickableText.events.onInputUp.add( function () { alert( 'please contact your game provider' ); }, this );
      // underline
      let underline = this.game.add.graphics( clickableText.left, clickableText.bottom - clickableText.height * 0.2 );
      underline.lineStyle( Math.ceil( clickableText.height * 0.05 ), Phaser.Color.getColor( 255, 255, 255, 255 ) );
      underline.moveTo( 0, 0 );
      underline.lineTo( clickableText.width, 0 );

      textWrongLastLine = this.game.make.text( this.game.width * 0.5,
        clickableText.y + clickableText.height * 1.0,
        'Incorrect PlayerID/Password combination.',
        { font: this.game.width * 0.013 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
      textWrongLastLine.anchor.set( 0.5, 0.0 );
      this.add( textWrongLastLine );
      this.localGarbage.push( clickableText );
      this.localGarbage.push( underline );
      this.localGarbage.push( textWrongLastLine );
    }
    // if ( this.attempts ) {
    if ( server.wrongAttemps < 50 && !this.errorPOSUser ) {
      let text = 'After ' + server.wrongAttemps + ' incorrect logon attempts, your access\nwill be locked.';
      if ( server.isAccountBlocked )
        text = 'Access is temporarily blocked. \nPlease try again in 5 minutes.';
      if ( !this.textFirstLine ) {
        this.textFirstLine = this.game.make.text( this.game.width * 0.5,
          textWrongLastLine.y + textWrongLastLine.height * 1.0,
          text,
          { font: this.game.width * 0.013 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
        this.textFirstLine.anchor.set( 0.5, 0.0 );
        this.add( this.textFirstLine );
      } else this.textFirstLine.setText( text );

      if ( server.wrongAttemps < 46 ) {
        this._setupCaptcha();
        $( '.g-recaptcha' ).show();
      }
    }
  }
  _setupBlockedDesign () {
    let blockedBG = this.game.make.image( this.game.width * 0.5, this.bottomYPosition - this.popupHeight * 0.5, 'PopupLogin', 'LockBG.png' );
    blockedBG.anchor.set( 0.5 );
    this.add( blockedBG );
    this.localGarbage.push( blockedBG );

    let lock = this.game.make.image( this.game.width * 0.5, this.bottomYPosition - this.popupHeight * 0.6, 'PopupLogin', 'Lock.png' );
    lock.anchor.set( 0.5 );
    this.add( lock );
    this.localGarbage.push( lock );

    let text = this.game.make.text( this.game.width * 0.5, this.bottomYPosition - this.popupHeight * 0.175, 'ACCOUNT LOCKED', { font: this.game.width * 0.014 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
    text.anchor.set( 0.5 );
    this.add( text );

    let clickableText = this.game.make.text( this.game.width * 0.5,
      text.y + text.height * 0.2,
      'More info',
      { font: this.game.width * 0.014 + 'px Playfair Display', fill: '#ffffff', align: 'center' } );
    clickableText.anchor.set( 0.5, 0.0 );
    this.add( clickableText );
    // input
    clickableText.inputEnabled = true;
    clickableText.input.useHandCursor = true;
    clickableText.input.priorityID = 1;
    clickableText.events.onInputUp.add( function () { alert( 'please contact your game provider' ); }, this );
    // underline
    let underline = this.game.add.graphics( clickableText.left, clickableText.bottom - clickableText.height * 0.2 );
    underline.lineStyle( Math.ceil( clickableText.height * 0.05 ), Phaser.Color.getColor( 255, 255, 255, 255 ) );
    underline.moveTo( 0, 0 );
    underline.lineTo( clickableText.width, 0 );
    this.localGarbage.push( clickableText );
    this.localGarbage.push( text );
    this.localGarbage.push( underline );
  }
  _setupTintBackgroundClick () {
    this.tintAnimatedBackground.inputEnabled = true;
    this.tintAnimatedBackground.input.priorityID = 1;
    this.tintAnimatedBackground.events.onInputUp.add( this._clickedOutsideThePopup, this );
  }
  _setupCaptcha ( self ) {
    let captchaScaleRatioX = $( '.g-recaptcha' ).width() / ( this.topPart.width * 1.3 * this.game.scale.scaleFactor.x );
    let captchaScaleRatioY = $( '.g-recaptcha' ).height() / ( this.topPart.height * 0.1 * this.game.scale.scaleFactor.y );
    let captchaScaleRatio = captchaScaleRatioX < captchaScaleRatioY ? captchaScaleRatioX : ( captchaScaleRatioY || captchaScaleRatioX );
    captchaScaleRatio = this.game.width * 0.0008;

    let captchaX = this.game.scale.bounds.x + this.game.scale.bounds.width * 0.5 - $( '.g-recaptcha' ).width() * 0.5 * captchaScaleRatio;
    let captchaY = this.game.scale.bounds.y + this.game.scale.bounds.height * 0.47;

    $( '.g-recaptcha' ).css( 'left', captchaX + 'px' );
    $( '.g-recaptcha' ).css( 'top', captchaY );
    if ( captchaScaleRatio ) {
      $( '.g-recaptcha' ).css( 'transform', 'scale( ' + captchaScaleRatio + ' )' );
      $( '.g-recaptcha' ).css( '-webkit-transform', 'scale( ' + captchaScaleRatio + ' )' );
      $( '.g-recaptcha' ).css( 'transform-origin', '0 0' );
      $( '.g-recaptcha' ).css( '-webkit-transform-origin', '0 0' );
    }
  }
  _shakeField ( field ) {
    this.game.make.tween( field ).to( { x: field.x + 1 }, 100, Phaser.Easing.Bounce.InOut, true, 0, 3, true ).onComplete.removeAll();
  }
  _enterClicked ( target, event ) {
    if ( $( '.g-recaptcha' ) && $( '.g-recaptcha' ).is( ':visible' ) ) {
      $( '.g-recaptcha-form' ).attr( 'action', 'javascript:window.captchaResponse = grecaptcha.getResponse(window.captchaObject);grecaptcha.reset();' );
      $( '.g-recaptcha-form' ).submit();
    }

    let username = this.inputLogin.domElement.element.value;
    let password = this.inputPassword.domElement.element.value;

    if ( !username.length ) { this._shakeField( this.inputLogin ); return; }
    if ( !password.length ) { this._shakeField( this.inputPassword ); return; }
    // if ( this.attempts && !window.captchaResponse ) return;
    if ( server.wrongAttemps < 46 && !window.captchaResponse && $( '.g-recaptcha' ) && $( '.g-recaptcha' ).is( ':visible' ) ) return;
    window.captchaResponse = null;

    server.AUTH.USER = username;
    server.AUTH.PASSWORD = password;

    if ( this.callbackFunction ) this.callbackFunction.call();

    this._close();
    /*
    input.domElement.element.value.setAttribute('inputmode', 'numeric');
    input.domElement.element.setAttribute('pattern', '[0-9]*');
    input.domElement.element.type = 'number';
    */
  }
  _clickedOutsideThePopup () {
    // this._close();
    // if ( this.onDeclineCallback ) this.onDeclineCallback.call();
  }
  _clickEnterOnInputLogin () {
    this.inputLogin.endFocus();
    let self = this;
    setTimeout( function () { self.inputPassword.startFocus(); }, 1 );

    // this.inputPassword.domElement.element.select();
  }
  _clickEnterOnInputPassword () {
    this.inputPassword.endFocus();
    this._enterClicked();
  }
  /**
   * Keypad delegate
   */
  KeypadNumberClicked ( number ) {
    if ( !this.currentInput ) return;
    this.currentInput.value += number.toString();
    this.currentInput.setText( this.currentInput.value );
  }
  KeypadDeleteClicked () {
    if ( !this.currentInput ) return;
    this.currentInput.startFocus();
    let currentCursorPosition = this.currentInput.domElement.element.selectionStart;
    this.currentInput.endFocus();
    let string = this.currentInput.value.split( '' );
    string.splice( currentCursorPosition - 1, 1 );
    string = string.join( '' );
    this.currentInput.value = string;
    this.currentInput.setText( this.currentInput.value );
  }
  KeypadEnterClicked () {
    switch ( this.currentInput ) {
      case this.inputLogin:
        this._clickEnterOnInputLogin();
        break;
      case this.inputPassword:
        this._clickEnterOnInputPassword();
        break;
      default:
        break;
    }
  }
}
