/*
 * Base Reel
 * ====
 *
 *Base  Reel Group Object
 */
import BaseGarbageCollectionGroup from './BaseGarbageCollectionGroup.js';
export default class Reel extends BaseGarbageCollectionGroup {
  constructor ( game, arrayOfTilesIDs, visibleRows, delegate ) {
    super( game, game.world );

    this.activeIcons = [];

    this.tileWidth = 0;
    this.tileHeight = 0;

    this.reelWidth = 0;
    this.reelHeight = 0;

    this.regularSpinsIDs = arrayOfTilesIDs;
    this.freeSpinsIDs = arrayOfTilesIDs; // should be [] -> just to avoid some logic erros as a default the same as regular spins

    this.visibleRows = visibleRows || 0;
    this.IDs = arrayOfTilesIDs || [ 1 ];
    this.resultID = 0;

    /**
     * Delegate methods:
     * reelStartedStop();
     * reelStopped();
     * reelHyperSpinStarted();
     */

    this.delegate = delegate;

    this.scatterBG = null;
    this.bonusBG = null;
    this.reelAnimation = null;
    this.isHyper = false;

    this._updateIcons();

    this.STATES = {
      STATE_A_SLEEP: 0,
      STATE_IN_MOVEMENT: 1,
      STATE_SHOW_RESULT: 2
    };

    this.currentState = this.STATES.STATE_A_SLEEP;

    this.tweenTime = 500;// 750;
    this.speedParam = 0.25;// 0.2
    this.speedDefaultValue = this.tileHeight * this.speedParam;
    this.speedCurrentValue = 0;
  }
  destroy () {
    while ( this.activeIcons.length ) this.activeIcons.pop().destroy();

    delete this.tileWidth;
    delete this.tileHeight;

    delete this.reelWidth;
    delete this.reelHeight;

    delete this.visibleRows;
    delete this.IDs;
    delete this.resultID;
    delete this.regularSpinsIDs;
    delete this.freeSpinsIDs;

    if ( this.scatterBitmap ) this.scatterBitmap.destroy( true ); this.scatterBitmap = null;
    if ( this.scatterBG ) this.scatterBG.destroy( true ); this.scatterBG = null;
    if ( this.bonusBitmap ) this.bonusBitmap.destroy( true ); this.bonusBitmap = null;
    if ( this.bonusBG ) this.bonusBG.destroy( true ); this.bonusBG = null;
    if ( this.reelAnimation ) this.reelAnimation.destroy( true ); this.reelAnimation = null;
    if ( this.isHyper ) delete this.isHyper; this.isHyper = null;

    delete this.STATES;

    delete this.currentState;

    delete this.tweenTime;
    delete this.speedParam;
    delete this.speedDefaultValue;
    delete this.speedCurrentValue;
    super.destroy();
  }
  update () {
    switch ( this.currentState ) {
      case this.STATES.STATE_A_SLEEP: {
        break;
      }

      case this.STATES.STATE_IN_MOVEMENT: {
        this._updateIcons();
        this._move();
        break;
      }

      case this.STATES.STATE_SHOW_RESULT: {
        this._showResult();
        break;
      }
      default:
        break;
    }
  }
  /**
   * Private methods
   */
  _updateIcons () {
    if ( !this.activeIcons.length ) {
      for ( let i = -this.visibleRows; i <= this.visibleRows; i++ ) {
        let iconID = ( i < 0 ) ? this.IDs.length + i : i;
        let iconFileNameID = this.IDs[ iconID ];
        let lastElementID = i + this.visibleRows;

        this.activeIcons.push( this.game.make.image( 0, 0, 'Icons', 'tile' + ( iconFileNameID + '.png' ) ) );
        this.activeIcons[ lastElementID ].y = i * this.activeIcons[ lastElementID ].height;
        this.activeIcons[ lastElementID ].key = iconID.toString();

        if ( !this.bonusBG ) this._setupBonusBG( this.activeIcons[ lastElementID ].width, this.activeIcons[ lastElementID ].height );
        if ( !this.scatterBG ) this._setupScatterBG( this.activeIcons[ lastElementID ].width, this.activeIcons[ lastElementID ].height );
        this.add( this.activeIcons[ lastElementID ] );
      }
      this.tileWidth = this.activeIcons[ 0 ].width;
      this.tileHeight = this.activeIcons[ 0 ].height;

      this.reelWidth = this.tileWidth;
      this.reelHeight = this.tileHeight * this.visibleRows;
      return;
    }

    let lastIcon = this.activeIcons[ 0 ];
    if ( lastIcon.y > this.tileHeight * -this.visibleRows ) {
      let iconID = parseInt( lastIcon.key );
      let newIconID = ( iconID || this.IDs.length ) - 1;
      let newIconFileNameID = this.IDs[ newIconID ];
      this.activeIcons.unshift( this.game.make.image( 0, 0, 'Icons', 'tile' + ( newIconFileNameID + '.png' ) ) );
      this.activeIcons[ 0 ].y = lastIcon.y - this.tileHeight;
      this.activeIcons[ 0 ].key = newIconID.toString();
      this.add( this.activeIcons[ 0 ] );
    }
    let firstIcon = this.activeIcons[ this.activeIcons.length - 1 ];
    if ( firstIcon.y > this.tileHeight * this.visibleRows )
      this.activeIcons.pop().destroy( true );
  }

  _updateIconsForStop ( hyperSpin ) {
    if ( hyperSpin ) {
      this._addHyperSpinIcons();
      if ( this.delegate ) this.delegate.reelHyperSpinStarted();
    }
    let resultID = this.resultID || this.IDs.length;
    let lastReelID = this.IDs.length - 1;
    let lastIcon = this.activeIcons[ 0 ] || null;

    for ( let i = -1; i < 5; i++ ) {
      let idToadd = resultID - i;

      if ( idToadd < 0 ) idToadd = lastReelID + idToadd;
      else if ( idToadd > lastReelID ) idToadd = idToadd - this.IDs.length;

      let iconFileNameToAdd = this.IDs[ idToadd ];
      this.activeIcons.unshift( this.game.make.image( 0, 0, 'Icons', 'tile' + ( iconFileNameToAdd + '.png' ) ) );
      this.activeIcons[ 0 ].y = ( lastIcon ? lastIcon.y : 0 ) - this.tileHeight * ( i + 2 );
      this.activeIcons[ 0 ].key = idToadd.toString();
      this.add( this.activeIcons[ 0 ] );
    }
  }

  _addHyperSpinIcons () {
    let hyperSpinsReelsAmount = 3;
    let lastIcon = this.activeIcons[ 0 ];
    for ( let i = 0; i < hyperSpinsReelsAmount; i++ ) {
      let imageID = i % 2 + 1;
      let hyperSpinReel = this.game.make.image( 0, 0, 'Icons', 'iconsBlur' + imageID + '.png' );
      hyperSpinReel.scale.y = lastIcon.width / hyperSpinReel.width;
      hyperSpinReel.scale.x = lastIcon.width / hyperSpinReel.width;
      this.activeIcons.unshift( hyperSpinReel );
      this.activeIcons[ 0 ].y = lastIcon.y - hyperSpinReel.height;
      this.add( this.activeIcons[ 0 ] );

      lastIcon = this.activeIcons[ 0 ];
      // // this._garbage.push( hyperSpinReel );
    }
  }

  _showResult () {
    let resultIconPositionY = this.activeIcons[ 4 ].y;
    let speed = this.speedDefaultValue;
    let diff = Math.abs( resultIconPositionY );
    if ( resultIconPositionY < 0 && diff < this.speedDefaultValue ) speed = diff;

    if ( Math.ceil( resultIconPositionY ) >= 0 ) {
      this.currentState = this.STATES.STATE_A_SLEEP;
      this.activeIcons.forEach( function ( element, index ) {
        let targetPositionY = ( index - this.visibleRows ) * this.tileHeight;
        let tweenBack = this.game.make.tween( element );
        tweenBack.to( { y: targetPositionY }, this.tweenTime, Phaser.Easing.Back.Out );
        tweenBack.onComplete.add( function ( ) {
          this.speedCurrentValue = 0;
        }, this );
        tweenBack.start();
      }, this );
      this._startedStop();
      new Promise( ( resolve ) => setTimeout( resolve, this.tweenTime * 0.4 ) ).then( () => {
        this._stopped();
      } );

      return;
    }

    this._move( speed );
  }

  _move ( speed ) {
    let movementSpeed = speed || this.speedDefaultValue;
    if ( this.speedCurrentValue < this.speedDefaultValue )
      this.speedCurrentValue += this.speedDefaultValue * 0.025;
    else
      this.speedCurrentValue = movementSpeed;

    this.activeIcons.forEach( function ( element, index ) {
      element.y += this.speedCurrentValue;
    }, this );
  }
  _startedStop () {
    if ( !this.delegate ) return;

    this.delegate.reelStartedStop();
  }

  _stopped () {
    if ( !this.delegate ) return;

    this.delegate.reelStopped();
  }
  _setupScatterBG ( iconWidth, iconHeight ) {
    if ( this.scatterBG ) return;

    if ( this.scatterBitmap ) this.scatterBitmap.destroy( true ); this.scatterBitmap = null;
    this.scatterBitmap = this.game.make.bitmapData( iconWidth, iconHeight * this.visibleRows );
    this.scatterBitmap.rect( 0, 0, iconWidth, iconHeight * this.visibleRows, '#009EBE' );

    this.scatterBG = this.game.make.image( 0, 0, this.scatterBitmap, 0 );
    this.scatterBG.alpha = 0;
    this.add( this.scatterBG );
  }
  _setupBonusBG ( iconWidth, iconHeight ) {
    if ( this.bonusBG ) return;

    if ( this.bonusBitmap ) this.bonusBitmap.destroy( true ); this.bonusBitmap = null;
    this.bonusBitmap = this.game.make.bitmapData( iconWidth, iconHeight * this.visibleRows );
    this.bonusBitmap.rect( 0, 0, iconWidth, iconHeight * this.visibleRows, '#009EBE' );

    this.bonusBG = this.game.make.image( 0, 0, this.bonusBitmap, 0 );
    this.bonusBG.alpha = 0;
    this.add( this.bonusBG );
  }
  /**
   * public methods
   */
  move () {
    if ( this.isInMovement() || this.isShowingResult() ) return;
    this.currentState = this.STATES.STATE_IN_MOVEMENT;
  }

  stopAt ( resultID ) {
    if ( !this.isInMovement() ) return;
    this.resultID = resultID;
    this.currentState = this.STATES.STATE_SHOW_RESULT;
    this._updateIconsForStop( this.isHyper );
  }

  updateVisibleIcons ( iconsArray ) {
    iconsArray.forEach( function ( element, index ) {
      this.activeIcons[ index + this.visibleRows ].loadTexture( 'Icons', 'tile' + element + '.png' );
      this.activeIcons[ index + this.visibleRows ].key = this.getTileIDByIconID( element );
    }, this );
  }

  updateIcons ( iconsArray ) {
    iconsArray.forEach( function ( element, index ) {
      this.activeIcons[ element.index + this.visibleRows ].scale.set( 1.0 );
      this.activeIcons[ element.index + this.visibleRows ].y = element.index * this.tileHeight;
      this.activeIcons[ element.index + this.visibleRows ].loadTexture( 'Icons', 'tile' + element.iconID + '.png' );
      this.activeIcons[ element.index + this.visibleRows ].key = this.getTileIDByIconID( element.iconID );
    }, this );
  }

  isInMovement () {
    return this.currentState === this.STATES.STATE_IN_MOVEMENT;
  }

  isShowingResult () {
    return this.currentState === this.STATES.STATE_SHOW_RESULT;
  }

  isInactive () {
    return this.currentState === this.STATES.STATE_A_SLEEP;
  }

  getVisibleTileIDs () {
    let returnArray = [];
    for ( let i = 0; i < this.visibleRows; i++ ) {
      let iconID = parseInt( this.activeIcons[ i + this.visibleRows ].key );
      returnArray.push( iconID );
    }

    return returnArray;
  }

  getVisibleIconsIDs () {
    let visibleTilesIDs = this.getVisibleTileIDs();
    let returnArray = [];
    for ( let i = 0; i < visibleTilesIDs.length; i++ )
      returnArray[ i ] = this.IDs[ visibleTilesIDs[ i ] ];

    return returnArray;
  }

  getTileIDByIconID ( iconID ) {
    let tileID = null;
    for ( let i = 0; i < this.IDs.length; i++ )
      if ( this.IDs[ i ] === iconID ) tileID = i;
    return tileID;
  }

  hideTileByID ( id ) {
    if ( id + this.visibleRows >= this.activeIcons.length ) return;
    this.activeIcons[ id + this.visibleRows ].alpha = 0.0;
  }

  showTileByID ( id ) {
    if ( id + this.visibleRows >= this.activeIcons.length ) return;
    this.activeIcons[ id + this.visibleRows ].alpha = 1.0;
  }

  showAllVisibleTiles () {
    this.activeIcons.forEach( function ( element, index ) {
      element.alpha = 1.0;
    } );
  }

  /**
   * Free Spins
   * */
  setFreeSpinsTileIDs ( freeSpinsTileIDs ) {
    this.freeSpinsIDs = freeSpinsTileIDs;
  }

  startFreeSpins () {
    this.IDs = this.freeSpinsIDs;
  }

  endFreeSpins () {
    this.IDs = this.regularSpinsIDs;
  }
  /**
   * Free Spins End
   */

  showScatter () {
    if ( this.scatterBG ) this.scatterBG.alpha = 1;

    if ( this.reelAnimation || !this.game.cache.checkImageKey( 'BonusAnimation' ) ) return;

    this.reelAnimation = this.game.make.sprite( 0, 0, 'ScatterAnimation' );
    let framesAmount = this.game.cache.getFrameCount( 'ScatterAnimation' );
    let frames = Phaser.Animation.generateFrameNames( 'SCATTER' + '_', 0, framesAmount, '.png', 4 );
    this.reelAnimation.animations.add( 'SCATTER', frames );
    this.reelAnimation.animations.play( 'SCATTER', framesAmount, true );
    this.reelAnimation.animations.currentAnim.onComplete.add( function () {
      this.reelAnimation.destroy( true );
      this.reelAnimation = null;
    }, this );
    this.add( this.reelAnimation );
    // // this._garbage.push( this.reelAnimation.animations );
    // // this._garbage.push( this.reelAnimation );
  }

  showBonus () {
    if ( this.bonusBG ) this.bonusBG.alpha = 1;

    if ( this.reelAnimation || !this.game.cache.checkImageKey( 'BonusAnimation' ) ) return;

    this.reelAnimation = this.game.make.sprite( -this.tileWidth * 0.6, -this.tileWidth * 0.45, 'BonusAnimation' );
    let framesAmount = this.game.cache.getFrameCount( 'BonusAnimation' );
    let frames = Phaser.Animation.generateFrameNames( 'BONUS' + '_', 0, framesAmount, '.png', 4 );
    this.reelAnimation.animations.add( 'BONUS', frames );
    this.reelAnimation.scale.set( this.reelHeight / this.reelAnimation.height );
    this.reelAnimation.animations.play( 'BONUS', framesAmount, true );
    this.reelAnimation.animations.currentAnim.onComplete.add( function () {
      this.reelAnimation.kill();
      this.reelAnimation.destroy( true );
      this.reelAnimation = null;
    }, this );
    this.add( this.reelAnimation );
    // // this._garbage.push( this.reelAnimation.animations );
    // // this._garbage.push( this.reelAnimation );
  }

  hideScatter () {
    this.game.add.tween( this.scatterBG ).to( { alpha: 0.0 }, 1000, Phaser.Easing.Exponential.Out, true );

    if ( !this.reelAnimation ) return;

    this.game.add.tween( this.reelAnimation ).to( { alpha: 0.0 }, 1000, Phaser.Easing.Exponential.Out, true ).onComplete.add( function () {
      this.reelAnimation.kill();
      this.reelAnimation.destroy();
      this.remove( this.reelAnimation, true );
      this.reelAnimation = null;
    }, this );
  }

  hideBonus () {
    this.game.add.tween( this.bonusBG ).to( { alpha: 0.0 }, 1000, Phaser.Easing.Exponential.Out, true );

    if ( !this.reelAnimation ) return;

    this.game.add.tween( this.reelAnimation ).to( { alpha: 0.0 }, 1000, Phaser.Easing.Exponential.Out, true ).onComplete.add( function () {
      this.reelAnimation.kill();
      this.reelAnimation.destroy();
      this.remove( this.reelAnimation, true );
      this.reelAnimation = null;
    }, this );
  }

  hideHyper () {
    this.hideScatter();
    this.hideBonus();
    this.isHyper = false;
  }

  isBonusPresent () {
    if ( this.isInMovement() || this.isShowingResult() ) return false;
    let isBonusPresent = false;
    let resultIconsIDs = this.getVisibleIconsIDs();
    for ( let i = 0; i < resultIconsIDs.length; i++ )
      if ( resultIconsIDs[ i ] === 3 ) isBonusPresent = true;

    return isBonusPresent;
  }

  isScatterPresent () {
    if ( this.isInMovement() || this.isShowingResult() ) return false;
    let isScatterPresent = false;
    let resultIconsIDs = this.getVisibleIconsIDs();
    for ( let i = 0; i < resultIconsIDs.length; i++ )
      if ( resultIconsIDs[ i ] === 2 ) isScatterPresent = true;

    return isScatterPresent;
  }
}
