import server from '../objects/Server';
import popups from '../objects/Popups.js';
import LoadingOverlay from '../objects/LoadingOverlay.js';
import BaseGarbageCollectionState from '../base/BaseGarbageCollectionState.js';
export default class Login extends BaseGarbageCollectionState {
  preload () {}
  init () {
    super.init();
    this.loadingOverlay = null;
  }
  shutdown () {
    if ( this.loadingOverlay ) this.loadingOverlay.destroy( true ); this.loadingOverlay = null;
    super.shutdown();
  }
  create () {
    super.create();
    this._setupLoginPopup();
  }
  _setupLoginPopup () {
    let self = this;
    if ( this.loadingOverlay ) this.loadingOverlay.destroy( true ); this.loadingOverlay = null;
    popups.getPopupService( this.game ).addPopup( popups.TYPE.Login, { 'callbackFunction': function () {
      self.loadingOverlay = new LoadingOverlay( self.game, 'Login...' );
      self.add.existing( self.loadingOverlay );
      self.localGarbage.push( self.loadingOverlay );
      server.login( self.game, function () {
        if ( server.isLoggedIn() ) {
          self.loginPopuperrorPOSUser = false;
          self.loginPopupWrongUsernamePassword = false;
          server.loginPOSUser = false;
          server.getJackpotStatus( self.game, function () {
            self.state.start( 'Preloader', true, false );
          } );
        } else {
          self.loginPopuperrorPOSUser = false;
          self.loginPopupWrongUsernamePassword = true;
          if ( server.loginPOSUser ) self.loginPopuperrorPOSUser = true;
          else self.loginPopupWrongUsernamePassword = true;
          self._setupLoginPopup();
        }
      } );
    },
    'posUser': this.loginPopuperrorPOSUser,
    'wrong': this.loginPopupWrongUsernamePassword } );
  }
}
