/*
 * PopupNoInternetConnection
 * ==========
 *
 * Popup No Internet Connection Object
 */
import PopupBase from './../base/BasePopup.js';
export default class PopupNoInternetConnection extends PopupBase {
  constructor ( game, params ) {
    super( game );
    this.callbackFunction = params ? ( params[ 'callbackFunction' ] || null ) : null;
    this._addPopupBase();
  }
  destroy () {
    if ( this.popupBase ) this.popupBase.destroy( true ); this.popupBase = null;
    if ( this.bitmapBG ) this.bitmapBG.destroy( true ); this.bitmapBG = null;
    if ( this.popupRay1 ) this.popupRay1.destroy( true ); this.popupRay1 = null;
    if ( this.popupRay2 ) this.popupRay2.destroy( true ); this.popupRay2 = null;
    if ( this.oopsSign ) this.oopsSign.destroy( true ); this.oopsSign = null;
    if ( this.sign ) this.sign.destroy( true ); this.sign = null;
    if ( this.noInternetConnectionText ) this.noInternetConnectionText.destroy( true ); this.noInternetConnectionText = null;
    if ( this.strokeCorner ) this.strokeCorner.destroy( true ); this.strokeCorner = null;
    if ( this.strokeLine ) this.strokeLine.destroy( true ); this.strokeLine = null;
    if ( this.strokeLineVertical ) this.strokeLineVertical.destroy( true ); this.strokeLineVertical = null;

    if ( this.bgImage ) this.bgImage.destroy( true ); this.bgImage = null;
    if ( this.btn ) this.btn.destroy( true ); this.btn = null;
    if ( this.okText ) this.okText.destroy( true ); this.okText = null;
    this.callbackFunction = null;
    super.destroy();
  }

  _addPopupBase () {
    let popupRayCache = this.game.cache.getFrameData( 'Popups' ).getFrameByName( 'PopupRay1.png' );
    let width = popupRayCache.width * 1.34;
    let height = popupRayCache.height * 1.28;
    popupRayCache = null;

    this.popupBase = this.game.make.image( 0, 0, 'Popups', 'PopupBase.png' );
    this.bitmapBG = this.game.make.bitmapData( width, height );

    let tileWidth = this.popupBase.width * 0.99;
    let tileHeight = this.popupBase.height * 0.99;

    let widthAmount = Math.ceil( width / tileWidth );
    let heightAmount = Math.ceil( height / tileHeight );

    for ( let w = 0; w < widthAmount; w++ )
      for ( let h = 0; h < heightAmount; h++ ) {
        let bitmapX = ( w + ( w === ( widthAmount - 1 ) ? -0.25 : 0.1 ) ) * tileWidth;
        let bitmapY = ( h + ( h >= ( heightAmount - 1 ) ? -0.25 : 0.1 ) ) * tileHeight;
        this.bitmapBG.draw( this.popupBase, bitmapX, bitmapY );
      }

    this.popupRay1 = this.game.make.image( 0, 0, 'Popups', 'PopupRay1.png' );
    this.popupRay1.alpha = 0.2;
    this.popupRay1.scale.set( 1.5 );
    this.popupRay1.anchor.set( 0.5 );
    this.bitmapBG.draw( this.popupRay1, width * 0.5, height * 0.45 );

    this.popupRay2 = this.game.make.image( 0, 0, 'Popups', 'PopupRay2.png' );
    this.popupRay2.alpha = 0.2;
    this.popupRay2.scale.set( 1.5 );
    this.popupRay2.anchor.set( 0.5 );
    this.bitmapBG.draw( this.popupRay2, width * 0.5, height * 0.45 );

    this.oopsSign = this.game.make.image( 0, 0, 'Popups', 'Oops.png' );
    this.bitmapBG.draw( this.oopsSign, ( width - this.oopsSign.width ) * 0.5, ( height - this.oopsSign.height ) * 0.1 );

    this.sign = this.game.make.image( 0, 0, 'Popups', 'PopupNoInternetConnectionSign.png' );
    this.sign.anchor.set( 0.5 );
    this.bitmapBG.draw( this.sign, width * 0.5, height * 0.45 );

    this.noInternetConnectionText = this.game.make.text( 0, 0, 'INTERNET CONNECTION LOST', { font: 'bold ' + width * 0.06 + 'px Open Sans', fill: '#ffff00' } );
    this.noInternetConnectionText.anchor.set( 0.5 );
    this.bitmapBG.draw( this.noInternetConnectionText, width * 0.5, height * 0.7 );

    this.strokeCorner = this.game.make.image( 0, 0, 'Popups', 'PopupStrokeCorner.png' );
    this.bitmapBG.draw( this.strokeCorner, 0, 0 );
    this.strokeCorner.scale.x = -1;
    this.bitmapBG.draw( this.strokeCorner, width, 0 );
    this.strokeCorner.scale.y = -1;
    this.bitmapBG.draw( this.strokeCorner, width, height );
    this.strokeCorner.scale.x = 1;
    this.bitmapBG.draw( this.strokeCorner, 0, height );

    this.strokeLine = this.game.make.image( 0, 0, 'Popups', 'PopupStrokeLine.png' );
    this.bitmapBG.draw( this.strokeLine, this.strokeCorner.width, 0, width - this.strokeCorner.width * 2, this.strokeLine.height );
    this.strokeLine.scale.y = -1;
    this.bitmapBG.draw( this.strokeLine, this.strokeCorner.width, height * 0.9, width - this.strokeCorner.width * 2, this.strokeLine.height );

    this.strokeLineVertical = this.game.make.image( 0, 0, 'Popups', 'PopupStrokeLineVertical.png' );
    this.bitmapBG.draw( this.strokeLineVertical, 0, this.strokeLineVertical.height, this.strokeLineVertical.width, height - this.strokeLineVertical.height * 2 );
    this.strokeLineVertical.scale.x = -1;
    this.bitmapBG.draw( this.strokeLineVertical, width * 0.9, this.strokeLineVertical.height, this.strokeLineVertical.width, height - this.strokeLineVertical.height * 2 );

    this.bgImage = this.game.make.image( this.game.world.centerX, this.game.world.centerY, this.bitmapBG );
    this.bgImage.anchor.set( 0.5 );
    this.add( this.bgImage );

    this.btn = this.game.make.button( this.game.world.centerX, this.game.world.centerY + height * 0.35, 'Popups', this._btnCloseClick, this );
    this.btn.setFrames( 'PopupGreenButton.png', 'PopupGreenButton.png', 'PopupGreenButtonPressed.png', 'PopupGreenButton.png' );
    this.btn.anchor.set( 0.5, 0.5 );
    this.add( this.btn );

    this.okText = this.game.make.text( this.game.world.centerX, this.game.world.centerY + height * 0.35, 'OK', { font: 'bold ' + width * 0.06 + 'px Open Sans', fill: '#ffffff' } );
    this.okText.anchor.set( 0.5 );
    this.add( this.okText );
  }

  _btnCloseClick ( target, event ) {
    if ( this.callbackFunction ) this.callbackFunction.call();
    this._close();
  }
}
