/*
 * Popup Jackpot
 * ==========
 *
 * Popup Jackpot Object
 */
import PopupBase from '../base/BasePopup.js';
import { Math } from 'phaser-ce';
import PluginDecimalText from '../plugins/PluginDecimalText';
import server from '../objects/Server';
import get from '../objects/Get.js';
export default class PopupWinner extends PopupBase {
  constructor ( game, params, sounds ) {
    super( game );
    server.isShowingPopup = true;
    params = params || {};
    this.sounds = sounds;
    this.winValue = params[ 'winValue' ] || 0;
    this.winnerID = params[ 'winnerID' ] || 0;
    this.type = params[ 'type' ] || 0;
    this.personal = parseInt( this.type ) === 3;
    this.callback = params[ 'callback' ] || null;
    this.personalValue = params[ 'personalValue' ];
    this.minorValue = params[ 'minorValue' ];
    this.majorValue = params[ 'majorValue' ];
    this.grandValue = params[ 'grandValue' ];

    this.gradientArray = [
      [ 0, '#9C9C9C' ],
      [ 0.1, '#E0E0E0' ],
      [ 0.2, '#FFFFFF' ],
      [ 0.7, '#B3B3B3' ],
      [ 0.85, '#D1D1D1' ],
      [ 1.0, '#9D9D9D' ]
    ];
    this.isInSecondStage = false;
    this.firstStageTimer = null;

    this.thirdStageTimer = null;
    this.thirdStageSoundTimer = null;

    this.coinsAmount = 30;
    this.coinsBack = [];
    this.coinsFront = [];

    this.soundBG = this.game.add.audio( 'musicLoop' ); this.soundBG.loopFull(); this.soundBG.fadeIn( 1500 );
    this.soundEagleShow = this.game.add.audio( 'eagleShow' );

    this.soundEagleIn = this.game.add.audio( 'eagleIn' );
    this.soundEagleOut = this.game.add.audio( 'eagleOut' );
    this.soundCoins = this.game.add.audio( 'jackpotCoins' );

    this._generateBackCoins();

    this._runFirstStage();
  }
  destroy () {
    server.isShowingPopup = false;
    if ( this.firstStageTimer ) { this.firstStageTimer.stop( true ); this.firstStageTimer.destroy(); } this.firstStageTimer = null;

    if ( this.thirdStageTimer ) { this.thirdStageTimer.stop( true ); this.thirdStageTimer.destroy(); } this.thirdStageTimer = null;
    if ( this.thirdStageSoundTimer ) { this.thirdStageSoundTimer.stop( true ); this.thirdStageSoundTimer.destroy(); } this.thirdStageSoundTimer = null;
    this.coinsBack.forEach( function ( element, index ) {
      if ( element.tween ) element.tween.stop( true ); element.tween = null;
      if ( element.coins ) element.coins.destroy( true ); element.coins = null;
    }, this );
    this.coinsBack = [];
    this.coinsFront.forEach( function ( element, index ) {
      if ( element.tween ) element.tween.stop( true ); element.tween = null;
      if ( element.coins ) element.coins.destroy( true ); element.coins = null;
    }, this );
    this.coinsFront = [];

    if ( this.prizeAmountText ) this.prizeAmountText.destroy( true ); this.prizeAmountText = null;

    if ( this.soundBG ) this.soundBG.destroy( true ); this.soundBG = null;
    if ( this.soundEagleShow ) this.soundEagleShow.destroy( true ); this.soundEagleShow = null;
    if ( this.soundEagleIn ) this.soundEagleIn.destroy( true ); this.soundEagleIn = null;
    if ( this.soundEagleOut ) this.soundEagleOut.destroy( true ); this.soundEagleOut = null;
    if ( this.soundCoins ) this.soundCoins.destroy( true ); this.soundCoins = null;

    if ( this.isInSecondStage ) this.isInSecondStage = null; delete this.isInSecondStage;

    if ( this.coinsAmount ) this.coinsAmount = null; delete this.coinsAmount;
    if ( this.currentWinner ) this.currentWinner = null; delete this.currentWinner;
    super.destroy();
  }

  _runFirstStage () {
    // sound entrance
    this.soundBG.loopFull();

    let blueRays = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotBlueRays' );
    blueRays.anchor.set( 0.5 );
    blueRays.scale.set( 2.0 );
    blueRays.alpha = 0.0;
    this.add( blueRays );
    this.localGarbage.push( blueRays );

    let firstStageTime = Phaser.Timer.SECOND * 2;

    this.game.make.tween( blueRays )
      .to( { alpha: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime );

    this.game.make.tween( blueRays.scale )
      .to( { x: 1.0, y: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( true )
      .yoyoDelay( firstStageTime );

    let eagleString = 'Eagle.png';
    if ( get.projectType === 2 ) eagleString = 'Eagle2.png';
    let eagle = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupWinner', eagleString );
    eagle.anchor.set( 0.5 );
    eagle.scale.set( 2.0 );
    eagle.alpha = 0.0;
    this.add( eagle );
    this.localGarbage.push( eagle );

    this.soundEagleIn.play();

    this.game.make.tween( eagle )
      .to( { alpha: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( false );

    this.game.make.tween( eagle.scale )
      .to( { x: 1.0, y: 1.0 }, firstStageTime * 0.5, Phaser.Easing.Linear.None, true )
      .yoyo( false )
      .onComplete.add( function () {
        this.soundEagleShow.loopFull();
        this._runSecondStage();
      }, this );
    firstStageTime = null;
  }
  _runSecondStage () {
    this.isInSecondStage = true;
    this.currentWinner = 0;
    let secondStageTime = Phaser.Timer.SECOND * 4;

    this.prizeAmountText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.5, this.game.height * 1.2, 0, this.winValue, 'TournamentWinFont', this.game.width * 0.06, '$' );
    this.prizeAmountText.getText().anchor.set( 0.5 );
    this.prizeAmountText.getText().scale.set( 1.2 );
    this.prizeAmountText.setText( this.winValue, false );
    this.add( this.prizeAmountText.getText() );

    this.rankText = this.game.make.image( this.game.width * 0.5, this.game.height * 1.2, 'Window', '1.png' );
    this.rankText.anchor.set( 0.5 );
    this.rankText.scale.set( 0.9 );
    this.add( this.rankText );
    this.localGarbage.push( this.rankText );

    let adsBigText = this.game.make.image( this.game.width * 0.5, this.game.height * 1.2, 'Window', 'leaderboardPrize.png' );
    adsBigText.anchor.set( 0.5 );
    adsBigText.scale.set( 1.5 );
    this.add( adsBigText );
    this.localGarbage.push( adsBigText );

    let userIDbg = this.game.make.image( this.game.width * 0.5, this.game.height * 1.2, 'PopupWinner', 'Counter_Meter.png' );
    userIDbg.anchor.set( 0.5 );
    userIDbg.scale.set( 1 );
    this.add( userIDbg );
    this.localGarbage.push( userIDbg );

    this.userID = this.game.plugins.add( PluginDecimalText, this.game.width * 0.5, this.game.height * 0.875, 0, this.winnerID, 'TournamentWinFont', this.game.width * 0.06, '' );
    this.userID.getText().anchor.set( 0.5 );
    this.userID.getText().scale.set( 1.08 );
    this.userID.setText( this.winnerID, false );
    this.userID.getText().alpha = 0;
    this.add( this.userID.getText() );
    this.localGarbage.push( this.userID );

    this.winPonitsNum = this.game.make.text( this.game.width * 0.5, this.game.height * 0.63, 'THE TOURNAMENT FIRST PLACE WINNER IS:', { font: 'bold ' + this.game.width * 0.032 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
    this.winPonitsNum.anchor.set( 0.5 );
    let userTextGradient = this.winPonitsNum.context.createLinearGradient( 0, 0, 0, this.winPonitsNum.height );
    this.winPonitsNum.fill = userTextGradient;
    this.winPonitsNum.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
    userTextGradient.addColorStop( 0, '#FA120B' );
    userTextGradient.addColorStop( 0.15, '#FFCB07' );
    userTextGradient.addColorStop( 0.4, '#FFFF56' );
    userTextGradient.addColorStop( 0.5, '#FFFFC5' );
    userTextGradient.addColorStop( 0.7, '#FFA10B' );
    userTextGradient.addColorStop( 0.85, '#B45508' );
    userTextGradient.addColorStop( 1, '#FFA10B' );
    userTextGradient = null;
    this.winPonitsNum.alpha = 0;
    this.add( this.winPonitsNum );
    this.localGarbage.push( this.winPonitsNum );

    this._generateFrontCoins();
    this._startCoinsAnimations();

    this.game.make.tween( this.rankText )
      .to( { y: this.game.height * 0.15 }, secondStageTime * 0.2, Phaser.Easing.Linear.None, true )
      .yoyo( false ).onComplete.add( function () {
        this.game.make.tween( adsBigText )
          .to( { y: this.game.height * 0.34 }, secondStageTime * 0.2, Phaser.Easing.Linear.None, true )
          .yoyo( false ).onComplete.add( function () {
            this.game.make.tween( this.prizeAmountText.getText() )
              .to( { y: this.game.height * 0.6 }, secondStageTime * 0.15, Phaser.Easing.Linear.None, true )
              .yoyo( false ).onComplete.add( function () {
                this.game.make.tween( userIDbg )
                  .to( { y: this.game.height * 0.85 }, secondStageTime * 0.1, Phaser.Easing.Linear.None, true )
                  .yoyo( false ).onComplete.add( function () {
                    // this.winPonitsNum.alpha = 1;
                    this.userID.getText().alpha = 1;
                    this.currentWinner = 1;
                    this._showMultiWinner( this.currentWinner );
                  }, this );
              }, this );
          }, this );
      }, this );
    secondStageTime = null;
  }
  _showMultiWinner ( winnerCount ) {
    let self = this;
    if ( winnerCount < server.winValueList.length )
      new Promise( ( resolve ) => setTimeout( resolve, 5000 ) ).then( () => {
        self.userID.setText( server.winPlayerNumberList[ self.currentWinner ], false );
        self.prizeAmountText.setText( server.winValueList[ self.currentWinner ], false );
        self.currentWinner++;
        self.rankText.loadTexture( 'Window', self.currentWinner + '.png' );
        if ( self.currentWinner === 2 ) self.winPonitsNum.setText( 'THE TOURNAMENT SECOND PLACE WINNER IS:' );
        if ( self.currentWinner === 3 ) self.winPonitsNum.setText( 'THE TOURNAMENT THIRD PLACE WINNER IS:' );
        if ( self.currentWinner === 4 ) self.winPonitsNum.setText( 'THE TOURNAMENT FOURTH PLACE WINNER IS:' );
        if ( self.currentWinner === 5 ) self.winPonitsNum.setText( 'THE TOURNAMENT FIFTH PLACE WINNER IS:' );
        self._showMultiWinner( this.currentWinner );
      } );
    else {
      // set everything back
      this.currentWinner = 0;
      server.winValueList = [];
      server.winPlayerNumberList = [];
      this._runThirdStage();
    }
  }
  _runThirdStage () {
    let thirdStageTime = Phaser.Timer.SECOND * 2;

    this.soundEagleOut.play();
    this.soundCoins.loopFull();

    this.thirdStageSoundTimer = this.game.time.create( true );
    this.thirdStageSoundTimer.add( thirdStageTime * 1.5, function () {
    }, this );
    this.thirdStageSoundTimer.start();
    this.soundEagleShow.fadeOut( thirdStageTime * 0.1 );
    let pinkRays = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotPinkRays' );
    pinkRays.anchor.set( 0.5 );
    pinkRays.alpha = 0.0;
    this.add( pinkRays );

    this.game.add.tween( pinkRays ).to( { alpha: 0.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true ).onComplete.removeAll();
    this.game.add.tween( pinkRays ).to( { angle: 360 }, 2000, Phaser.Easing.Linear.None, true, 0, -1 );

    // congrats sign
    let sign = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'PopupJackpotCongratulationsSign' );
    sign.anchor.set( 0.5 );
    sign.scale.set( 2.0 );
    sign.alpha = 0.0;
    this.add( sign );

    this.game.make.tween( sign )
      .to( { alpha: 0.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true )
      .onComplete.removeAll();

    this.game.make.tween( sign.scale )
      .to( { x: 1.0, y: 1.0 }, thirdStageTime * 2.5, Phaser.Easing.Linear.None, true )
      .onComplete.add( function () {
        this.soundBG.fadeOut( thirdStageTime * 3 );
        this.thirdStageTimer = this.game.time.create( true );
        this.thirdStageTimer.add( thirdStageTime * 3, this._close, this );
        this.thirdStageTimer.start();
      }, this );

    this.localGarbage.push( pinkRays );
    this.localGarbage.push( sign );
    thirdStageTime = null;
  }

  _generateBackCoins () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      let variant = Math.random() >= 0.5;

      let frames = Phaser.Animation.generateFrameNames( 'CoinsAnimation_', variant ? 0 : 8, variant ? 7 : 21, '.png', 4 );

      let coinBack = this.game.make.sprite( this.game.width * Math.random(), -this.game.height * ( 0.5 + Math.random() * 0.8 ), 'CoinsAnimation' );
      coinBack.animations.add( 'CoinsAnimation', frames, 4 );
      coinBack.animations.play( 'CoinsAnimation', 12, true );

      coinBack.angle = 360 + ( variant ? 1 : -1 ) * ( 60 + Math.random() * 20 );
      coinBack.alpha = 0.5 + Math.random() * 0.3;

      this.coinsBack.push( { coin: coinBack, tween: null } );

      this.add( coinBack );
      variant = null;
      frames = null;
      this.localGarbage.push( coinBack );
    }
  }
  _generateFrontCoins () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      let variant = Math.random() >= 0.5;

      let frames = Phaser.Animation.generateFrameNames( 'CoinsAnimation_', variant ? 0 : 8, variant ? 7 : 21, '.png', 4 );

      let coinFront = this.game.make.sprite( this.game.width * Math.random(), -this.game.height * ( 0.5 + Math.random() * 0.8 ), 'CoinsAnimation' );
      coinFront.animations.add( 'CoinsAnimation', frames, 4 );
      coinFront.animations.play( 'CoinsAnimation', 12, true );

      coinFront.angle = 360 + ( variant ? 1 : -1 ) * ( 60 + Math.random() * 20 );

      this.coinsFront.push( { coin: coinFront, tween: null } );

      this.add( coinFront );
      variant = null;
      frames = null;
      this.localGarbage.push( coinFront );
    }
  }
  _startCoinsAnimations () {
    for ( let i = 0; i < this.coinsAmount; i++ ) {
      this.coinsFront[ i ].tween = this.game.make.tween( this.coinsFront[ i ].coin );
      this.coinsFront[ i ].tween.to( { y: this.game.height + this.coinsFront[ i ].coin.height }, 200 + Math.random() * 400, Phaser.Easing.Linear.None, false );

      this.coinsFront[ i ].tween.onComplete.add( function () {
        this.coinsFront.forEach( function ( element, index ) {
          if ( element.coin.y >= this.game.height + element.coin.height ) {
            element.coin.y = -element.coin.height;
            element.coin.x = this.game.width * Math.random();
            element.tween.start();
          }
        }, this );
      }, this );

      this.coinsFront[ i ].tween.start();

      this.coinsBack[ i ].tween = this.game.make.tween( this.coinsBack[ i ].coin );
      this.coinsBack[ i ].tween.to( { y: this.game.height + this.coinsBack[ i ].coin.height }, 200 + Math.random() * 400, Phaser.Easing.Linear.None, false );

      this.coinsBack[ i ].tween.onComplete.add( function () {
        this.coinsBack.forEach( function ( element, index ) {
          if ( element.coin.y >= this.game.height + element.coin.height ) {
            element.coin.y = -element.coin.height;
            element.coin.x = this.game.width * Math.random();
            element.tween.start();
          }
        }, this );
      }, this );

      this.coinsBack[ i ].tween.start();
    }
  }
  /**
   * Jackpot Slot Machine Delegate
   */
  SlotMachineTookASLEEPState () {
    this._runThirdStage();
  }
  SlotMachineShowsWinLine ( lineData, withSound ) {}
  SlotMachineAnimationIsEnded () {}
  SlotMachineReelStopped ( isLastReel ) {
  }
  SlotMachineReelHyperSpinStarted () {}
  SlotMachineRunTwist () {}
}
