/*
 * Boot state
 * ==========
 *
 * The first state of the game, responsible for setting up some Phaser
 * features. Adjust the game appearance, number of input pointers, screen
 * orientation handling etc. using this game state.
 */

import assets from '../assets';

export default class Boot extends Phaser.State {
  preload () {
    // Point the Phaser Asset Loader to where your game assets live.
    this.load.path = 'assets/' + this.game.currentResolution.x.toString() + 'x' + this.game.currentResolution.y.toString() + '/';

    // Initialize physics engines here. Remember that Phaser builds including
    // Arcade Physics have it enabled by default.
    // this.physics.startSystem(Phaser.Physics.P2);

    // Adjust how many pointers Phaser will check for input events.
    this.input.maxPointers = 2;

    // Set the alignment of the game canvas within the page.
    this.scale.pageAlignHorizontally = true;
    this.scale.pageAlignVertically = true;

    // Adjust the scaling mode of the game canvas.
    // Example: If you're developing a pixel-art game, set it to 'USER_SCALE'.
    this.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL;
    let div = document.createElement( 'div' );
    div.id = 'turn';
    document.body.appendChild( div );
    this.game.scale.forceOrientation( true, false );
    this.game.scale.enterIncorrectOrientation.add( function () { if ( !this.game.device.desktop ) document.getElementById( 'turn' ).style.display = 'block'; }, this );
    this.game.scale.leaveIncorrectOrientation.add( function () { if ( !this.game.device.desktop ) document.getElementById( 'turn' ).style.display = 'none'; }, this );

    // When using 'USER_SCALE' scaling mode, use this method to adjust the
    // scaling factor.
    // this.scale.setUserScale(2);

    // Uncomment the following line to adjust the rendering of the canvas to
    // crisp graphics. Great for pixel-art!
    // Phaser.Canvas.setImageRenderingCrisp(this.game.canvas);

    // Uncomment this line to disable smoothing of textures.
    // this.stage.smoothed = false;

    // If the game canvas loses focus, keep the game loop running.
    this.stage.disableVisibilityChange = true;

    // Whether to use frame-based interpolations or not.
    this.tweens.frameBased = false;

    // setup phaser-input plugin
    this.game.add.plugin( PhaserInput.Plugin );

    // Load the graphical assets required to show the splash screen later,
    // using the asset pack data.
    this.load.pack( 'boot', null, assets );
  }

  create () {
    // After applying the first adjustments and loading the splash screen
    // assets, move to the next game state.
    // this.state.start( 'Preloader' );
    window.__showCanvas();
    this.state.start( 'Login', true, false );
  }
}
