/*
 * BasePopup
 * ==========
 *
 * Base Group Object for Popup
 */
import BaseTintAnimatedBackground from './BaseTintAnimatedBackground.js';
export default class BasePopup extends BaseTintAnimatedBackground {
  constructor ( game ) {
    super( game );
    this.isClosed = false;
  }

  destroy () {
    delete this.isClosed;
    if ( this.share ) this.share.destroy( true ); this.share = null;
    if ( this.shareText ) this.shareText.destroy( true ); this.shareText = null;
    this.removeAll( true );
    super.destroy();
  }

  _showShare ( positionX, positionY ) {
    this.share = this.game.make.image( positionX, positionY, 'Popups', 'checkboxChecked.png' );
    this.share.anchor.set( 0.5 );
    this.share.data = 1;
    this.share.inputEnabled = true;
    this.share.events.onInputDown.add( this._shareClick, this );
    this.add( this.share );

    let textStyle = { font: 'bold ' + this.game.width * 0.0125 + 'px Open Sans', fill: '#fff', align: 'center' };
    this.shareText = this.game.make.text( positionX + this.share.width, positionY + this.share.height * 0.1, 'Share with a friend', textStyle );
    this.shareText.anchor.set( 0, 0.5 );
    this.add( this.shareText );
  }

  _shareClick ( target, event ) {
    let currentOption = parseInt( target.data );
    switch ( currentOption ) {
      case 0:
        target.loadTexture( 'Popups', 'checkboxChecked.png' );
        target.data = 1;
        break;
      case 1:
        target.loadTexture( 'Popups', 'checkboxEmpty.png' );
        target.data = 0;
        break;
      default:
        break;
    }
  }

  _isShare () {
    if ( !this.share ) return true;
    return parseInt( this.share.data ) === 1;
  }

  _close () {
    this.isClosed = true;
  }
}

