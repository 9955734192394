import popups from '../objects/Popups.js';
import Stats from '../objects/Stats.js';
import { setTimeout } from 'timers';
class Server {
  constructor () {
    this.isProdVersion = false;

    window.version = '5.7.0.0';
    this.BEVersion = null; // '3.4.0.0'

    this.SERVER_URL = ''; // if you're working locally use: 'https://skyline-dev.bitglu.io:8443'
    this.SERVER_SOCKET_URL = '/api/rt/bitgluhub'; // if you're working locally use: 'https://skyline-dev.bitglu.io:8443/api/rt/bitgluhub'

    // console.info = function ( ) { };
    // console.log = function ( ) { };
    // console.debug = function ( ) { };
    if ( this.isProdVersion )
      window.environment = 'Production';
    else {
      window.environment = 'Development';
      if ( !window.stats ) {
        window.stats = new Stats();
        document.body.appendChild( window.stats.dom );
        requestAnimationFrame( function loop () {
          window.stats.update();
          requestAnimationFrame( loop );
        } );
      }
    }

    this.METHOD = {
      GET: 'GET',
      POST: 'POST'
    };

    this.PATH = {
      TOKEN: this.SERVER_URL + '/identity/connect/token?app=jp', // Add ?app=jp to tell BE request is sent from JP
      ACTION: this.SERVER_URL + '/api/Actions/execute'
    };

    this.ACTION = {
      JACKPOTSTATUS: 'BitGlu.Slots2.Actions.GetJPStatus',
      LEADERBOARD: 'BitGlu.Slots2.Actions.Tournaments.GetTopLeaderBoard',
      TOURNAMENTINFO: 'BitGlu.Slots2.Actions.Tournaments.GetTournamentInfo'
    };

    this.AUTH = {
      USER: null,
      PASSWORD: null
    };

    this.CONFIG = {
      TOKEN: null,
      TOKEN_REFRESH: null,
      TOKEN_EXPIRES_AT: 0,
      TOKEN_EXPIRATION_FRAME: 0, // secs
      JACKPOT_STATUS: {},
      JACKPOT_STATUS_UPDATE_TIME: 15, // secs
      TOURNAMENT_PRIZE: 0,
      TOURNAMENT_PRIZE_List: [],
      TOURNAMENT_ENDTIME: null,
      TOURNAMENT_ACITVE: false,
      TOURNAMENT_STARTTIME: null,
      TOURNAMENT_TIMELEFT: 0,
      TOURNAMENT_LASTWINNER: null,
      TOURNAMENT_URLLINK: null,
      TOURNAMENT_REMAININGSECONDS: 0,
      TOURNAMENT_REMAININGSECONDSNEXTSTART: 0,
      TOURNAMENT_OTHERRANKS: {},
      TOURNAMENT_STATUS_UPDATE_TIME: 10
    };

    this.loginPOSUser = false; // When user using POS username login, set the value to be true
    this.wrongAttemps = 50;
    this.isAccountBlocked = false;

    this.jackpotStatusTimer = null;
    this.jackpotStatusParams = {};
    this.leaderboardStatusParams = {};

    this.leaderboardTimer = null;
    this.tournamentInfoTimer = null;

    this.socketConnection = null;
    this.socketConnectionGameObject = null;
    this.socketConnectionSoundsObject = null;
    this.socketConnectionCallback = null;

    this.externalImageCallback = {};
    this.isShowingPopup = false;

    this.winValueList = [];
    this.winPlayerNumberList = [];

    this.refreshTokenTimer = [ 1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 60, 120 ];
    this.refreshTokenTimerIndex = 0;
    this.isFreshingToken = false;
    this.failLoadingTime = 0;

    this.isReToken = false;

    this.isAnticipation = false;
    this.isRoyal = false;

    this.projectType = 1;
    // FOR TEST POPUP WINNER ONLY
    // this.winValueList = [ 20, 30, 40 ];
    // this.winPlayerNumberList = [ 10, 20, 30 ];
  }
  /**
 * Public Methods
 */
  isProd () { return this.isProdVersion; }
  isLoggedIn () { return this.CONFIG.TOKEN !== null; }
  log ( message ) {
    if ( this.isProd() ) return;
    console.log( message );// eslint-disable-line no-console
  }
  error ( message ) {
    if ( this.isProd() ) return;
    console.error( message );// eslint-disable-line no-console
  }
  token ( game, callbackFunction ) {
    let self = this;

    if ( !callbackFunction || !game )
      self._handleError( 'Empty callback function or game object on login' );
    else if ( self.CONFIG.TOKEN ) callbackFunction.call();

    let callback = function ( success, response ) {
      if ( !success ) {
        callbackFunction.call();
        return;
      }
      let parsedResponse = JSON.parse( response );
      self._updateToken( parsedResponse );

      callbackFunction.call();
      response = null;
    };

    let data = 'client_id=Authenticated&username=' + this.AUTH.USER + '&password=' + this.AUTH.PASSWORD + '&grant_type=password';
    this._sendRequest( game, this.METHOD.POST, this.PATH.TOKEN, data, callback );
  }
  reToken ( game, callbackFunction ) {
    let self = this;
    this.game = game;

    self.isReToken = true;
    if ( !callbackFunction || !game )
      self._handleError( 'Empty callback function or game object on login' );
    // else if ( self.CONFIG.TOKEN ) callbackFunction.call();

    let callback = function ( success, response ) {
      if ( !success ) {
        self.refreshTokenTimerIndex++;
        if ( self.refreshTokenTimerIndex > self.refreshTokenTimer.length - 1 ) self.refreshTokenTimerIndex = self.refreshTokenTimer.length - 1;
        new Promise( ( resolve ) => setTimeout( resolve, self.refreshTokenTimer[ self.refreshTokenTimerIndex ] * 1000 ) ).then( () => {
          self.reToken( game, callbackFunction );
        } );
        return;
      }
      let parsedResponse = JSON.parse( response );
      self._updateToken( parsedResponse );

      self.isReToken = false;
      self.refreshTokenTimerIndex = 0;
      callbackFunction.call();
      response = null;
    };

    let data = 'client_id=Authenticated&username=' + this.AUTH.USER + '&password=' + this.AUTH.PASSWORD + '&grant_type=password';
    this._sendRequest( self.game, this.METHOD.POST, this.PATH.TOKEN, data, callback, true );
  }
  refreshToken ( game, callbackFunction ) {
    let self = this;
    if ( this.isFreshingToken ) return;
    this.isFreshingToken = true;
    if ( game ) this.game = game;
    this.callbackReToken = function () {
      if ( !self.checkToken( game, function () { } ) ) return;
      callbackFunction.call();
    };

    let callback = function ( success, response ) {
      self.isFreshingToken = false;
      if ( !success ) {
        let exMessage = 'no success on refresh token request';
        let exData = self.CONFIG;
        self.googleAnalyticsException( exMessage, exData, true );
        // self.logoutFull( game );
        // start refresh token
        if ( !self.isReToken ) self.reToken( self.game, self.callbackReToken );
        return;
      }
      self.refreshTokenTimerIndex = 0;
      let parsedResponse = JSON.parse( response );
      self._updateToken( parsedResponse );

      callbackFunction.call();
    };

    let data = 'client_id=Authenticated&refresh_token=' + this.CONFIG.TOKEN_REFRESH + '&grant_type=refresh_token';
    this._sendRequest( game, this.METHOD.POST, this.PATH.TOKEN, data, callback, true );
  }
  checkToken ( game, callback ) {
    if ( !this.CONFIG.TOKEN_EXPIRATION_FRAME || !this.CONFIG.TOKEN_EXPIRES_AT || !this.CONFIG.TOKEN_REFRESH ) {
      let exMessage = 'logout on check token. one of the parameters is missed';
      let exData = this.CONFIG;
      this.googleAnalyticsException( exMessage, exData, true );
      // this.logoutFull( game );
      // start refresh token
      this.refreshToken( game, callback );
      return false;
    }

    let now = new Date().getTime();
    let tokenDiff = this.CONFIG.TOKEN_EXPIRES_AT - now;

    if ( tokenDiff <= 0 || tokenDiff < this.CONFIG.TOKEN_EXPIRATION_FRAME ) {
      this.refreshToken( game, callback );
      return false;
    }
    this.refreshTokenTimerIndex = 0;
    return true;
  }
  checkTokenForLogin ( game, callback ) {
    if ( !this.CONFIG.TOKEN_EXPIRATION_FRAME || !this.CONFIG.TOKEN_EXPIRES_AT || !this.CONFIG.TOKEN_REFRESH ) {
      let exMessage = 'logout on check token. one of the parameters is missed';
      let exData = this.CONFIG;
      this.googleAnalyticsException( exMessage, exData, true );
      this.logoutFull( game );
      return false;
    }

    let now = new Date().getTime();
    let tokenDiff = this.CONFIG.TOKEN_EXPIRES_AT - now;

    if ( tokenDiff <= 0 || tokenDiff < this.CONFIG.TOKEN_EXPIRATION_FRAME ) {
      this.refreshToken( game, callback );
      return false;
    }
    this.refreshTokenTimerIndex = 0;
    return true;
  }
  login ( game, callbackFunction ) {
    let self = this;

    if ( !callbackFunction || !game )
      self._handleError( 'Empty callback function or game object on login' );

    let callback = function () {
      if ( !self.isLoggedIn() ) {
        if ( callbackFunction ) callbackFunction.call(); // don't change this. Shows incorrect password message
        return;
      }

      if ( !self.checkTokenForLogin( game, function () { self.login( game, callbackFunction ); } ) ) return;
      callbackFunction.call();
    };

    if ( !this.CONFIG.TOKEN ) this.token( game, callback );
    else callback.call();
  }
  logout ( game, callbackFunction ) {
    this.CONFIG.TOKEN = null;
    if ( callbackFunction ) callbackFunction.call();
    window.location.reload( true );
  }
  logoutFull ( game ) {
    let self = this;
    let gameObject = game || this.game;
    if ( gameObject ) popups.getPopupService( gameObject ).clean();
    this.logout( this.game, function () {
      self.closeSocketConnection();
      self.game.load.reset();
      self.game.load.removeAll();
      // self.game.state.start( 'Boot', true, true );
    } );
  }
  updateTimeZone () {
    let endTime = new Date( this.CONFIG.TOURNAMENT_ENDTIME ).getTime();
    let now = new Date().getTime();
    let timeDiff = endTime - now;
    if ( timeDiff < 0 ) timeDiff = 0;
    this.CONFIG.TOURNAMENT_TIMELEFT = timeDiff;
  }
  getJackpotStatus ( game, callbackFunction ) {
    if ( game ) this.game = game;
    let self = this;

    // TEST POPUP WINNER ONLY
    // this.game.input.onDown.add( function () {
    //   popups.getPopupService( self.game ).addPopup( popups.TYPE.Winner, {
    //     'winValue': 100,
    //     'winnerID': 0,
    //     'type': '1',
    //     'minorValue': self.CONFIG.JACKPOT_STATUS[ 'minorAmount' ],
    //     'majorValue': self.CONFIG.JACKPOT_STATUS[ 'majorAmount' ],
    //     'grandValue': self.CONFIG.JACKPOT_STATUS[ 'grandAmount' ],
    //     'personalValue': self.CONFIG.JACKPOT_STATUS[ 'personalJP' ],
    //     'callback': self.socketConnectionCallback },
    //   self.game );
    // } );

    if ( !this.CONFIG.TOKEN ) return;
    if ( !this.jackpotStatusParams[ 'game' ] ) {
      this.jackpotStatusParams[ 'game' ] = game;
      this.jackpotStatusParams[ 'callback' ] = function () { self.log( 'received update for jackpot status' ); };
    }
    let gameObject = game || this.jackpotStatusParams[ 'game' ];
    let callbackObject = callbackFunction || this.jackpotStatusParams[ 'callback' ];

    if ( !callbackObject || !gameObject ) {
      self._handleError( 'Empty callback function or game object on getJackpotStatus' );
      return;
    }

    let callback = function ( success, response ) {
      if ( !self.checkToken( game, function () { self.getJackpotStatus( game, callbackFunction ); } ) ) return;

      // self.jackpotStatusTimer = gameObject.time.create( true );
      // self.jackpotStatusTimer.add( Phaser.Timer.SECOND * self.CONFIG.JACKPOT_STATUS_UPDATE_TIME, self.getJackpotStatus, self );
      // self.jackpotStatusTimer.start();

      if ( !success || !response || !response.length ) {
        callbackObject.call();
        self.error( 'jackpot status is not updated' );
        return;
      }

      let parsedResponse = JSON.parse( response );
      self._updateJackpotStatusWithResponse( parsedResponse[ 'outParameters' ][ 'Response' ] );
      if ( parsedResponse[ 'outParameters' ][ 'Response' ][ 'isRoyalActive' ] ) self.isRoyal = true;
      self.googleAnalyticsPageview();
      callbackObject.call();
      parsedResponse = null;
      callbackObject = null;
    };

    // if ( this.jackpotStatusTimer ) {
    //   this.jackpotStatusTimer.stop( true );
    //   this.jackpotStatusTimer.destroy( true );
    //   this.jackpotStatusTimer = null;
    // }

    let data = {
      'ActionName': self.ACTION.JACKPOTSTATUS
    };
    this._sendRequest( gameObject, this.METHOD.POST, self.PATH.ACTION, data, callback, true );
  }
  getLeaderboard ( game, callbackFunction ) {
    if ( game ) this.game = game;
    let self = this;

    if ( !this.CONFIG.TOKEN ) return;
    if ( !this.leaderboardStatusParams[ 'game' ] )
      this.leaderboardStatusParams[ 'game' ] = game;
    let gameObject = game || this.leaderboardStatusParams[ 'game' ];

    if ( !gameObject ) {
      self._handleError( 'Empty callback function or game object on get Leaderboard' );
      return;
    }

    let callback = function ( success, response ) {
      if ( !self.checkToken( game, function () { self.getLeaderboard( game, callbackFunction ); } ) ) return;

      if ( !success || !response || !response.length ) {
        self.error( 'leaderboard status is not updated' );
        return;
      }

      let parsedResponse = JSON.parse( response );
      // console.error( parsedResponse );
      self.CONFIG.OTHERRANKS = parsedResponse[ 'outParameters' ][ 'TopRanks' ];
      self.googleAnalyticsPageview();
      parsedResponse = null;
    };

    let data = {
      'ActionName': self.ACTION.LEADERBOARD
    };
    this._sendRequest( gameObject, this.METHOD.POST, self.PATH.ACTION, data, callback, true );
  }
  getTournamentInfo ( game, callbackFunction ) {
    let self = this;

    if ( !this.CONFIG.TOKEN ) return;
    if ( !this.leaderboardStatusParams[ 'game' ] )
      this.leaderboardStatusParams[ 'game' ] = game;
    let gameObject = game || this.leaderboardStatusParams[ 'game' ];
    let callback = function ( success, response ) {
      if ( !self.checkToken( game, function () { self.getTournamentInfo( game, callbackFunction ); } ) ) return;

      // self.tournamentInfoTimer = gameObject.time.create( true );
      // self.tournamentInfoTimer.add( Phaser.Timer.SECOND * self.CONFIG.TOURNAMENT_STATUS_UPDATE_TIME, self.getTournamentInfo, self );
      // self.tournamentInfoTimer.start();

      if ( !success || !response || !response.length ) {
        if ( callbackFunction ) callbackFunction.call();
        self.failLoadingTime++;
        self.error( 'Tournament status is not updated' );
        return;
      } else
        self.failLoadingTime = 0;

      let parsedResponse = JSON.parse( response );
      self.CONFIG.TOURNAMENT_PRIZE_List[ 0 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize' ];
      self.CONFIG.TOURNAMENT_PRIZE_List[ 1 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_2' ];
      self.CONFIG.TOURNAMENT_PRIZE_List[ 2 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_3' ];
      self.CONFIG.TOURNAMENT_PRIZE_List[ 3 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_4' ];
      self.CONFIG.TOURNAMENT_PRIZE_List[ 4 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_5' ];
      self.CONFIG.TOURNAMENT_PRIZE = self.CONFIG.TOURNAMENT_PRIZE_List[ 0 ] + self.CONFIG.TOURNAMENT_PRIZE_List[ 1 ] + self.CONFIG.TOURNAMENT_PRIZE_List[ 2 ] + self.CONFIG.TOURNAMENT_PRIZE_List[ 3 ] + self.CONFIG.TOURNAMENT_PRIZE_List[ 4 ];
      self.CONFIG.TOURNAMENT_ENDTIME = parsedResponse[ 'outParameters' ][ 'Response' ][ 'endDate' ];
      self.CONFIG.TOURNAMENT_ACTIVE = parsedResponse[ 'outParameters' ][ 'Response' ][ 'active' ];
      self.CONFIG.TOURNAMENT_STARTTIME = parsedResponse[ 'outParameters' ][ 'Response' ][ 'startTime' ];
      self.CONFIG.TOURNAMENT_URLLINK = parsedResponse[ 'outParameters' ][ 'Response' ][ 'jPadlink' ];
      self.CONFIG.TOURNAMENT_LASTWINNER = parsedResponse[ 'outParameters' ][ 'Response' ][ 'lastWinners' ];

      if ( self.CONFIG.TOURNAMENT_LASTWINNER && self.CONFIG.TOURNAMENT_LASTWINNER.length )
        self.CONFIG.TOURNAMENT_LASTWINNER.sort( ( a, b ) => b.rank - a.rank );

      self.CONFIG.TOURNAMENT_WINNER = parsedResponse[ 'outParameters' ][ 'Response' ][ 'winner' ];
      self.CONFIG.TOURNAMENT_REMAININGSECONDS = parsedResponse[ 'outParameters' ][ 'Response' ][ 'remainingSecondsNextEnd' ];
      self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART = parsedResponse[ 'outParameters' ][ 'Response' ][ 'remainingSecondsNextStart' ];

      if ( self.CONFIG.TOURNAMENT_ACTIVE ) self.getLeaderboard( game, callbackFunction );
      // self.getJackpotStatus( game, callbackFunction );

      self.CONFIG.TOURNAMENT_PRIZE = self.CONFIG.TOURNAMENT_PRIZE / 100;
      self.CONFIG.TOURNAMENT_PRIZE_List[ 0 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize' ] / 100;
      self.CONFIG.TOURNAMENT_PRIZE_List[ 1 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_2' ] / 100;
      self.CONFIG.TOURNAMENT_PRIZE_List[ 2 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_3' ] / 100;
      self.CONFIG.TOURNAMENT_PRIZE_List[ 3 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_4' ] / 100;
      self.CONFIG.TOURNAMENT_PRIZE_List[ 4 ] = parsedResponse[ 'outParameters' ][ 'Response' ][ 'prize_5' ] / 100;
      if ( self.CONFIG.TOURNAMENT_PRIZE < 0 ) self.CONFIG.TOURNAMENT_PRIZE = -self.CONFIG.TOURNAMENT_PRIZE;

      if ( !self.CONFIG.TOURNAMENT_REMAININGSECONDS ) self.CONFIG.TOURNAMENT_REMAININGSECONDS = 0;
      if ( !self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART ) self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART = 0;

      // console.error( parsedResponse );
      let endTime = 0;
      if ( self.CONFIG.TOURNAMENT_REMAININGSECONDS < self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART )
        endTime = new Date().getTime() + self.CONFIG.TOURNAMENT_REMAININGSECONDS * 1000;
      else if ( self.CONFIG.TOURNAMENT_REMAININGSECONDS > self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART )
        endTime = new Date().getTime() + self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART * 1000;

      if ( self.CONFIG.TOURNAMENT_REMAININGSECONDS > 0 && self.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART === 0 )
        endTime = new Date().getTime() + self.CONFIG.TOURNAMENT_REMAININGSECONDS * 1000;
      self.CONFIG.TOURNAMENT_ENDTIME = endTime;
      self.updateTimeZone();
      if ( callbackFunction ) callbackFunction.call();
      parsedResponse = null;
      callbackFunction = null;
    };

    // if ( this.tournamentInfoTimer ) {
    //   this.tournamentInfoTimer.stop( true );
    //   this.tournamentInfoTimer.destroy( true );
    //   this.tournamentInfoTimer = null;
    // }
    let data = {
      'ActionName': self.ACTION.TOURNAMENTINFO
    };
    this._sendRequest( gameObject, this.METHOD.POST, self.PATH.ACTION, data, callback, true );
  }

  isShowingFurtureEvent () {
    if ( this.CONFIG.TOURNAMENT_REMAININGSECONDS > 0 && this.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART === 0 ) return false;

    if ( this.CONFIG.TOURNAMENT_REMAININGSECONDS > this.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART ) return true;
    else if ( this.CONFIG.TOURNAMENT_REMAININGSECONDS < this.CONFIG.TOURNAMENT_REMAININGSECONDSNEXTSTART ) return false;
  }

  preloadExternalImage ( game, key, imgUrl, callback, callbackTarget ) {
    if ( !imgUrl ) return;
    game.load.image( key, imgUrl );
    game.load.onFileComplete.add( function ( progress, key, success, totalLoaded, totalFiles ) {
      if ( !this.externalImageCallback[ key ] ) return;
      this.externalImageCallback[ key ][ 'callback' ].call( this.externalImageCallback[ key ][ 'callbackTarget' ], key );
      this.externalImageCallback[ key ][ 'callback' ] = null;
      this.externalImageCallback[ key ][ 'callbackTarget' ] = null;
      this.externalImageCallback[ key ] = null;
      delete this.externalImageCallback[ key ];
    }, this );
    this.externalImageCallback[ key ] = { 'callback': callback, 'callbackTarget': callbackTarget };
    game.load.start();
  }

  setupSocketConnection ( game, sounds, callbackFunction ) {
    if ( this.socketConnection ) return;

    this.socketConnectionGameObject = game || this.game;
    this.socketConnectionSoundsObject = sounds;
    this.socketConnectionCallback = callbackFunction;

    let self = this;
    let link = this.SERVER_SOCKET_URL + '?token=' + self.CONFIG.TOKEN;

    let signalR = require( '@aspnet/signalr' );
    this.socketConnection = new signalR.HubConnectionBuilder()
      .withUrl( link )
      .build();

    this.socketConnection.on( 'GMessage', data => {
      // {MessageType: "JPWIN", JPType: 0, WinAmount: 120}
      // JPType 0 - minor 1 - major 2 - grand 3 - personal
      self.log( 'socket connection message:' );
      self.log( data );
      data = JSON.parse( data );
      if ( data.hasOwnProperty( 'JPType' ) ) {
        self.googleAnalyticsEvent( 'JP Screen', 'JP' + data[ 'JPType' ].toString(), data[ 'WinAmount' ] );
        popups.getPopupService( self.socketConnectionGameObject ).addPopup( popups.TYPE.Jackpot, {
          'winValue': data[ 'WinAmount' ],
          'type': data[ 'JPType' ],
          'JPPayoutType': data[ 'JPPayoutType' ],
          'minorValue': self.CONFIG.JACKPOT_STATUS[ 'minorAmount' ],
          'majorValue': self.CONFIG.JACKPOT_STATUS[ 'majorAmount' ],
          'grandValue': self.CONFIG.JACKPOT_STATUS[ 'grandAmount' ],
          'royalValue': self.CONFIG.JACKPOT_STATUS[ 'waJP' ] || 0,
          'personalValue': self.CONFIG.JACKPOT_STATUS[ 'personalJP' ],
          'callback': self.socketConnectionCallback },
        self.socketConnectionSoundsObject );
      } else {
        self.winValueList.push( data[ 'WinAmount' ] / 100 );
        self.winPlayerNumberList.push( data[ 'PlayerNumber' ] || 0 );
        if ( data[ 'Rank' ] === 1 )
          popups.getPopupService( self.socketConnectionGameObject ).addPopup( popups.TYPE.Winner, {
            'winValue': data[ 'WinAmount' ] / 100,
            'winnerID': data[ 'PlayerNumber' ] || 0,
            'type': '1',
            'minorValue': self.CONFIG.JACKPOT_STATUS[ 'minorAmount' ],
            'majorValue': self.CONFIG.JACKPOT_STATUS[ 'majorAmount' ],
            'grandValue': self.CONFIG.JACKPOT_STATUS[ 'grandAmount' ],
            'royalValue': self.CONFIG.JACKPOT_STATUS[ 'waJP' ] || 0,
            'personalValue': self.CONFIG.JACKPOT_STATUS[ 'personalJP' ],
            'callback': self.socketConnectionCallback },
          self.socketConnectionSoundsObject );
      }
    } );

    let retry = function () {
      self.log( 'socket retry' );
      self.socketConnection = null;
      self.setupSocketConnection(
        this.socketConnectionGameObject,
        this.socketConnectionSoundsObject,
        this.socketConnectionCallback
      );
    };

    this.socketConnection.onclose( function ( message ) {
      if ( !message ) return;
      setTimeout( retry, 10000 );
    } );
    this.socketConnection.start()
      .then( () => {
        self.log( 'socket connection established' );
      } )
      .catch( () => {
        setTimeout( retry, 10000 );
      } );
  }
  closeSocketConnection () {
    if ( !this.socketConnection ) return;
    let self = this;

    this.socketConnection.stop()
      .then( () => {
        self.socketConnectionGameObject = null;
        self.socketConnectionSoundsObject = null;
        self.socketConnectionCallback = null;
        self.socketConnection = null;
        self.log( 'socket connection closed' );
      } );
  }
  /**
   * Google Analytics
   */
  googleAnalyticsCustomMetrics ( winPoints = 0 ) {
    let metrics = {
      'dimension1': new Date().toGMTString(), // string date
      'dimension2': this.CONFIG.JACKPOT_STATUS[ 'storeLabel' ] || 'empty',
      'dimension3': this.AUTH.USER || 'empty',
      'metric4': this.CONFIG.JACKPOT_STATUS[ 'minorAmount' ] || 0,
      'metric5': this.CONFIG.JACKPOT_STATUS[ 'majorAmount' ] || 0,
      'metric6': this.CONFIG.JACKPOT_STATUS[ 'grandAmount' ] || 0,
      'metric7': this.CONFIG.JACKPOT_STATUS[ 'waJP' ] || 0
    };
    return metrics;
  }
  googleAnalyticsPageview () {
    let metrics = this.googleAnalyticsCustomMetrics();
    ga( 'send', 'pageview', location.pathname + '&JP Screen&' + metrics[ 'dimension2' ] + '&' + metrics[ 'dimension3' ], metrics );
  }
  googleAnalyticsEvent ( page = 'default', action = 'default activity', winPoints = 0 ) {
    this.googleAnalyticsPageview();
    ga( 'send', 'event', page.toString(), action.toString(), this.googleAnalyticsCustomMetrics( winPoints ) );
  }
  googleAnalyticsException ( message, data, fatal = false ) {
    let metrics = {
      'date': new Date().toGMTString(), // string date
      'store': this.CONFIG.JACKPOT_STATUS[ 'storeLabel' ] || 'empty',
      'user': this.AUTH.USER || 'empty',
      'description': 'JP Screen :::' + message.toString(),
      'data': JSON.stringify( data ),
      'status': fatal ? 'critical' : 'normal'
    };
    ga( 'send', 'exception', {
      'exDescription': JSON.stringify( metrics ),
      'exFatal': fatal
    } );
  }
  /**
  * Private methods
  */
  _sendRequest ( game, method, path, data, callbackFunction, disableErrors = false ) {
    this.log( 'data to send: ' + JSON.stringify( data ) );
    this.log( 'request url: ' + path );

    let isTokenRequest = ( path === this.PATH.TOKEN );

    let XMLReq = new XMLHttpRequest();
    XMLReq.open( method, path, true );
    XMLReq.timeout = 10 * Phaser.Timer.SECOND; // Timeout is setup for 10 seconds

    if ( isTokenRequest ) {
      XMLReq.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
      XMLReq.send( data );
    } else {
      if ( this.BEVersion ) data[ 'Version' ] = this.BEVersion; // adds BE version if needed
      XMLReq.setRequestHeader( 'Content-Type', 'application/json' );
      XMLReq.setRequestHeader( 'Authorization', 'Bearer ' + this.CONFIG.TOKEN );
      XMLReq.send( JSON.stringify( data ) );
    }

    // if ( !disableErrors ) {
    //   console.error( game );
    //   XMLReq.ontimeout = this._generateOnTimeoutCallback( game );
    //   XMLReq.onerror = this._generateOnTimeoutCallback( game );
    // }

    let self = this;

    XMLReq.onreadystatechange = function () {
      if ( this.readyState !== 4 ) return;
      self.log( this );
      if ( this.status !== 200 || !this.responseText.length ) {
        if ( this.responseText && this.responseText.length ) {
          let responseError = null;
          try {
            responseError = JSON.parse( this.responseText );
          } catch ( e ) {
            callbackFunction( false, this.responseText );
            return;
          }
          if ( responseError.error_description === 'App not allowed' ) self.loginPOSUser = true;
          else self.loginPOSUser = false;
        }
        self._generateErrorCallback( game ).call();
        let exMessage = 'request error response';
        let exData = {
          'status': this.status || 'empty',
          'response': this.responseText || 'empty'
        };
        self.googleAnalyticsException( exMessage, exData );
        callbackFunction( false, this.responseText );
        return;
      }

      if ( this.responseText && this.responseText.length ) self.log( JSON.parse( this.responseText ) ); else self.log( 'empty response text' );
      callbackFunction( true, this.responseText );
    };
  }
  _updateToken ( response ) {
    this.CONFIG.TOKEN = response[ 'access_token' ];
    this.CONFIG.TOKEN_REFRESH = response[ 'refresh_token' ];

    let now = new Date().getTime();
    let expiresIn = parseInt( response[ 'expires_in' ] );
    let expiresFrame = expiresIn * Phaser.Timer.SECOND;
    this.CONFIG.TOKEN_EXPIRES_AT = parseInt( now ) + expiresFrame;
    this.CONFIG.TOKEN_EXPIRATION_FRAME = Math.floor( expiresFrame / 10 );
  }
  _generateOnTimeoutCallback ( game ) {
    let self = this;
    let callback = function () {
      popups.getPopupService( self.game ).addPopup( popups.TYPE.NoInternetConnection );
    };
    return callback;
  }
  _generateErrorCallback ( game ) {
    if ( game ) this.game = game;
    let self = this;
    let callback = function () {
      // popups.getPopupService( self.game ).addPopup( popups.TYPE.Error );
      let exMessage = 'request timeout';
      let exData = self.CONFIG;
      self.googleAnalyticsException( exMessage, exData );
    };
    return callback;
  }
  _handleError ( errorMessage ) {
    alert( 'Error: ' + errorMessage );
  }
  _updateJackpotStatusWithResponse ( parsedResponse ) {
    this.CONFIG.JACKPOT_STATUS = parsedResponse;
    this.isAnticipation = parsedResponse[ 'anticipation' ];
  }
}
export default new Server();
