import server from '../objects/Server';
// import popups from '../objects/Popups.js';
import PluginDecimalFloatText from '../plugins/PluginDecimalFloatText.js';
import PluginDecimalText from '../plugins/PluginDecimalText.js';
import BaseGarbageCollectionState from '../base/BaseGarbageCollectionState.js';
import get from '../objects/Get.js';
import popups from '../objects/Popups';
export default class Window extends BaseGarbageCollectionState {
  preload () {}
  init () {
    if ( get.projectType ) server.projectType = get.projectType;
    server.getJackpotStatus( this.game, function () {} );
    server.getTournamentInfo( this.game, function () {} );
    server.setupSocketConnection( this.game );

    this.bg = null;

    this.daysNum = '00';
    this.hrsNum = '00';
    this.minsNum = '00';

    this.fieldsMaskBitmap = null;
    this.fieldsMaskArea = new Phaser.Rectangle();
    this.fieldsMaskImageToCopy = null;
    this.fieldsMaskImage = null;

    this.royalTextPlugin = null;
    this.grandTextPlugin = null;
    this.majorTextPlugin = null;
    this.minorTextPlugin = null;
    this.jackpotsTimer = null;

    this.animatedStars = [];
    this.animatedStarsAreas = [];
    this.starAnimationFramesAmount = 0;

    this.otherRanksObjects = [];
    this.rankInfoObjects = [];
    this.rankInfo1Objects = [];

    this.jackpotFireSounds = [];
    this.jackpotFireSoundsIndex = 0;
    if ( this.jackpotFireSounds != null ) while ( this.jackpotFireSounds.length ) this.jackpotFireSounds.pop().destroy( true );
    for ( let i = 0; i < 5; i++ )
      this.jackpotFireSounds.push( this.game.add.audio( 'jackpotFireSound', this.defaultVolume ) );

    this.jackpotFireSounds.push( this.game.add.audio( 'jackpotFireSound', this.defaultVolume ) );
    this.anticipationFire = this.game.add.audio( 'anticipationFire', this.defaultVolume );
    this.gameIconsList = [
      'icons_castle.png',
      'icons_cats_dogs.png',
      'icons_fairy.png',
      'icons_jumping_beans.png',
      'icons_veggie.png',
      'icons_candy_keno.png',
      'icons_jacksorbetter.png'
    ];

    this.ads = {
      NOTIMEANDNOLASTWINNER: 1,
      TIMEADNNOLASTWINNER: 2,
      TIMEANDLASTWINNER: 3,
      NOTIMEANDLASTWINNER: 4,
      LIVE: 5,
      URLIMAGE: 6,
      ENDINGPAGE: 7
    };
    // this.count = 0;
  }

  shutdown () {
    this._destroyPreviousAds();
    this.game.load.reset();
    this.game.load.removeAll();
    if ( this.bg ) this.bg.destroy( true ); this.bg = null;
    if ( this.royalTextPlugin ) this.royalTextPlugin.destroy( true ); this.royalTextPlugin = null;
    if ( this.grandTextPlugin ) this.grandTextPlugin.destroy( true ); this.grandTextPlugin = null;
    if ( this.majorTextPlugin ) this.majorTextPlugin.destroy( true ); this.majorTextPlugin = null;
    if ( this.minorTextPlugin ) this.minorTextPlugin.destroy( true ); this.minorTextPlugin = null;
    if ( this.jackpotsTimer ) { this.jackpotsTimer.stop( true ); this.jackpotsTimer.destroy( true ); } this.jackpotsTimer = null;
    if ( this.gameTimer ) { this.gameTimer.stop( true ); this.gameTimer.destroy( true ); } this.gameTimer = null;
    if ( this.fieldsMaskBitmap ) this.fieldsMaskBitmap.destroy( true ); this.fieldsMaskBitmap = null;
    if ( this.fieldsMaskArea ) delete this.fieldsMaskArea; this.fieldsMaskArea = null;
    if ( this.fieldsMaskImageToCopy ) this.fieldsMaskImageToCopy.destroy( true ); this.fieldsMaskImageToCopy = null;
    if ( this.fieldsMaskImage ) this.fieldsMaskImage.destroy( true ); this.fieldsMaskImage = null;
    if ( this.animatedStars ) while ( this.animatedStars.length ) this.animatedStars.pop().destroy( true ); this.animatedStar = null;
    this.rankInfoObjects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].destroy( true ); element[ 'rankNum' ] = null;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].destroy( true ); element[ 'userIDNum' ] = null;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].destroy( true ); element[ 'winPonitsNum' ] = null;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].destroy( true ); element[ 'prizeShareAmount' ] = null;
    }, this );
    this.rankInfoObjects = [];
    this.rankInfo1Objects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].destroy( true ); element[ 'rankNum' ] = null;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].destroy( true ); element[ 'userIDNum' ] = null;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].destroy( true ); element[ 'winPonitsNum' ] = null;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].destroy( true ); element[ 'prizeShareAmount' ] = null;
    }, this );
    this.rankInfo1Objects = [];
    super.shutdown();
  }

  create () {
    this.bg = this.game.make.image( 0, 0, 'WinnerBG' );
    this.bg.scale.set( this.game.width / this.bg.width );
    this.add.existing( this.bg );
    this.localGarbage.push( this.bg );

    if ( this.game.cache.checkImageKey( 'SparklesAnimation' ) )
      this._setupSparklesAnimation();

    // 1.TIME AND NO LAST WINNER
    // this._showAds( this.ads.TIMEADNNOLASTWINNER );
    // this.currentAds = 2;

    // 2.NO TIME AND NO LAST WINNER
    this._showAds( this.ads.NOTIMEANDNOLASTWINNER );
    this.currentAds = 1;

    // 3.TIME AND LAST WINNER
    // new Promise( ( resolve ) => setTimeout( resolve, 2000 ) ).then( () => {
    //   this._showAds( this.ads.TIMEANDLASTWINNER );
    //   this.currentAds = 3;
    // } );

    // 4.NO TIME AND LAST WINNER
    // new Promise( ( resolve ) => setTimeout( resolve, 2000 ) ).then( () => {
    //   this._showAds( this.ads.NOTIMEANDLASTWINNER );
    //   this.currentAds = 4;
    // } );

    // 5.LIVE
    // this._showAds( this.ads.LIVE );
    // this.currentAds = 5;

    // 6.URL IMAGE
    // this._showAds( this.ads.URLIMAGE );
    // this.currentAds = 6;

    // 7.ENDING PAGE
    // this._showAds( this.ads.ENDINGPAGE );
    // this.currentAds = 7;

    // new Promise( ( resolve ) => setTimeout( resolve, 6000 ) ).then( () => {
    //   console.error( 123 );
    //   server.CONFIG.TOKEN_EXPIRATION_FRAME = null;
    // } );

    // let self = this;
    this.gameTimer = this.game.time.create( false );
    this.gameTimer.loop( 1000, this.updateJackpotInfo, this );

    // update every 3 seconds
    // this.gameTimer.loop( 3000, function () {
    //   self.currentAds++;
    //   if ( self.currentAds > 7 ) self.currentAds = 1;
    // }, this );
    this.gameTimer.start();

    this.statusTimer = this.game.time.create( false );
    this.statusTimer.loop( 15000, this.updateInfo, this );
    this.statusTimer.start();

    this._setupJackpotStatuses();
  }
  playJackpotFireSound () {
    if ( this.jackpotFireSoundsIndex < this.jackpotFireSounds.length ) {
      this.jackpotFireSounds[ this.jackpotFireSoundsIndex ].play();
      this.jackpotFireSoundsIndex++;
    } else {
      this.jackpotFireSounds[ 0 ].play();
      this.jackpotFireSoundsIndex = 0;
    }
  }
  stopJackpotFireSoundAll () {
    for ( let i = 0; i < this.jackpotFireSounds.length; i++ ) this.jackpotFireSounds[ i ].stop();
  }
  startJackpotFireSound () {
    this.anticipationFire.play();
    // this.playJackpotFireSound();
    // this.timer = this.game.time.create();
    // this.timer.add( Phaser.Timer.SECOND * 1, this.startJackpotFireSound, this );
    // this.timer.start();
  }
  stopJackpotFireSound () {
    // this.stopJackpotFireSoundAll();
    // if ( this.timer ) {
    //   this.timer.stop( true );
    //   this.timer.destroy();
    //   this.timer = null;
    // }
  }
  updateInfo () {
    server.getJackpotStatus( this.game, function () {} );
    server.getTournamentInfo( this.game, function () {} );
  }
  updateJackpotInfo () {
    this.descreasTimer();
    this._updateTourInfo();
    this.changeAdsType();
  }
  update () {
    super.update();
    this._updateStarAnimation();
    this._setupTimer();
  }
  changeAdsType () {
    if ( server.isShowingPopup ) return;
    // this.previousAds = null;
    // delete this.previousAds;

    if ( this.previousAds ) this.previousAds = null; delete this.previousAds;
    this.previousAds = this.currentAds;
    if ( server.CONFIG.TOURNAMENT_PRIZE && server.CONFIG.TOURNAMENT_PRIZE > 0 && server.CONFIG.TOURNAMENT_REMAININGSECONDS > 0 ) {
      let isShowFurtureEvent = server.isShowingFurtureEvent();
      if ( isShowFurtureEvent )
        // TIME
        if ( server.CONFIG.TOURNAMENT_LASTWINNER && server.CONFIG.TOURNAMENT_LASTWINNER.length ) {
          if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
          this.currentAds = 3;
        } else {
          if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
          this.currentAds = 2;
        }
      else {
        if ( server.CONFIG.TOURNAMENT_TIMELEFT > 60 * 1000 ) {
          if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
          this.currentAds = 5;
        } else {
          if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
          this.currentAds = 7;
        }
      }
      isShowFurtureEvent = null;
    } else {
      if ( server.CONFIG.TOURNAMENT_LASTWINNER ) {
        if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
        // NO TIME AND LAST WINNER
        this.currentAds = 4;
      } else {
        if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
        // NO TIME AND NO LAST WINNER
        this.currentAds = 1;
      }

      if ( server.CONFIG.TOURNAMENT_URLLINK ) {
        if ( this.currentAds ) this.currentAds = null; delete this.currentAds;
        this.currentAds = 6;
      }
    }
    if ( this.previousAds !== this.currentAds ) this._showAds( this.currentAds );
    // this.currentAds++;
    // if ( this.currentAds > 7 ) this.currentAds = 1;
    // if ( this.previousAds !== this.currentAds ) this._showAds( this.currentAds );
    // if ( this.previousAds ) this.previousAds = null; delete this.previousAds;
    // this.previousAds = this.currentAds;

    // TEST JP POPUP
    // this.count = 0;
    // if ( this.count < 1 ) {
    //   let promise = new Promise( ( resolve ) => setTimeout( resolve, this.count ? 30000 : 1000 ) );
    //   promise.then( () => {
    //     popups.getPopupService( server.socketConnectionGameObject ).addPopup( popups.TYPE.Jackpot, {
    //       'winValue': '1000',
    //       'winnerID': '300099',
    //       'type': 0,
    //       'JPPayoutType': 1,
    //       'minorValue': server.CONFIG.JACKPOT_STATUS[ 'minorAmount' ],
    //       'majorValue': server.CONFIG.JACKPOT_STATUS[ 'majorAmount' ],
    //       'grandValue': server.CONFIG.JACKPOT_STATUS[ 'grandAmount' ],
    //       'royalValue': server.CONFIG.JACKPOT_STATUS[ 'waJP' ] || 0,
    //       'personalValue': server.CONFIG.JACKPOT_STATUS[ 'personalJP' ],
    //       'callback': server.socketConnectionCallback },
    //     server.socketConnectionSoundsObject );
    //   } );
    //   this.count++;
    // }
  }
  _updateStarAnimation () {
    let starsAmount = 1.5;

    if ( !this.animatedStars.length ) {
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.0, this.game.height * 0.0, this.game.width * 0.525, this.game.height * 0.16 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.03, this.game.height * 0.16, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.46, this.game.height * 0.16, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.0, this.game.height * 0.32, this.game.width * 0.475, this.game.height * 0.16 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.03, this.game.height * 0.48, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.41, this.game.height * 0.48, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.0, this.game.height * 0.64, this.game.width * 0.46, this.game.height * 0.16 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.03, this.game.height * 0.8, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.36, this.game.height * 0.8, this.game.width * 0.01, this.game.height * 0.18 ) );
      this.animatedStarsAreas.push( new Phaser.Rectangle( this.game.width * 0.03, this.game.height * 0.96, this.game.width * 0.34, this.game.height * 0.01 ) );

      for ( let i = 0; i < starsAmount; i++ ) {
        let star = this.game.make.sprite( 0, 0, 'StarAnimation' );
        star.anchor.set( 0.5 );
        this.starAnimationFramesAmount = this.game.cache.getFrameCount( 'StarAnimation' );
        let frames = Phaser.Animation.generateFrameNames( 'StarAnimation_', 0, this.starAnimationFramesAmount, '.png', 4 );
        star.animations.add( 'StarAnimation', frames, 30 );
        this.add.existing( star );
        this.animatedStars.push( star );
        this.localGarbage.push( star );
      }
      starsAmount = null;
      return;
    }

    for ( let i = 0; i < this.animatedStars.length; i++ ) {
      if ( this.animatedStars[ i ].animations.currentAnim.isPlaying ) continue;

      let randomArea = this.animatedStarsAreas[ Math.floor( Math.random() * this.animatedStarsAreas.length ) ];
      let randomX = randomArea.x + Math.random() * randomArea.width;
      let randomY = randomArea.y + Math.random() * randomArea.height;

      this.animatedStars[ i ].x = randomX;
      this.animatedStars[ i ].y = randomY;

      this.animatedStars[ i ].animations.play( 'StarAnimation', this.starAnimationFramesAmount * ( 0.5 + Math.random() * 0.5 ) );
      randomArea = null;
      randomX = null;
      randomY = null;
    }
  }
  _setupJackpotStatuses () {
    // let allBG = this.game.make.image( this.game.width * 0.005, this.game.height * 0.489, 'Window', 'Jackpot_Grand_Frames.png' );
    // allBG.anchor.set( 0.0, 0.5 );
    // this.add.existing( allBG );
    // this.localGarbage.push( allBG );
    // // grand Jackpot
    // let grandBG = this.game.make.image( this.game.width * 0.03, this.game.height * 0.24, 'Window', 'Jackpot_Grand_Frame.png' );
    // grandBG.anchor.set( 0.0, 0.5 );
    // this.add.existing( grandBG );
    // this.localGarbage.push( grandBG );

    // this.grandTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.03 + grandBG.width * 0.5, this.game.height * 0.265, 0, null, 'JackpotPopupFont', this.game.width * 0.038, '$' );
    // this.grandTextPlugin.getText().anchor.set( 0.5 );
    // this.add.existing( this.grandTextPlugin.getText() );

    // // minor Jackpot
    // let minorBG = this.game.make.image( this.game.width * 0.03, this.game.height * 0.56, 'Window', 'Jackpot_Minor_Frame.png' );
    // minorBG.anchor.set( 0.0, 0.5 );
    // this.add.existing( minorBG );
    // this.localGarbage.push( minorBG );

    // this.minorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.03 + minorBG.width * 0.5, this.game.height * 0.585, 0, null, 'JackpotPopupFont', this.game.width * 0.035, '$' );
    // this.minorTextPlugin.getText().anchor.set( 0.5 );
    // this.add.existing( this.minorTextPlugin.getText() );

    // // Major (former) / Single now
    // let singleBG = this.game.make.image( this.game.width * 0.03, this.game.height * 0.88, 'Window', 'Jackpot_Single_Frame.png' );
    // singleBG.anchor.set( 0.0, 0.5 );
    // this.add.existing( singleBG );
    // this.localGarbage.push( singleBG );

    // this.majorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.03 + singleBG.width * 0.5, this.game.height * 0.905, 0, null, 'JackpotPopupFont', this.game.width * 0.035, '$' );
    // this.majorTextPlugin.getText().anchor.set( 0.5 );
    // this.add.existing( this.majorTextPlugin.getText() );

    // grandBG.alpha = 0;
    // minorBG.alpha = 0;
    // singleBG.alpha = 0;

    // this.LeaderboardFireAnimation = this.game.make.sprite( this.game.width * 0.006, this.game.height * 0.08, 'LeaderboardFire' );
    // let framesAmount = this.game.cache.getFrameCount( 'LeaderboardFire' );
    // let frames = Phaser.Animation.generateFrameNames( 'LeaderboardFire' + '_', 0, framesAmount, '.png', 4 );
    // this.LeaderboardFireAnimation.animations.add( 'LeaderboardFire', frames );
    // this.LeaderboardFireAnimation.animations.play( 'LeaderboardFire', framesAmount, true );
    // this.LeaderboardFireAnimation.animations.currentAnim.onComplete.add( function () {
    //   this.LeaderboardFireAnimation.destroy( true );
    //   this.LeaderboardFireAnimation = null;
    // }, this );
    // this.LeaderboardFireAnimation.scale.set( 2 );
    // this.LeaderboardFireAnimation.alpha = 0;
    // this.add.existing( this.LeaderboardFireAnimation );

    if ( server.isRoyal ) {
      // Royal Jackpot frame
      let royalBG = this.game.make.image( this.game.width * 0.0185, this.game.height * 0.15, 'JackpotFrames', 'Jackpot_Royal_Frame.png' );
      royalBG.anchor.set( 0.0, 0.5 );
      royalBG.scale.set( 0.98, 1 );
      this.add.existing( royalBG );
      this.localGarbage.push( royalBG );

      // Other three Jackpot frames
      let goldBG = this.game.make.image( this.game.width * 0.025, this.game.height * 0.65, 'JackpotFrames', 'Jackpot_Frame.png' );
      goldBG.anchor.set( 0.0, 0.5 );
      goldBG.scale.set( 0.7, 0.7 );
      this.add.existing( goldBG );
      this.localGarbage.push( goldBG );

      this.LeaderboardFireAnimation = this.game.make.sprite( this.game.width * 0.0194, this.game.height * 0.0575, 'LeaderboardFireRoyal' );
      let framesAmount = this.game.cache.getFrameCount( 'LeaderboardFireRoyal' );
      let frames = Phaser.Animation.generateFrameNames( 'LeaderboardFireRoyal' + '_', 0, framesAmount, '.png', 4 );
      this.LeaderboardFireAnimation.animations.add( 'LeaderboardFireRoyal', frames );
      this.LeaderboardFireAnimation.animations.play( 'LeaderboardFireRoyal', framesAmount / 2, true );
      this.LeaderboardFireAnimation.animations.currentAnim.onComplete.add( function () {
        this.LeaderboardFireAnimation.destroy( true );
        this.LeaderboardFireAnimation = null;
      }, this );
      this.LeaderboardFireAnimation.scale.set( 4, 3.965 );
      this.LeaderboardFireAnimation.alpha = 0;
      this.add.existing( this.LeaderboardFireAnimation );

      this.royalTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.021 + royalBG.width * 0.525, this.game.height * 0.232, 0, null, 'JackpotRoyalFont', this.game.width * 0.034, '$' );
      this.royalTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.royalTextPlugin.getText() );

      this.grandTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.026 + royalBG.width * 1.02 * 0.525 * 0.71, this.game.height * 0.487, 0, null, 'JackpotPopupFont', this.game.width * 0.019, '$' );
      this.grandTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.grandTextPlugin.getText() );

      this.minorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.0255 + royalBG.width * 1.02 * 0.525 * 0.71 * 0.9, this.game.height * 0.713, 0, null, 'JackpotPopupFont', this.game.width * 0.019, '$' );
      this.minorTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.minorTextPlugin.getText() );

      this.majorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, this.game.width * 0.0255 + royalBG.width * 1.02 * 0.525 * 0.71 * 0.9 * 0.9, this.game.height * 0.937, 0, null, 'JackpotPopupFont', this.game.width * 0.019, '$' );
      this.majorTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.majorTextPlugin.getText() );

      let royalTitle = this.game.make.image( this.game.width * 0.0185, this.game.height * 0.15, 'JackpotTitles', 'Jackpot_Royal_Title.png' );
      royalTitle.anchor.set( 0.0, 0.5 );
      this.add.existing( royalTitle );
      this.localGarbage.push( royalTitle );
      let goldTitle = this.game.make.image( this.game.width * 0.025, this.game.height * 0.65, 'JackpotTitles', 'Jackpot_Title.png' );
      goldTitle.anchor.set( 0.0, 0.5 );
      goldTitle.scale.set( 0.7, 0.7 );
      this.add.existing( goldTitle );
      this.localGarbage.push( goldTitle );
    } else {
      let goldBG = this.game.make.image( 0, this.game.height * 0.48, 'JackpotFrames', 'Jackpot_Frame.png' );
      goldBG.anchor.set( 0.0, 0.5 );
      this.add.existing( goldBG );
      this.localGarbage.push( goldBG );

      this.LeaderboardFireAnimation = this.game.make.sprite( this.game.width * 0.01, this.game.height * 0.0795, 'LeaderboardFire' );
      let framesAmount = this.game.cache.getFrameCount( 'LeaderboardFire' );
      let frames = Phaser.Animation.generateFrameNames( 'LeaderboardFire' + '_', 0, framesAmount, '.png', 4 );
      this.LeaderboardFireAnimation.animations.add( 'LeaderboardFire', frames );
      this.LeaderboardFireAnimation.animations.play( 'LeaderboardFire', framesAmount, true );
      this.LeaderboardFireAnimation.animations.currentAnim.onComplete.add( function () {
        this.LeaderboardFireAnimation.destroy( true );
        this.LeaderboardFireAnimation = null;
      }, this );
      this.LeaderboardFireAnimation.scale.set( 4.03, 4 );
      this.LeaderboardFireAnimation.alpha = 0;
      this.add.existing( this.LeaderboardFireAnimation );

      this.grandTextPlugin = this.game.plugins.add( PluginDecimalFloatText, goldBG.width * 0.465, this.game.height * 0.25, 0, null, 'JackpotPopupFont', this.game.width * 0.028, '$' );
      this.grandTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.grandTextPlugin.getText() );

      this.minorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, goldBG.width * 0.465 * 0.9, this.game.height * 0.57, 0, null, 'JackpotPopupFont', this.game.width * 0.027, '$' );
      this.minorTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.minorTextPlugin.getText() );

      this.majorTextPlugin = this.game.plugins.add( PluginDecimalFloatText, goldBG.width * 0.465 * 0.9 * 0.9, this.game.height * 0.89, 0, null, 'JackpotPopupFont', this.game.width * 0.027, '$' );
      this.majorTextPlugin.getText().anchor.set( 0.5 );
      this.add.existing( this.majorTextPlugin.getText() );

      let goldTitle = this.game.make.image( 0, this.game.height * 0.48, 'JackpotTitles', 'Jackpot_Title.png' );
      goldTitle.anchor.set( 0.0, 0.5 );
      this.add.existing( goldTitle );
      this.localGarbage.push( goldTitle );
    }

    this._updateJackpotStatuses();

    // NEXT THING, TO LINK THIS WITH BACK END
    this.HideOrShowLeaderboardFireAnimation( server.isAnticipation );
  }

  HideOrShowLeaderboardFireAnimation ( show ) {
    if ( !show ) {
      if ( this.LeaderboardFireAnimation.alpha === 1 ) {
        this.LeaderboardFireAnimation.alpha = 0;
        this.stopJackpotFireSound();
      }
    } else if ( this.LeaderboardFireAnimation.alpha === 0 ) {
      this.LeaderboardFireAnimation.alpha = 1;
      this.startJackpotFireSound();
    }
  }

  _destroyPreviousAds () {
    this.game.load.reset();
    this.game.load.removeAll();
    this._destroyPerviousLastWinner();
    this._destroyPerviousLastWinnerWithoutTime();
    this._destroyPerviousOtherData();
    if ( this.leaderboardTimer ) {
      this.leaderboardTimer.stop( true );
      this.leaderboardTimer.destroy( true );
      this.leaderboardTimer = null;
    }

    if ( this.userTextGradientLink ) return;
    if ( this.userTextGradientLink ) this.userTextGradientLink = null; delete this.userTextGradientLink;
    if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
    if ( this.gap ) this.gap = null;
    if ( this.unavailableImage ) this.unavailableImage.destroy(); this.unavailableImage = null;
    if ( this.unavailableImageBG ) this.unavailableImageBG.destroy(); this.unavailableImageBG = null;

    if ( this.btnURL ) this.btnURL.destroy( true ); this.btnURL = null;
    if ( this.adsBG ) this.adsBG.destroy( true ); this.adsBG = null;
    if ( this.adsTop ) this.adsTop.destroy( true ); this.adsTop = null;
    if ( this.adsBigText ) this.adsBigText.destroy( true ); this.adsBigText = null;
    if ( this.adsFirstText ) this.adsFirstText.destroy( true ); this.adsFirstText = null;
    if ( this.adsDivideLine ) this.adsDivideLine.destroy( true ); this.adsDivideLine = null;
    if ( this.adsSecondText ) this.adsSecondText.destroy( true ); this.adsSecondText = null;
    if ( this.gameIcon ) this.gameIcon.destroy( true ); this.gameIcon = null;

    if ( this.prizeAmountText ) this.prizeAmountText.getText().alpha = 0;
    if ( this.infoText1 ) this.infoText1.destroy( true ); this.infoText1 = null;
    if ( this.infoText2 ) this.infoText2.destroy( true ); this.infoText2 = null;
    if ( this.infoText3 ) this.infoText3.destroy( true ); this.infoText3 = null;
    if ( this.infoText4 ) this.infoText4.destroy( true ); this.infoText4 = null;

    if ( this.infoText5 ) this.infoText5.destroy( true ); this.infoText5 = null;
    if ( this.infoText6 ) this.infoText6.destroy( true ); this.infoText6 = null;
    if ( this.infoText7 ) this.infoText7.destroy( true ); this.infoText7 = null;

    if ( this.linkInfo ) this.linkInfo.destroy( true ); this.linkInfo = null;
    if ( this.linkInfo2 ) this.linkInfo2.destroy( true ); this.linkInfo2 = null;
    if ( this.linkInfo3 ) this.linkInfo3.destroy( true ); this.linkInfo3 = null;

    if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
    if ( this.rankText ) this.rankText.destroy( true ); this.rankText = null;
    if ( this.userIDText ) this.userIDText.destroy( true ); this.userIDText = null;
    if ( this.winPonitsText ) this.winPonitsText.destroy( true ); this.winPonitsText = null;
    if ( this.rankNum ) this.rankNum.destroy( true ); this.rankNum = null;
    if ( this.userIDNum ) this.userIDNum.destroy( true ); this.userIDNum = null;
    if ( this.winPonitsNum ) this.winPonitsNum.destroy( true ); this.winPonitsNum = null;
    if ( this.infoText ) this.infoText.destroy( true ); this.infoText = null;
    if ( this.daysNumText ) this.daysNumText.getText().alpha = 0;
    if ( this.daysNum2Text ) this.daysNum2Text.getText().alpha = 0;

    if ( this.hrsNumText ) this.hrsNumText.getText().alpha = 0;
    if ( this.hrs2NumText ) this.hrs2NumText.getText().alpha = 0;
    if ( this.minsNumText ) this.minsNumText.getText().alpha = 0;
    if ( this.minsNum2Text ) this.minsNum2Text.getText().alpha = 0;

    if ( this.divideline2 ) this.divideline2.destroy( true ); this.divideline2 = null;
    if ( this.infoRemainText ) this.infoRemainText.destroy( true ); this.infoRemainText = null;
    if ( this.daysText ) this.daysText.destroy( true ); this.daysText = null;
    if ( this.hrsText ) this.hrsText.destroy( true ); this.hrsText = null;
    if ( this.minsText ) this.minsText.destroy( true ); this.minsText = null;
    if ( this.lastWinText ) this.lastWinText.destroy( true ); this.lastWinText = null;
    if ( this.bottomText ) this.bottomText.destroy( true ); this.bottomText = null;

    if ( this.rankUserText ) this.rankUserText.destroy( true ); this.rankUserText = null;
    if ( this.nameUserText ) this.nameUserText.destroy( true ); this.nameUserText = null;
    if ( this.scoreUserText ) this.scoreUserText.destroy( true ); this.scoreUserText = null;

    if ( this.winAmountText ) this.winAmountText.destroy( true ); this.winAmountText = null;
    if ( this.prizeShare ) this.prizeShare.destroy( true ); this.prizeShare = null;

    this.otherRanksObjects.forEach( function ( element, index ) {
      if ( element[ 'userRank' ] ) element[ 'userRank' ].alpha = 0;
      if ( element[ 'userText' ] ) element[ 'userText' ].alpha = 0;
      if ( element[ 'userScoreText' ] ) element[ 'userScoreText' ].alpha = 0;
      if ( element[ 'prizeAmountLive' ] ) element[ 'prizeAmountLive' ].alpha = 0;
      if ( element[ 'userTextGradient' ] ) element[ 'userTextGradient' ] = null; delete element[ 'userTextGradient' ];
    }, this );
    // this.otherRanksObjects = [];

    this.rankInfoObjects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].alpha = 0;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].alpha = 0;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].alpha = 0;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].alpha = 0;
    }, this );
    this.rankInfo1Objects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].alpha = 0;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].alpha = 0;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].alpha = 0;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].alpha = 0;
    }, this );
    // this.rankInfoObjects = [];

    if ( this.daysNum ) this.daysNum = null; delete this.daysNum;
    if ( this.hrsNum ) this.hrsNum = null; delete this.daysNum;
    if ( this.minsNum ) this.minsNum = null; delete this.daysNum;
    if ( this.gap ) this.gap = null; delete this.gap;
    if ( this.previousURL ) this.previousURL = null;
  }
  _showAds ( adsType ) {
    this._destroyPreviousAds();
    if ( this.adsBG ) this.adsBG.destroy( true ); this.adsBG = null;
    if ( this.adsTop ) this.adsTop.destroy( true ); this.adsTop = null;
    let adsBGString = ( server.projectType === 2 ? 'Leaderboard_Frame2.png' : 'Leaderboard_Frame.png' );
    if ( server.projectType === 3 ) adsBGString = 'Leaderboard_Frame3.png';
    this.adsBG = this.game.make.image( this.game.width * 0.75, this.game.height * 0.56, server.projectType === 3 ? 'Window2' : 'Window', adsBGString );
    this.adsBG.anchor.set( 0.5 );
    this.add.existing( this.adsBG );

    if ( this.gap ) this.gap = null; delete this.gap;
    this.gap = this.game.height * 0.03;

    if ( this.linkInfo ) this.linkInfo.destroy( true ); this.linkInfo = null;
    let textString = ( server.projectType === 2 ? 'PLAY ANYWHERE AT WWW.R7PLAY.COM' : 'PLAY ANYWHERE AT WWW.AMAZING777.COM' );
    if ( server.projectType === 3 ) textString = 'PLAY ANYWHERE AT WWW.SANDMANGOLD.COM';
    this.linkInfo = this.game.make.text( this.game.width * 0.75, this.game.height * 1.187 - this.adsBG.height * 0.3, textString, { font: 'bold ' + this.game.width * 0.016 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
    this.linkInfo.anchor.set( 0.5 );
    this.linkInfo.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
    if ( this.userTextGradientLink ) this.userTextGradientLink = null; delete this.userTextGradient;
    this.userTextGradientLink = this.linkInfo.context.createLinearGradient( 0, 0, 0, this.linkInfo.height );
    this.linkInfo.fill = this.userTextGradientLink;
    this.userTextGradientLink.addColorStop( 0, '#9C9C9C' );
    this.userTextGradientLink.addColorStop( 0.25, '#E0E0E0' );
    this.userTextGradientLink.addColorStop( 0.55, '#FFFFFF' );
    this.userTextGradientLink.addColorStop( 0.57, '#B3B3B3' );
    this.userTextGradientLink.addColorStop( 0.8, '#D1D1D1' );
    this.userTextGradientLink.addColorStop( 1, '#B2B2B2' );

    this.add.existing( this.linkInfo );

    if ( adsType !== this.ads.URLIMAGE && adsType !== this.ads.NOTIMEANDNOLASTWINNER ) {
      this.adsTop = this.game.make.image( this.game.width * 0.75, this.game.height * 0.55 - this.adsBG.height * 0.5 - this.gap, 'Window', 'Leaderboard_Title_Tournament.png' );
      this.adsTop.anchor.set( 0.5 );
      this.add.existing( this.adsTop );
    }

    if ( adsType === this.ads.NOTIMEANDNOLASTWINNER ) {
      if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
      this.divideline1 = this.game.make.image( this.game.width * 0.75, this.game.height * 0.57, 'PlayAnywhere' );
      this.divideline1.anchor.set( 0.5 );
      this.divideline1.scale.set( 0.95 );
      this.add.existing( this.divideline1 );

      if ( this.linkInfo ) this.linkInfo.destroy(); this.linkInfo = null;
      if ( this.linkInfo2 ) this.linkInfo2.destroy(); this.linkInfo2 = null;
      if ( this.linkInfo3 ) this.linkInfo3.destroy(); this.linkInfo3 = null;
    } else if ( adsType === this.ads.TIMEANDLASTWINNER || adsType === this.ads.TIMEADNNOLASTWINNER || adsType === this.ads.NOTIMEANDLASTWINNER ) {
      if ( this.adsBigText ) this.adsBigText.destroy( true ); this.adsBigText = null;

      if ( adsType === this.ads.TIMEANDLASTWINNER )
        this.adsBigText = this.game.make.image( this.game.width * 0.875, this.game.height * 0.54 - this.adsBG.height * 0.3 - this.gap, 'Window', 'leaderboardTotalPrize2.png' );
      else
        this.adsBigText = this.game.make.image( this.game.width * 0.875, this.game.height * 0.54 - this.adsBG.height * 0.3 - this.gap, 'Window', 'leaderboardTotalPrize.png' );
      this.adsBigText.anchor.set( 0.5 );
      this.adsBigText.scale.set( 0.8 );
      this.add.existing( this.adsBigText );

      if ( !this.prizeAmountText )
        this.prizeAmountText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.665, this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
      else {
        this.prizeAmountText.getText().alpha = 1;
        this.prizeAmountText.setup( this.game.width * 0.665, this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
        this.game.world.bringToTop( this.prizeAmountText.getText() );
      }
      this.prizeAmountText.x = this.game.width * 0.665;
      this.prizeAmountText.y = this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap;
      this.prizeAmountText.getText().anchor.set( 0.5 );
      this.prizeAmountText.getText().scale.set( 0.45 );
      this.prizeAmountText.setText( server.CONFIG.TOURNAMENT_PRIZE, false );
      this.add.existing( this.prizeAmountText.getText() );

      if ( adsType === this.ads.NOTIMEANDLASTWINNER ) {
        this.prizeAmountText.getText().x = this.game.width * 0.75;
        this.prizeAmountText.getText().y = this.game.height * 0.6 - this.adsBG.height * 0.3 - this.gap;
        this.prizeAmountText.getText().scale.set( 0.78 );
        this.adsBigText.x = this.game.width * 0.75;
        this.adsBigText.y = this.game.height * 0.72 - this.adsBG.height * 0.3 - this.gap;
        if ( this.infoText1 ) this.infoText1.destroy( true ); this.infoText1 = null;
        if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
        if ( this.infoText2 ) this.infoText2.destroy( true ); this.infoText2 = null;
        if ( this.infoText3 ) this.infoText3.destroy( true ); this.infoText3 = null;
        if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
        if ( this.infoText4 ) this.infoText4.destroy( true ); this.infoText4 = null;

        if ( this.rankText ) this.rankText.destroy( true ); this.rankText = null;
        if ( this.userIDText ) this.userIDText.destroy( true ); this.userIDText = null;
        if ( this.winPonitsText ) this.winPonitsText.destroy( true ); this.winPonitsText = null;
        if ( this.prizeShare ) this.prizeShare.destroy( true ); this.prizeShare = null;

        this.infoText1 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.8 - this.adsBG.height * 0.3 - this.gap, 'THE TOURNAMENT IS', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
        this.infoText1.anchor.set( 0.5 );
        this.infoText1.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
        this.userTextGradient = this.infoText1.context.createLinearGradient( 0, 0, 0, this.infoText1.height );
        this.infoText1.fill = this.userTextGradient;
        this.userTextGradient.addColorStop( 0, '#9C9C9C' );
        this.userTextGradient.addColorStop( 0.25, '#E0E0E0' );
        this.userTextGradient.addColorStop( 0.55, '#FFFFFF' );
        this.userTextGradient.addColorStop( 0.57, '#B3B3B3' );
        this.userTextGradient.addColorStop( 0.8, '#D1D1D1' );
        this.userTextGradient.addColorStop( 1, '#B2B2B2' );
        this.add.existing( this.infoText1 );

        this.infoText2 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.85 - this.adsBG.height * 0.3 - this.gap, 'COMING SOON! STAY TUNED', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
        this.infoText2.anchor.set( 0.5 );
        this.infoText2.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
        this.infoText2.fill = this.userTextGradient;
        this.add.existing( this.infoText2 );

        this.infoText3 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.9 - this.adsBG.height * 0.3 - this.gap, 'FOR MORE INFORMATION', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
        this.infoText3.anchor.set( 0.5 );
        this.infoText3.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
        this.infoText3.fill = this.userTextGradient;
        this.add.existing( this.infoText3 );

        this.divideline1 = this.game.make.image( this.game.width * 0.75, this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
        this.divideline1.anchor.set( 0.5 );
        this.add.existing( this.divideline1 );

        this.infoText4 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.99 - this.adsBG.height * 0.3, 'LAST TOURNAMENT\'S WINNER', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
        this.infoText4.anchor.set( 0.5 );
        this.infoText4.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
        this.infoText4.fill = this.userTextGradient;
        this.add.existing( this.infoText4 );

        this.rankText = this.game.make.text( this.game.width * 0.57, this.game.height * 1.05 - this.adsBG.height * 0.3, 'RANK', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.rankText.anchor.set( 0.5 );
        this.add.existing( this.rankText );

        this.userIDText = this.game.make.text( this.game.width * 0.65, this.game.height * 1.05 - this.adsBG.height * 0.3, 'USER ID', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.userIDText.anchor.set( 0.5 );
        this.add.existing( this.userIDText );

        this.winPonitsText = this.game.make.text( this.game.width * 0.75, this.game.height * 1.05 - this.adsBG.height * 0.3, 'WIN POINTS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.winPonitsText.anchor.set( 0.5 );
        this.add.existing( this.winPonitsText );

        this.prizeShare = this.game.make.text( this.game.width * 0.89, this.game.height * 1.05 - this.adsBG.height * 0.3, 'PRIZE SHARE', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.prizeShare.anchor.set( 0.5 );
        this.add.existing( this.prizeShare );

        this._updateLastWinnerWithoutTime();
        return;
      }

      if ( this.infoText1 ) this.infoText1.destroy( true ); this.infoText1 = null;
      if ( this.infoText ) this.infoText.destroy( true ); this.infoText = null;
      if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
      if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
      this.infoText1 = this.game.make.text( this.game.width * 0.75, ( adsType === this.ads.TIMEANDLASTWINNER ) ? this.game.height * 0.37 - this.gap : this.game.height * 0.8 - this.adsBG.height * 0.3 - this.gap, 'THE TOURNAMENT WILL START', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText1.anchor.set( 0.5 );
      this.userTextGradient = this.infoText1.context.createLinearGradient( 0, 0, 0, this.infoText1.height );
      this.infoText1.fill = this.userTextGradient;
      this.userTextGradient.addColorStop( 0, '#9C9C9C' );
      this.userTextGradient.addColorStop( 0.25, '#E0E0E0' );
      this.userTextGradient.addColorStop( 0.6, '#FFFFFF' );
      this.userTextGradient.addColorStop( 0.61, '#B3B3B3' );
      this.userTextGradient.addColorStop( 0.8, '#D1D1D1' );
      this.userTextGradient.addColorStop( 1, '#B2B2B2' );
      this.add.existing( this.infoText1 );

      this.infoText = this.game.make.text( this.game.width * 0.75, ( adsType === this.ads.TIMEANDLASTWINNER ) ? this.game.height * 0.42 - this.gap : this.game.height * 0.85 - this.adsBG.height * 0.3 - this.gap, 'ON ' + server.CONFIG.TOURNAMENT_STARTTIME, { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText.anchor.set( 0.5 );

      this.infoText.fill = this.userTextGradient;

      this.add.existing( this.infoText );

      this.divideline1 = this.game.make.image( this.game.width * 0.75, ( adsType === this.ads.TIMEANDLASTWINNER ) ? this.game.height * 0.45 - this.gap : this.game.height * 0.88 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
      this.divideline1.anchor.set( 0.5 );
      this.add.existing( this.divideline1 );

      let timeHeight = ( adsType === this.ads.TIMEANDLASTWINNER ) ? this.game.height * 0.65 - this.gap : this.game.height * 1.08 - this.adsBG.height * 0.3 - this.gap;

      if ( !this.daysNumText )
        this.daysNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.58, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      else {
        this.daysNumText.getText().alpha = 1;
        this.daysNumText.setup( this.game.width * 0.58, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.daysNumText );
      }
      this.daysNumText.getText().anchor.set( 0.5 );
      this.daysNumText.getText().scale.set( 0.6 );
      this.daysNumText.setText( this.daysNum ? this.daysNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.daysNumText.getText() );

      if ( !this.daysNum2Text )
        this.daysNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.633, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      else {
        this.daysNum2Text.getText().alpha = 1;
        this.daysNum2Text.setup( this.game.width * 0.633, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.daysNum2Text );
      }
      this.daysNum2Text.getText().anchor.set( 0.5 );
      this.daysNum2Text.getText().scale.set( 0.6 );
      this.daysNum2Text.setText( this.daysNum ? this.daysNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.daysNum2Text.getText() );

      if ( !this.hrsNumText )
        this.hrsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.71, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      else {
        this.hrsNumText.getText().alpha = 1;
        this.hrsNumText.setup( this.game.width * 0.71, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, ':' );
        this.game.world.bringToTop( this.hrsNumText );
      }
      // this.hrsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.71, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, ':' );
      this.hrsNumText.getText().anchor.set( 0.5 );
      this.hrsNumText.getText().scale.set( 0.6 );
      this.hrsNumText.setText( this.hrsNum ? this.hrsNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.hrsNumText.getText() );

      if ( !this.hrs2NumText )
        this.hrs2NumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.775, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      else {
        this.hrs2NumText.getText().alpha = 1;
        this.hrs2NumText.setup( this.game.width * 0.775, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.hrs2NumText );
      }
      // this.hrs2NumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.775, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      this.hrs2NumText.getText().anchor.set( 0.5 );
      this.hrs2NumText.getText().scale.set( 0.6 );
      this.hrs2NumText.setText( this.hrsNum ? this.hrsNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.hrs2NumText.getText() );

      if ( !this.minsNumText )
        this.minsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.85, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, ':' );
      else {
        this.minsNumText.getText().alpha = 1;
        this.minsNumText.setup( this.game.width * 0.85, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, ':' );
        this.game.world.bringToTop( this.minsNumText );
      }
      // this.minsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.85, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, ':' );
      this.minsNumText.getText().anchor.set( 0.5 );
      this.minsNumText.getText().scale.set( 0.6 );
      this.minsNumText.setText( this.minsNum ? this.minsNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.minsNumText.getText() );

      if ( !this.minsNum2Text )
        this.minsNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.915, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      else {
        this.minsNum2Text.getText().alpha = 1;
        this.minsNum2Text.setup( this.game.width * 0.915, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.minsNum2Text );
      }
      // this.minsNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.915, timeHeight, 0, null, 'TournamentTimeFont', this.game.width * 0.06, '' );
      this.minsNum2Text.getText().anchor.set( 0.5 );
      this.minsNum2Text.getText().scale.set( 0.6 );
      this.minsNum2Text.setText( this.minsNum ? this.minsNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.minsNum2Text.getText() );
      timeHeight = null;

      if ( adsType === this.ads.TIMEANDLASTWINNER ) {
        if ( this.divideline2 ) this.divideline2.destroy( true ); this.divideline2 = null;
        if ( this.infoRemainText ) this.infoRemainText.destroy( true ); this.infoRemainText = null;
        if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
        if ( this.daysText ) this.daysText.destroy( true ); this.daysText = null;
        if ( this.hrsText ) this.hrsText.destroy( true ); this.hrsText = null;
        if ( this.minsText ) this.minsText.destroy( true ); this.minsText = null;
        if ( this.rankText ) this.rankText.destroy( true ); this.rankText = null;
        if ( this.userIDText ) this.userIDText.destroy( true ); this.userIDText = null;
        if ( this.winPonitsText ) this.winPonitsText.destroy( true ); this.winPonitsText = null;
        if ( this.prizeShare ) this.prizeShare.destroy( true ); this.prizeShare = null;
        if ( this.lastWinText ) this.lastWinText.destroy( true ); this.lastWinText = null;

        this.divideline2 = this.game.make.image( this.game.width * 0.75, this.game.height * 1 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
        this.divideline2.anchor.set( 0.5 );
        this.add.existing( this.divideline2 );

        this.infoRemainText = this.game.make.text( this.game.width * 0.75, this.game.height * 0.75 - this.adsBG.height * 0.3 - this.gap, 'TIME REMAINING UNTIL\n THE TOURNAMENT BEGINS:', { font: 'bold ' + this.game.width * 0.018 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.infoRemainText.anchor.set( 0.5 );
        this.add.existing( this.infoRemainText );

        this.daysText = this.game.make.text( this.game.width * 0.6, this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap, 'DAYS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.daysText.anchor.set( 0.5 );
        this.add.existing( this.daysText );

        this.hrsText = this.game.make.text( this.game.width * 0.75, this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap, 'HOURS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.hrsText.anchor.set( 0.5 );
        this.add.existing( this.hrsText );

        this.minsText = this.game.make.text( this.game.width * 0.9, this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap, 'MINS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.minsText.anchor.set( 0.5 );
        this.add.existing( this.minsText );

        this.rankText = this.game.make.text( this.game.width * 0.57, this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap, 'RANK', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.rankText.anchor.set( 0.5 );
        this.add.existing( this.rankText );

        this.userIDText = this.game.make.text( this.game.width * 0.65, this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap, 'USER ID', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.userIDText.anchor.set( 0.5 );
        this.add.existing( this.userIDText );

        this.winPonitsText = this.game.make.text( this.game.width * 0.75, this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap, 'WIN POINTS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.winPonitsText.anchor.set( 0.5 );
        this.add.existing( this.winPonitsText );

        this.prizeShare = this.game.make.text( this.game.width * 0.89, this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap, 'PRIZE SHARE', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        this.prizeShare.anchor.set( 0.5 );
        this.add.existing( this.prizeShare );

        this.lastWinText = this.game.make.text( this.game.width * 0.75, this.game.height * 1.05 - this.adsBG.height * 0.3 - this.gap, 'LAST TOURNAMENT\'S WINNER:', { font: 'bold ' + this.game.width * 0.02 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
        this.lastWinText.anchor.set( 0.5 );
        this.userTextGradient = this.lastWinText.context.createLinearGradient( 0, 0, 0, this.lastWinText.height );
        this.lastWinText.fill = this.userTextGradient;
        this.userTextGradient.addColorStop( 0, '#9C9C9C' );
        this.userTextGradient.addColorStop( 0.25, '#E0E0E0' );
        this.userTextGradient.addColorStop( 0.6, '#FFFFFF' );
        this.userTextGradient.addColorStop( 0.61, '#B3B3B3' );
        this.userTextGradient.addColorStop( 0.8, '#D1D1D1' );
        this.userTextGradient.addColorStop( 1, '#B2B2B2' );
        this.add.existing( this.lastWinText );

        this._updateLastWinner();
      } else {
        if ( this.daysText ) this.daysText.destroy( true ); this.daysText = null;
        if ( this.hrsText ) this.hrsText.destroy( true ); this.hrsText = null;
        if ( this.minsText ) this.minsText.destroy( true ); this.minsText = null;
        if ( this.divideline2 ) this.divideline2.destroy( true ); this.divideline2 = null;
        if ( this.infoRemainText ) this.infoRemainText.destroy( true ); this.infoRemainText = null;
        this.prizeAmountText.getText().x = this.game.width * 0.75;
        this.prizeAmountText.getText().y = this.game.height * 0.6 - this.adsBG.height * 0.3 - this.gap;
        this.prizeAmountText.getText().scale.set( 0.78 );
        this.adsBigText.x = this.game.width * 0.75;
        this.adsBigText.y = this.game.height * 0.72 - this.adsBG.height * 0.3 - this.gap;

        this.divideline2 = this.game.make.image( this.game.width * 0.75, this.game.height * 1.19 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
        this.divideline2.anchor.set( 0.5 );
        this.add.existing( this.divideline2 );

        this.infoRemainText = this.game.make.text( this.game.width * 0.75, this.game.height * 0.95 - this.adsBG.height * 0.3 - this.gap, 'TIME REMAINING UNTIL\n THE TOURNAMENT BEGINS', { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.infoRemainText.anchor.set( 0.5 );
        this.add.existing( this.infoRemainText );

        this.daysText = this.game.make.text( this.game.width * 0.6, this.game.height * 1.16 - this.adsBG.height * 0.3 - this.gap, 'DAYS', { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.daysText.anchor.set( 0.5 );
        this.add.existing( this.daysText );

        this.hrsText = this.game.make.text( this.game.width * 0.75, this.game.height * 1.16 - this.adsBG.height * 0.3 - this.gap, 'HOURS', { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.hrsText.anchor.set( 0.5 );
        this.add.existing( this.hrsText );

        this.minsText = this.game.make.text( this.game.width * 0.9, this.game.height * 1.16 - this.adsBG.height * 0.3 - this.gap, 'MINS', { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
        this.minsText.anchor.set( 0.5 );
        this.add.existing( this.minsText );
      }
    } else if ( adsType === this.ads.LIVE ) {
      if ( this.adsBigText ) this.adsBigText.destroy( true ); this.adsBigText = null;
      if ( this.prizeAmountText ) this.prizeAmountText.getText().alpha = 0;
      if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
      if ( this.divideline2 ) this.divideline2.destroy( true ); this.divideline2 = null;

      if ( this.infoRemainText ) this.infoRemainText.destroy( true ); this.infoRemainText = null;
      if ( this.daysText ) this.daysText.destroy( true ); this.daysText = null;
      if ( this.hrsText ) this.hrsText.destroy( true ); this.hrsText = null;
      if ( this.minsText ) this.minsText.destroy( true ); this.minsText = null;

      if ( this.rankText ) this.rankText.destroy( true ); this.rankText = null;
      if ( this.userIDText ) this.userIDText.destroy( true ); this.userIDText = null;
      if ( this.winPonitsText ) this.winPonitsText.destroy( true ); this.winPonitsText = null;
      if ( this.winAmountText ) this.winAmountText.destroy( true ); this.winAmountText = null;
      if ( this.bottomText ) this.bottomText.destroy( true ); this.bottomText = null;
      this.adsBigText = this.game.make.image( this.game.width * 0.875, this.game.height * 0.54 - this.adsBG.height * 0.3 - this.gap, 'Window', 'leaderboardTotalPrize2.png' );
      this.adsBigText.anchor.set( 0.5 );
      this.adsBigText.scale.set( 0.8 );
      this.add.existing( this.adsBigText );

      if ( !this.prizeAmountText )
        this.prizeAmountText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.665, this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
      else {
        this.prizeAmountText.getText().alpha = 1;
        this.prizeAmountText.setup( this.game.width * 0.665, this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
        this.game.world.bringToTop( this.prizeAmountText.getText() );
      }
      this.prizeAmountText.x = this.game.width * 0.665;
      this.prizeAmountText.y = this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap;
      this.prizeAmountText.getText().anchor.set( 0.5 );
      this.prizeAmountText.getText().scale.set( 0.45 );
      this.prizeAmountText.setText( server.CONFIG.TOURNAMENT_PRIZE, false );
      this.add.existing( this.prizeAmountText.getText() );

      this.divideline1 = this.game.make.image( this.game.width * 0.75, this.game.height * 0.6 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
      this.divideline1.anchor.set( 0.5 );
      this.add.existing( this.divideline1 );

      this.divideline2 = this.game.make.image( this.game.width * 0.75, this.game.height * 0.79 - this.adsBG.height * 0.3 - this.gap, 'Window', 'Divider.png' );
      this.divideline2.anchor.set( 0.5 );
      this.add.existing( this.divideline2 );

      let timeHeight = this.game.height * 0.7 - this.adsBG.height * 0.3 - this.gap;
      let scaleSize = 0.9;

      if ( !this.daysNumText )
        this.daysNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.63, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      else {
        this.daysNumText.getText().alpha = 1;
        this.daysNumText.setup( this.game.width * 0.63, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.daysNumText );
      }
      this.daysNumText.getText().anchor.set( 0.5 );
      this.daysNumText.getText().scale.set( scaleSize );
      this.daysNumText.setText( this.daysNum ? this.daysNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.daysNumText.getText() );

      if ( !this.daysNum2Text )
        this.daysNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.67, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      else {
        this.daysNum2Text.getText().alpha = 1;
        this.daysNum2Text.setup( this.game.width * 0.67, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.daysNum2Text );
      }
      // this.daysNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.67, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      this.daysNum2Text.getText().anchor.set( 0.5 );
      this.daysNum2Text.getText().scale.set( scaleSize );
      this.daysNum2Text.setText( this.daysNum ? this.daysNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.daysNum2Text.getText() );

      if ( !this.hrsNumText )
        this.hrsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.722, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
      else {
        this.hrsNumText.getText().alpha = 1;
        this.hrsNumText.setup( this.game.width * 0.722, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
        this.game.world.bringToTop( this.hrsNumText );
      }
      // this.hrsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.722, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
      this.hrsNumText.getText().anchor.set( 0.5 );
      this.hrsNumText.getText().scale.set( scaleSize );
      this.hrsNumText.setText( this.hrsNum ? this.hrsNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.hrsNumText.getText() );

      if ( !this.hrs2NumText )
        this.hrs2NumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.77, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      else {
        this.hrs2NumText.getText().alpha = 1;
        this.hrs2NumText.setup( this.game.width * 0.77, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.hrs2NumText );
      }
      // this.hrs2NumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.77, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      this.hrs2NumText.getText().anchor.set( 0.5 );
      this.hrs2NumText.getText().scale.set( scaleSize );
      this.hrs2NumText.setText( this.hrsNum ? this.hrsNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.hrs2NumText.getText() );

      if ( !this.minsNumText )
        this.minsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.822, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
      else {
        this.minsNumText.getText().alpha = 1;
        this.minsNumText.setup( this.game.width * 0.822, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
        this.game.world.bringToTop( this.minsNumText );
      }
      // this.minsNumText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.822, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, ':' );
      this.minsNumText.getText().anchor.set( 0.5 );
      this.minsNumText.getText().scale.set( scaleSize );
      this.minsNumText.setText( this.minsNum ? this.minsNum.substring( 0, 1 ) : 0, false );
      this.add.existing( this.minsNumText.getText() );

      if ( !this.minsNum2Text )
        this.minsNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.87, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      else {
        this.minsNum2Text.getText().alpha = 1;
        this.minsNum2Text.setup( this.game.width * 0.87, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
        this.game.world.bringToTop( this.minsNum2Text );
      }
      // this.minsNum2Text = this.game.plugins.add( PluginDecimalText, this.game.width * 0.87, timeHeight, 0, null, 'TournamentTimeFontMiddle', this.game.width * 0.06, '' );
      this.minsNum2Text.getText().anchor.set( 0.5 );
      this.minsNum2Text.getText().scale.set( scaleSize );
      this.minsNum2Text.setText( this.minsNum ? this.minsNum.substring( 1, 2 ) : 0, false );
      this.add.existing( this.minsNum2Text.getText() );

      this.infoRemainText = this.game.make.text( this.game.width * 0.75, this.game.height * 0.63 - this.adsBG.height * 0.3 - this.gap, 'TIME REMAINING IN THE TOURNAMENT:', { font: 'bold ' + this.game.width * 0.018 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
      this.infoRemainText.anchor.set( 0.5 );
      this.add.existing( this.infoRemainText );

      this.daysText = this.game.make.text( this.game.width * 0.65, this.game.height * 0.76 - this.adsBG.height * 0.3 - this.gap, 'DAYS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
      this.daysText.anchor.set( 0.5 );
      this.add.existing( this.daysText );

      this.hrsText = this.game.make.text( this.game.width * 0.75, this.game.height * 0.76 - this.adsBG.height * 0.3 - this.gap, 'HOURS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
      this.hrsText.anchor.set( 0.5 );
      this.add.existing( this.hrsText );

      this.minsText = this.game.make.text( this.game.width * 0.85, this.game.height * 0.76 - this.adsBG.height * 0.3 - this.gap, 'MINS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#2AFF07', align: 'center' } );
      this.minsText.anchor.set( 0.5 );
      this.add.existing( this.minsText );

      this.rankText = this.game.make.text( this.game.width * 0.57, this.game.height * 0.84 - this.adsBG.height * 0.3 - this.gap, 'RANK', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
      this.rankText.anchor.set( 0.5 );
      this.add.existing( this.rankText );

      this.userIDText = this.game.make.text( this.game.width * 0.65, this.game.height * 0.84 - this.adsBG.height * 0.3 - this.gap, 'USER ID', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
      this.userIDText.anchor.set( 0.5 );
      this.add.existing( this.userIDText );

      this.winPonitsText = this.game.make.text( this.game.width * 0.765, this.game.height * 0.84 - this.adsBG.height * 0.3 - this.gap, 'WIN POINTS', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
      this.winPonitsText.anchor.set( 0.5 );
      this.add.existing( this.winPonitsText );

      this.winAmountText = this.game.make.text( this.game.width * 0.89, this.game.height * 0.84 - this.adsBG.height * 0.3 - this.gap, 'PRIZE SHARE', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
      this.winAmountText.anchor.set( 0.5 );
      this.add.existing( this.winAmountText );

      this.bottomText = this.game.make.text( this.game.width * 0.75, this.game.height * 1.18 - this.adsBG.height * 0.3 - this.gap, 'PLAYER RESULTS ARE UPDATED EVERY 1 MINUTE', { font: 'bold ' + this.game.width * 0.015 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
      this.bottomText.anchor.set( 0.5 );
      this.add.existing( this.bottomText );
      timeHeight = null;
      scaleSize = null;
    } else if ( adsType === this.ads.URLIMAGE ) {
      if ( this.infoText1 ) this.infoText1.destroy( true ); this.infoText1 = null;
      if ( this.infoText2 ) this.infoText2.destroy( true ); this.infoText2 = null;
      if ( this.infoText3 ) this.infoText3.destroy( true ); this.infoText3 = null;
      if ( this.infoText4 ) this.infoText4.destroy( true ); this.infoText4 = null;
      if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
      if ( this.divideline1 ) this.divideline1.destroy( true ); this.divideline1 = null;
      if ( this.btnURL ) this.btnURL.destroy( true ); this.btnURL = null;
      // this.infoText1 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.47 - this.adsBG.height * 0.3, 'THERE IS NO TOURNAMENT', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      // this.infoText1.anchor.set( 0.5 );
      // this.infoText1.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      // this.infoText2 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.52 - this.adsBG.height * 0.3, 'CURRENTLY PLANNED!', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      // this.infoText2.anchor.set( 0.5 );
      // this.infoText2.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      // this.infoText3 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.62 - this.adsBG.height * 0.3, 'IN THE MEANTIME,', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      // this.infoText3.anchor.set( 0.5 );
      // this.infoText3.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      // this.infoText4 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.67 - this.adsBG.height * 0.3, 'CHECK THIS OUT!', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      // this.infoText4.anchor.set( 0.5 );
      // this.infoText4.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      // this.userTextGradient = this.infoText1.context.createLinearGradient( 0, 0, 0, this.infoText1.height );
      // this.infoText1.fill = this.userTextGradient;
      // this.infoText2.fill = this.userTextGradient;
      // this.infoText3.fill = this.userTextGradient;
      // this.infoText4.fill = this.userTextGradient;
      // this.userTextGradient.addColorStop( 0, '#9C9C9C' );
      // this.userTextGradient.addColorStop( 0.25, '#E0E0E0' );
      // this.userTextGradient.addColorStop( 0.55, '#FFFFFF' );
      // this.userTextGradient.addColorStop( 0.57, '#B3B3B3' );
      // this.userTextGradient.addColorStop( 0.8, '#D1D1D1' );
      // this.userTextGradient.addColorStop( 1, '#B2B2B2' );
      // this.add.existing( this.infoText1 );
      // this.add.existing( this.infoText2 );
      // this.add.existing( this.infoText3 );
      // this.add.existing( this.infoText4 );

      // this.divideline1 = this.game.make.image( this.game.width * 0.75, this.game.height * 0.57 - this.adsBG.height * 0.3, 'Window', 'Divider.png' );
      // this.divideline1.anchor.set( 0.5 );
      // this.add.existing( this.divideline1 );

      this.btnURL = this.game.make.button( this.game.width * 0.75, this.game.height * 0.82 - this.adsBG.height * 0.33, 'GameIcons', this.urlClicked, this );
      // this.btnURL.setFrames( 'test.png', 'test.png', 'test.png', 'test.png' );
      this.btnURL.anchor.set( 0.5 );
      this.btnURL.scale.set( 0 );
      this.add.existing( this.btnURL );

      this.previousURL = server.CONFIG.TOURNAMENT_URLLINK;
      server.preloadExternalImage( this.game, 'externalImg', this.previousURL, function ( cacheKey ) {
        if ( !this.game.cache.checkImageKey( cacheKey ) ) {
          this.btnURL.setFrames( 'test.png', 'test.png', 'test.png', 'test.png' );
          this.btnURL.scale.set( 1 );
          return;
        }
        this.btnURL.loadTexture( cacheKey );
        this.btnURL.scale.set( this.game.width * 0.001 * 0.5 );
      }, this );
    } else if ( adsType === this.ads.ENDINGPAGE ) {
      if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
      if ( this.infoText1 ) this.infoText1.destroy( true ); this.infoText1 = null;
      if ( this.infoText2 ) this.infoText2.destroy( true ); this.infoText2 = null;
      if ( this.infoText3 ) this.infoText3.destroy( true ); this.infoText3 = null;
      if ( this.infoText4 ) this.infoText4.destroy( true ); this.infoText4 = null;
      if ( this.adsBigText ) this.adsBigText.destroy( true ); this.adsBigText = null;
      if ( this.prizeAmountText ) this.prizeAmountText.getText().alpha = 0;
      this.infoText1 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.55 - this.adsBG.height * 0.3 - this.gap, 'THE TOURNAMENT IS', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText1.anchor.set( 0.5 );
      this.infoText1.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      this.infoText2 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.6 - this.adsBG.height * 0.3 - this.gap, 'ENDING SOON!', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText2.anchor.set( 0.5 );
      this.infoText2.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      this.infoText3 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.7 - this.adsBG.height * 0.3 - this.gap, 'THIS IS YOUR LAST', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText3.anchor.set( 0.5 );
      this.infoText3.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      this.infoText4 = this.game.make.text( this.game.width * 0.75, this.game.height * 0.75 - this.adsBG.height * 0.3 - this.gap, 'CHANCE TO PLAY FOR THE', { font: 'bold ' + this.game.width * 0.026 + 'px Roboto', fill: '#FFFFFF', align: 'center' } );
      this.infoText4.anchor.set( 0.5 );
      this.infoText4.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

      this.userTextGradient = this.infoText1.context.createLinearGradient( 0, 0, 0, this.infoText1.height );
      this.infoText1.fill = this.userTextGradient;
      this.infoText2.fill = this.userTextGradient;
      this.infoText3.fill = this.userTextGradient;
      this.infoText4.fill = this.userTextGradient;
      this.userTextGradient.addColorStop( 0, '#9C9C9C' );
      this.userTextGradient.addColorStop( 0.25, '#E0E0E0' );
      this.userTextGradient.addColorStop( 0.55, '#FFFFFF' );
      this.userTextGradient.addColorStop( 0.57, '#B3B3B3' );
      this.userTextGradient.addColorStop( 0.8, '#D1D1D1' );
      this.userTextGradient.addColorStop( 1, '#B2B2B2' );
      this.add.existing( this.infoText1 );
      this.add.existing( this.infoText2 );
      this.add.existing( this.infoText3 );
      this.add.existing( this.infoText4 );

      this.adsBigText = this.game.make.image( this.game.width * 0.75, this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap, 'Window', 'leaderboardTotalPrize.png' );
      this.adsBigText.anchor.set( 0.5 );
      this.adsBigText.scale.set( 1 );
      this.add.existing( this.adsBigText );

      if ( !this.prizeAmountText )
        this.prizeAmountText = this.game.plugins.add( PluginDecimalText, this.game.width * 0.75, this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
      else {
        this.prizeAmountText.getText().alpha = 1;
        this.prizeAmountText.setup( this.game.width * 0.75, this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
        this.game.world.bringToTop( this.prizeAmountText.getText() );
      }
      this.prizeAmountText.x = this.game.width * 0.75;
      this.prizeAmountText.y = this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap;
      this.prizeAmountText.getText().anchor.set( 0.5 );
      this.prizeAmountText.getText().scale.set( 0.78 );
      this.prizeAmountText.setText( server.CONFIG.TOURNAMENT_PRIZE, false );
      this.add.existing( this.prizeAmountText.getText() );
    }
    this._updateOtherData();
    this._updateTourInfo();

    if ( this.unavailableImage ) this.unavailableImage.destroy( true ); this.unavailableImage = null;
    if ( this.unavailableImageBG ) this.unavailableImageBG.destroy( true ); this.unavailableImageBG = null;
    this.unavailableImageBG = this.game.make.image( 0, 0, 'WinnerBG' );
    this.unavailableImageBG.scale.set( this.game.width / this.unavailableImageBG.width );
    this.unavailableImageBG.alpha = 0;
    this.add.existing( this.unavailableImageBG );

    this.unavailableImage = this.game.make.image( this.game.width * 0.5, this.game.height * 0.5, 'WindowUnavailable' );
    this.unavailableImage.anchor.set( 0.5 );
    this.unavailableImage.alpha = 0;
    this.add.existing( this.unavailableImage );
  }
  urlClicked () {
    window.open( server.CONFIG.TOURNAMENT_URLLINK ? server.CONFIG.TOURNAMENT_URLLINK : 'https://skyline-dev.bitglu.io:8443', '_blank' );
  }
  _destroyPerviousLastWinner () {
    if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
    this.rankInfo1Objects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].alpha = 0;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].alpha = 0;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].alpha = 0;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].getText().alpha = 0;
    }, this );
    // this.rankInfoObjects = [];
  }
  _updateLastWinner () {
    this._destroyPerviousLastWinner();
    let k = server.CONFIG.TOURNAMENT_LASTWINNER.length - 1;
    if ( !this.rankInfo1Objects.length )
      for ( let i = 4; i >= 0; i-- ) {
        let rankNumLast = this.game.make.text( this.game.width * 0.57, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * i, 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        rankNumLast.anchor.set( 0.5 );
        rankNumLast.alpha = 0;
        this.add.existing( rankNumLast );

        let userIDNumLast = this.game.make.text( this.game.width * 0.65, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * i, 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        userIDNumLast.anchor.set( 0.5 );
        userIDNumLast.alpha = 0;
        this.add.existing( userIDNumLast );

        let winPonitsNumLast = this.game.make.text( this.game.width * 0.75, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * i, 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        winPonitsNumLast.anchor.set( 0.5 );
        winPonitsNumLast.alpha = 0;
        this.add.existing( winPonitsNumLast );

        let userTextGradient = rankNumLast.context.createLinearGradient( 0, 0, 0, rankNumLast.height );
        rankNumLast.fill = userTextGradient;
        userIDNumLast.fill = userTextGradient;
        winPonitsNumLast.fill = userTextGradient;
        userTextGradient.addColorStop( 0, '#FA120B' );
        userTextGradient.addColorStop( 0.15, '#FFCB07' );
        userTextGradient.addColorStop( 0.4, '#FFFF56' );
        userTextGradient.addColorStop( 0.5, '#FFFFC5' );
        userTextGradient.addColorStop( 0.7, '#FFA10B' );
        userTextGradient.addColorStop( 0.85, '#B45508' );
        userTextGradient.addColorStop( 1, '#FFA10B' );
        userTextGradient = null;

        let prizeShareAmountLast = this.game.plugins.add( PluginDecimalText, this.game.width * 0.88, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * i, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
        prizeShareAmountLast.getText().anchor.set( 0.5 );
        prizeShareAmountLast.getText().scale.set( 0.2 );
        prizeShareAmountLast.getText().alpha = 0;
        // prizeShareAmountLast.setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'prize' ] / 100, false );
        this.add.existing( prizeShareAmountLast.getText() );
        this.localGarbage.push( prizeShareAmountLast.getText() );

        this.add.existing( rankNumLast );
        this.add.existing( userIDNumLast );
        this.add.existing( winPonitsNumLast );
        this.rankInfo1Objects.push( { 'rankNum': rankNumLast, 'userIDNum': userIDNumLast, 'winPonitsNum': winPonitsNumLast, 'prizeShareAmount': prizeShareAmountLast } );
      }

    for ( let i = k; i >= 0; i-- ) {
      if ( this.rankInfo1Objects[ 4 - i ][ 'rankNum' ].alpha === 0 ) {
        this.rankInfo1Objects[ 4 - i ][ 'rankNum' ].alpha = 1;
        this.rankInfo1Objects[ 4 - i ][ 'userIDNum' ].alpha = 1;
        this.rankInfo1Objects[ 4 - i ][ 'winPonitsNum' ].alpha = 1;
        this.rankInfo1Objects[ 4 - i ][ 'prizeShareAmount' ].getText().alpha = 1;
        if ( !server.isShowingPopup ) {
          this.game.world.bringToTop( this.rankInfo1Objects[ 4 - i ][ 'rankNum' ] );
          this.game.world.bringToTop( this.rankInfo1Objects[ 4 - i ][ 'userIDNum' ] );
          this.game.world.bringToTop( this.rankInfo1Objects[ 4 - i ][ 'winPonitsNum' ] );
          this.game.world.bringToTop( this.rankInfo1Objects[ 4 - i ][ 'prizeShareAmount' ].getText() );
        }
      }
      this.rankInfo1Objects[ 4 - i ][ 'rankNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'rank' ] );
      this.rankInfo1Objects[ 4 - i ][ 'userIDNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'playerNumber' ] );
      this.rankInfo1Objects[ 4 - i ][ 'winPonitsNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'winAmount' ] );
      this.rankInfo1Objects[ 4 - i ][ 'prizeShareAmount' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'prize' ] / 100, false );
    }
    if ( k === 0 ) {
      this.infoRemainText.setText( 'TIME REMAINING UNTIL\n THE TOURNAMENT BEGINS' );
      this.infoRemainText.scale.set( 1 );
      this.infoRemainText.y = this.game.height * 0.75 - this.adsBG.height * 0.3 - this.gap;
      this.daysNumText.getText().y = this.game.height * 0.65 - this.gap;
      this.daysNum2Text.getText().y = this.game.height * 0.65 - this.gap;
      this.hrsNumText.getText().y = this.game.height * 0.65 - this.gap;
      this.hrs2NumText.getText().y = this.game.height * 0.65 - this.gap;
      this.minsNumText.getText().y = this.game.height * 0.65 - this.gap;
      this.minsNum2Text.getText().y = this.game.height * 0.65 - this.gap;
      this.daysText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap;
      this.hrsText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap;
      this.minsText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap;
      this.divideline2.y = this.game.height * 1 - this.adsBG.height * 0.3 - this.gap;
      this.lastWinText.y = this.game.height * 1.05 - this.adsBG.height * 0.3 - this.gap;
      this.rankText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap;
      this.userIDText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap;
      this.winPonitsText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap;
      this.prizeShare.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap;
    } else if ( k > 0 ) {
      this.infoRemainText.setText( 'TIME REMAINING UNTIL THE TOURNAMENT BEGINS' );
      this.infoRemainText.scale.set( 0.8 );
      this.infoRemainText.y = this.game.height * 0.75 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.008 * k;
      this.daysNumText.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.daysNum2Text.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.hrsNumText.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.hrs2NumText.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.minsNumText.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.minsNum2Text.getText().y = this.game.height * 0.65 - this.gap - this.game.height * 0.02 * k;
      this.daysText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.025 * k;
      this.hrsText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.025 * k;
      this.minsText.y = this.game.height * 0.98 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.025 * k;
      this.divideline2.y = this.game.height * 1 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.026 * k;
      this.lastWinText.y = this.game.height * 1.05 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.032 * k;
      this.rankText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.036 * k;
      this.userIDText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.036 * k;
      this.winPonitsText.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.036 * k;
      this.prizeShare.y = this.game.height * 1.1 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.036 * k;
    }
    k = null;
  }
  _destroyPerviousLastWinnerWithoutTime () {
    if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;
    this.rankInfoObjects.forEach( function ( element, index ) {
      if ( element[ 'rankNum' ] ) element[ 'rankNum' ].alpha = 0;
      if ( element[ 'userIDNum' ] ) element[ 'userIDNum' ].alpha = 0;
      if ( element[ 'winPonitsNum' ] ) element[ 'winPonitsNum' ].alpha = 0;
      if ( element[ 'prizeShareAmount' ] ) element[ 'prizeShareAmount' ].getText().alpha = 0;
    }, this );
    // this.rankInfoObjects = [];
  }
  _updateLastWinnerWithoutTime () {
    this._destroyPerviousLastWinnerWithoutTime();
    let k = server.CONFIG.TOURNAMENT_LASTWINNER.length - 1;
    if ( !this.rankInfoObjects.length )
      for ( let i = 4; i >= 0; i-- ) {
        let rankNumLastNoTime = this.game.make.text( this.game.width * 0.57, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        // this.rankNum = this.game.make.text( this.game.width * 0.57, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 1, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        rankNumLastNoTime.anchor.set( 0.5 );
        rankNumLastNoTime.alpha = 0;
        this.add.existing( rankNumLastNoTime );
        this.localGarbage.push( rankNumLastNoTime );

        let userIDNumLastNoTime = this.game.make.text( this.game.width * 0.65, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        // this.userIDNum = this.game.make.text( this.game.width * 0.65, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 1, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        userIDNumLastNoTime.anchor.set( 0.5 );
        userIDNumLastNoTime.alpha = 0;
        this.add.existing( userIDNumLastNoTime );
        this.localGarbage.push( userIDNumLastNoTime );

        let winPonitsNumLastNoTime = this.game.make.text( this.game.width * 0.75, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 0, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        // this.winPonitsNum = this.game.make.text( this.game.width * 0.75, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 1, { font: 'bold ' + this.game.width * 0.025 + 'px Roboto', fill: '#8F8F8F', align: 'center' } );
        winPonitsNumLastNoTime.anchor.set( 0.5 );
        winPonitsNumLastNoTime.alpha = 0;
        this.add.existing( winPonitsNumLastNoTime );
        this.localGarbage.push( winPonitsNumLastNoTime );
        let userTextGradient = rankNumLastNoTime.context.createLinearGradient( 0, 0, 0, rankNumLastNoTime.height );
        rankNumLastNoTime.fill = userTextGradient;
        userIDNumLastNoTime.fill = userTextGradient;
        winPonitsNumLastNoTime.fill = userTextGradient;
        userTextGradient.addColorStop( 0, '#FA120B' );
        userTextGradient.addColorStop( 0.15, '#FFCB07' );
        userTextGradient.addColorStop( 0.4, '#FFFF56' );
        userTextGradient.addColorStop( 0.5, '#FFFFC5' );
        userTextGradient.addColorStop( 0.7, '#FFA10B' );
        userTextGradient.addColorStop( 0.85, '#B45508' );
        userTextGradient.addColorStop( 1, '#FFA10B' );
        userTextGradient = null;

        let prizeShareAmountLastNoTime = this.game.plugins.add( PluginDecimalText, this.game.width * 0.88, this.game.height * 1.17 - this.adsBG.height * 0.3 - this.gap - this.game.height * 0.045 * ( k ? i : 0.5 ), 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
        prizeShareAmountLastNoTime.getText().anchor.set( 0.5 );
        prizeShareAmountLastNoTime.getText().scale.set( 0.2 );
        prizeShareAmountLastNoTime.getText().alpha = 0;
        // prizeShareAmountLastNoTime.setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'prize' ] / 100, false );
        // this.prizeShareAmount.setText( 1, false );
        this.add.existing( prizeShareAmountLastNoTime.getText() );

        this.add.existing( rankNumLastNoTime );
        this.add.existing( userIDNumLastNoTime );
        this.add.existing( winPonitsNumLastNoTime );

        this.rankInfoObjects.push( { 'rankNum': rankNumLastNoTime, 'userIDNum': userIDNumLastNoTime, 'winPonitsNum': winPonitsNumLastNoTime, 'prizeShareAmount': prizeShareAmountLastNoTime } );
      }

    for ( let i = k; i >= 0; i-- ) {
      if ( this.rankInfoObjects[ 4 - i ][ 'rankNum' ].alpha === 0 ) {
        this.rankInfoObjects[ 4 - i ][ 'rankNum' ].alpha = 1;
        this.rankInfoObjects[ 4 - i ][ 'userIDNum' ].alpha = 1;
        this.rankInfoObjects[ 4 - i ][ 'winPonitsNum' ].alpha = 1;
        this.rankInfoObjects[ 4 - i ][ 'prizeShareAmount' ].getText().alpha = 1;
        if ( !server.isShowingPopup ) {
          this.game.world.bringToTop( this.rankInfoObjects[ 4 - i ][ 'rankNum' ] );
          this.game.world.bringToTop( this.rankInfoObjects[ 4 - i ][ 'userIDNum' ] );
          this.game.world.bringToTop( this.rankInfoObjects[ 4 - i ][ 'winPonitsNum' ] );
          this.game.world.bringToTop( this.rankInfoObjects[ 4 - i ][ 'prizeShareAmount' ].getText() );
        }
      }
      this.rankInfoObjects[ 4 - i ][ 'rankNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'rank' ] );
      this.rankInfoObjects[ 4 - i ][ 'userIDNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'playerNumber' ] );
      this.rankInfoObjects[ 4 - i ][ 'winPonitsNum' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'winAmount' ] );
      this.rankInfoObjects[ 4 - i ][ 'prizeShareAmount' ].setText( server.CONFIG.TOURNAMENT_LASTWINNER[ i ][ 'prize' ] / 100, false );
    }
    if ( k === 0 || k === 1 ) {
      this.infoText1.y = this.game.height * 0.8 - this.adsBG.height * 0.3 - this.gap;
      this.infoText2.y = this.game.height * 0.85 - this.adsBG.height * 0.3 - this.gap;
      this.infoText3.y = this.game.height * 0.9 - this.adsBG.height * 0.3 - this.gap;
      this.divideline1.y = this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap;
      this.infoText4.y = this.game.height * 0.99 - this.adsBG.height * 0.3;
      this.rankText.y = this.game.height * 1.05 - this.adsBG.height * 0.3;
      this.userIDText.y = this.game.height * 1.05 - this.adsBG.height * 0.3;
      this.winPonitsText.y = this.game.height * 1.05 - this.adsBG.height * 0.3;
      this.prizeShare.y = this.game.height * 1.05 - this.adsBG.height * 0.3;
    } else if ( k === 2 ) {
      this.infoText1.y = this.game.height * 0.8 - this.adsBG.height * 0.3 - this.gap;
      this.infoText2.y = this.game.height * 0.85 - this.adsBG.height * 0.3 - this.gap;
      this.infoText3.y = this.game.height * 0.9 - this.adsBG.height * 0.3 - this.gap;
      this.divideline1.y = this.game.height * 0.93 - this.adsBG.height * 0.3 - this.gap;
      this.infoText4.y = this.game.height * 0.95 - this.adsBG.height * 0.3;
      this.rankText.y = this.game.height * 1 - this.adsBG.height * 0.3;
      this.userIDText.y = this.game.height * 1 - this.adsBG.height * 0.3;
      this.winPonitsText.y = this.game.height * 1 - this.adsBG.height * 0.3;
      this.prizeShare.y = this.game.height * 1 - this.adsBG.height * 0.3;
    } else if ( k === 3 ) {
      this.infoText1.y = this.game.height * 0.8 - this.adsBG.height * 0.3 - this.gap;
      this.infoText2.y = this.game.height * 0.84 - this.adsBG.height * 0.3 - this.gap;
      this.infoText3.y = this.game.height * 0.88 - this.adsBG.height * 0.3 - this.gap;
      this.divideline1.y = this.game.height * 0.91 - this.adsBG.height * 0.3 - this.gap;
      this.infoText4.y = this.game.height * 0.92 - this.adsBG.height * 0.3;
      this.rankText.y = this.game.height * 0.96 - this.adsBG.height * 0.3;
      this.userIDText.y = this.game.height * 0.96 - this.adsBG.height * 0.3;
      this.winPonitsText.y = this.game.height * 0.96 - this.adsBG.height * 0.3;
      this.prizeShare.y = this.game.height * 0.96 - this.adsBG.height * 0.3;
    } else if ( k === 4 ) {
      this.infoText1.y = this.game.height * 0.79 - this.adsBG.height * 0.3 - this.gap;
      this.infoText2.y = this.game.height * 0.825 - this.adsBG.height * 0.3 - this.gap;
      this.infoText3.y = this.game.height * 0.86 - this.adsBG.height * 0.3 - this.gap;
      this.divideline1.y = this.game.height * 0.88 - this.adsBG.height * 0.3 - this.gap;
      this.infoText4.y = this.game.height * 0.88 - this.adsBG.height * 0.3;
      this.rankText.y = this.game.height * 0.92 - this.adsBG.height * 0.3;
      this.userIDText.y = this.game.height * 0.92 - this.adsBG.height * 0.3;
      this.winPonitsText.y = this.game.height * 0.92 - this.adsBG.height * 0.3;
      this.prizeShare.y = this.game.height * 0.92 - this.adsBG.height * 0.3;
    }
    k = null;
  }

  _destroyPerviousOtherData () {
    if ( this.userTextGradient ) this.userTextGradient = null; delete this.userTextGradient;

    this.otherRanksObjects.forEach( function ( element, index ) {
      if ( element[ 'userRank' ] ) element[ 'userRank' ].alpha = 0;
      if ( element[ 'userText' ] ) element[ 'userText' ].alpha = 0;
      if ( element[ 'userScoreText' ] ) element[ 'userScoreText' ].alpha = 0;
      if ( element[ 'prizeAmountLive' ] ) element[ 'prizeAmountLive' ].alpha = 0;
    }, this );
    // this.otherRanksObjects = [];
  }
  _updateOtherData () {
    if ( this.currentAds !== 5 ) return;
    if ( this.otherRanksObjects )
      if ( !this.otherRanksObjects.length ) {
        let bounds = new Phaser.Rectangle();
        bounds.x = this.game.width * 0.7;
        bounds.y = this.game.height * 0.8 - this.gap;
        bounds.width = this.game.width * 0.9;
        bounds.height = this.game.height * 0.3;

        for ( let i = 0; i < 5; i++ ) {
          // rank text
          let rankUserTextLive = this.game.make.text(
            bounds.width * 0.63,
            bounds.height * ( 2.15 + 0.2 * i ) - this.gap,
            ( i + 1 ),
            { font: this.game.width * 0.025 + 'px Open Sans ', fill: '#fff', align: 'center' }
          );
          rankUserTextLive.anchor.set( 0.5 );
          this.add.existing( rankUserTextLive );

          // user name
          let nameUserTextLive = this.game.make.text(
            bounds.width * 0.72,
            bounds.height * ( 2.15 + 0.2 * i ) - this.gap,
            'nickname',
            { font: this.game.width * 0.025 + 'px Open Sans', fill: '#fff', align: 'center' }
          );
          nameUserTextLive.anchor.set( 0.5 );
          this.add.existing( nameUserTextLive );

          // user score
          let scoreUserTextLive = this.game.make.text(
            bounds.width * 0.85,
            bounds.height * ( 2.15 + 0.2 * i ) - this.gap,
            ( 1000 + i ),
            { font: this.game.width * 0.025 + 'px Open Sans', fill: '#fff', align: 'center' }
          );
          scoreUserTextLive.anchor.set( 0.5 );
          this.add.existing( scoreUserTextLive );

          let prizeAmountLive = this.game.plugins.add( PluginDecimalText, bounds.width * 0.98, bounds.height * ( 2.15 + 0.2 * i ) - this.gap, 0, null, 'TournamentWinFont', this.game.width * 0.06, '$' );
          prizeAmountLive.getText().anchor.set( 0.5 );
          prizeAmountLive.getText().scale.set( 0.2 );
          prizeAmountLive.setText( server.CONFIG.TOURNAMENT_PRIZE_List[ i ], false );
          this.add.existing( prizeAmountLive.getText() );

          if ( server.CONFIG.TOURNAMENT_PRIZE_List[ i ] ) prizeAmountLive.getText().alpha = 1;
          else prizeAmountLive.getText().alpha = 0;

          rankUserTextLive.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
          nameUserTextLive.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );
          scoreUserTextLive.setShadow( -2, 2, 'rgba(0,0,0,0.5)', 0 );

          let userTextGradient = rankUserTextLive.context.createLinearGradient( 0, 0, 0, rankUserTextLive.height );
          if ( server.CONFIG.TOURNAMENT_PRIZE_List[ i ] ) {
            rankUserTextLive.fill = userTextGradient;
            nameUserTextLive.fill = userTextGradient;
            scoreUserTextLive.fill = userTextGradient;
            userTextGradient.addColorStop( 0, '#FA120B' );
            userTextGradient.addColorStop( 0.15, '#FFCB07' );
            userTextGradient.addColorStop( 0.4, '#FFFF56' );
            userTextGradient.addColorStop( 0.5, '#FFFFC5' );
            userTextGradient.addColorStop( 0.7, '#FFA10B' );
            userTextGradient.addColorStop( 0.85, '#B45508' );
            userTextGradient.addColorStop( 1, '#FFA10B' );
          } else {
            userTextGradient = rankUserTextLive.context.createLinearGradient( 0, 0, 0, rankUserTextLive.height );
            rankUserTextLive.fill = userTextGradient;
            nameUserTextLive.fill = userTextGradient;
            scoreUserTextLive.fill = userTextGradient;
            userTextGradient.addColorStop( 0, '#898989' );
            userTextGradient.addColorStop( 0.2, '#E0E0E0' );
            userTextGradient.addColorStop( 0.5, '#FCFCFC' );
            userTextGradient.addColorStop( 0.1, '#898989' );
          }
          userTextGradient = null;

          this.otherRanksObjects.push( { 'userText': nameUserTextLive, 'userScoreText': scoreUserTextLive, 'userRank': rankUserTextLive, 'prizeAmountLive': prizeAmountLive } );

          this.localGarbage.push( rankUserTextLive );
          this.localGarbage.push( nameUserTextLive );
          this.localGarbage.push( scoreUserTextLive );
          this.localGarbage.push( prizeAmountLive );
        }
        bounds = null;
      }

    for ( let j = 0; j < server.CONFIG.TOURNAMENT_PRIZE_List.length; j++ )
      if ( server.CONFIG.TOURNAMENT_PRIZE_List[ j ] ) {
        // if ( this.otherRanksObjects[ j ][ 'prizeAmountLive' ].getText().alpha === 0 ) this.game.world.bringToTop( this.otherRanksObjects[ j ][ 'prizeAmountLive' ].getText() );
        if ( !server.isShowingPopup ) this.game.world.bringToTop( this.otherRanksObjects[ j ][ 'prizeAmountLive' ].getText() );
        this.otherRanksObjects[ j ][ 'prizeAmountLive' ].getText().alpha = 1;
        this.otherRanksObjects[ j ][ 'prizeAmountLive' ].setText( server.CONFIG.TOURNAMENT_PRIZE_List[ j ], false );
      } else this.otherRanksObjects[ j ][ 'prizeAmountLive' ].getText().alpha = 0;

    for ( let i = 0; i < 5; i++ ) {
      let exists = false;
      if ( server.CONFIG.OTHERRANKS )
        exists = i < server.CONFIG.OTHERRANKS.length;
      let elementData = exists ? server.CONFIG.OTHERRANKS[ i ] : null;
      // if no data
      if ( !elementData ) {
        this.otherRanksObjects[ i ][ 'userRank' ].alpha = 0;
        this.otherRanksObjects[ i ][ 'userText' ].alpha = 0;
        this.otherRanksObjects[ i ][ 'userScoreText' ].alpha = 0;
        continue;
      }

      let isCurrentUser = false;
      // set rank
      this.otherRanksObjects[ i ][ 'userRank' ].setText( elementData[ 'rank' ] );
      this.otherRanksObjects[ i ][ 'userRank' ].fontSize = this.game.width * ( isCurrentUser ? 0.03 : 0.025 ) + 'px';
      // set Name
      this.otherRanksObjects[ i ][ 'userText' ].setText( elementData[ 'playerNumber' ] );
      this.otherRanksObjects[ i ][ 'userText' ].fontSize = this.game.width * ( isCurrentUser ? 0.03 : 0.025 ) + 'px';
      // set Value
      this.otherRanksObjects[ i ][ 'userScoreText' ].setText( elementData[ 'winAmount' ] );
      this.otherRanksObjects[ i ][ 'userScoreText' ].fontSize = this.game.width * ( isCurrentUser ? 0.03 : 0.025 ) + 'px';

      if ( this.otherRanksObjects[ i ][ 'userRank' ].alpha === 0 ) {
        this.otherRanksObjects[ i ][ 'userRank' ].alpha = 1;
        this.otherRanksObjects[ i ][ 'userText' ].alpha = 1;
        this.otherRanksObjects[ i ][ 'userScoreText' ].alpha = 1;
        if ( !server.isShowingPopup ) {
          this.game.world.bringToTop( this.otherRanksObjects[ i ][ 'userRank' ] );
          this.game.world.bringToTop( this.otherRanksObjects[ i ][ 'userText' ] );
          this.game.world.bringToTop( this.otherRanksObjects[ i ][ 'userScoreText' ] );
        }
      }

      exists = null;
      isCurrentUser = null;
      elementData = null;
    }
  }

  _updateTourInfo () {
    if ( server.refreshTokenTimerIndex > 5 || server.failLoadingTime > 4 ) {
      // show the popup
      if ( this.unavailableImageBG ) {
        this.unavailableImageBG.alpha = 1;
        if ( !server.isShowingPopup ) this.game.world.bringToTop( this.unavailableImageBG );
      }
      if ( this.unavailableImage ) {
        this.unavailableImage.alpha = 1;
        if ( !server.isShowingPopup ) this.game.world.bringToTop( this.unavailableImage );
      }
    } else {
      // hide the popup
      if ( this.unavailableImage ) this.unavailableImage.alpha = 0;
      if ( this.unavailableImageBG ) this.unavailableImageBG.alpha = 0;
    }
    this._updateOtherData();
    if ( this.currentAds === this.ads.URLIMAGE )
      if ( this.previousURL !== server.CONFIG.TOURNAMENT_URLLINK && this.btnURL ) {
        this.previousURL = server.CONFIG.TOURNAMENT_URLLINK;
        server.preloadExternalImage( this.game, 'externalImg', this.previousURL, function ( cacheKey ) {
          if ( !this.game.cache.checkImageKey( cacheKey ) ) {
            this.btnURL.setFrames( 'test.png', 'test.png', 'test.png', 'test.png' );
            this.btnURL.scale.set( 1 );
            return;
          }
          this.btnURL.loadTexture( cacheKey );
          this.btnURL.scale.set( this.game.width * 0.001 * 0.5 );
        }, this );
      }
    if ( server.CONFIG.TOURNAMENT_PRIZE === 0 ) return;
    let prize = 0;
    if ( this.prizeAmountText ) {
      prize = server.CONFIG.TOURNAMENT_PRIZE;
      this.prizeAmountText.setText( prize );
    }

    if ( this.currentAds === 3 || this.currentAds === 5 )
      if ( prize > 0 && prize < 10 ) {
        if ( this.prizeAmountText ) this.prizeAmountText.getText().x = this.game.width * 0.68;
        if ( this.adsBigText ) this.adsBigText.x = this.game.width * 0.81;
      } else if ( prize >= 10 && prize < 100 ) {
        if ( this.prizeAmountText ) this.prizeAmountText.getText().x = this.game.width * 0.68;
        if ( this.adsBigText ) this.adsBigText.x = this.game.width * 0.83;
      } else if ( prize >= 100 && prize < 1000 ) {
        if ( this.prizeAmountText ) this.prizeAmountText.getText().x = this.game.width * 0.67;
        if ( this.adsBigText ) this.adsBigText.x = this.game.width * 0.84;
      } else if ( prize >= 1000 && prize < 10000 ) {
        if ( this.prizeAmountText ) this.prizeAmountText.x = this.game.width * 0.665;
        if ( this.adsBigText ) this.adsBigText.x = this.game.width * 0.86;
      } else {
        if ( this.prizeAmountText ) this.prizeAmountText.x = this.game.width * 0.66;
        if ( this.adsBigText ) this.adsBigText.x = this.game.width * 0.877;
      }

    prize = null;
    if ( this.infoText && this.time !== server.CONFIG.TOURNAMENT_STARTTIME ) {
      let monthNames = [
        'JANUARY', 'FEBRUARY', 'MARCH',
        'APRIL', 'MAY', 'JUNE', 'JULY',
        'AUGUST', 'SEPTEMBER', 'OCTOBER',
        'NOVEMBER', 'DECEMBER'
      ];

      let splits = server.CONFIG.TOURNAMENT_STARTTIME.split( '-' );
      let month = parseInt( splits[ 1 ], 10 ) - 1;
      let day = splits[ 2 ].split( 'T' )[ 0 ];

      this.time = server.CONFIG.TOURNAMENT_STARTTIME;
      let dateString = ' ' + monthNames[ month ] + ', ' + day + '.';
      this.infoText.setText( 'ON ' + dateString );

      monthNames = null;
      splits = null;
      month = null;
      day = null;
      dateString = null;
    }
  }

  _updateGameIcon () {
    if ( !this.gameIcon ) return;

    let iconID = parseInt( this.gameIcon.data );
    iconID++; if ( iconID >= this.gameIconsList.length ) iconID = 0;
    this.gameIcon.loadTexture( 'GameIcons', this.gameIconsList[ iconID ] );
    this.gameIcon.data = iconID;
    iconID = null;
  }

  _updateJackpotStatuses () {
    this.HideOrShowLeaderboardFireAnimation( server.isAnticipation );
    this._updateGameIcon();
    if ( server.isRoyal ) {
      let currentRoyalAmount = Math.floor( server.CONFIG.JACKPOT_STATUS[ 'waJP' ] ) / 100;
      if ( !server.CONFIG.JACKPOT_STATUS.hasOwnProperty( 'waJP' ) )
        server.logoutFull( this.game );
      else
        this.royalTextPlugin.updateTo( currentRoyalAmount ); // Change this line when BE providing royalAmount
      if ( currentRoyalAmount < 10000 ) this.royalTextPlugin.text.fontSize = this.game.width * 0.034 + 'px';
      else if ( currentRoyalAmount < 100000 ) this.royalTextPlugin.text.fontSize = this.game.width * 0.032 + 'px';
      else if ( currentRoyalAmount < 1000000 ) this.royalTextPlugin.text.fontSize = this.game.width * 0.028 + 'px';
      else this.royalTextPlugin.text.fontSize = this.game.width * 0.025 + 'px';
    }
    this.grandTextPlugin.updateTo( Math.floor( server.CONFIG.JACKPOT_STATUS[ 'grandAmount' ] ) / 100 );
    this.majorTextPlugin.updateTo( Math.floor( server.CONFIG.JACKPOT_STATUS[ 'personalJP' ] ) / 100 ); // single now
    this.minorTextPlugin.updateTo( Math.floor( server.CONFIG.JACKPOT_STATUS[ 'minorAmount' ] ) / 100 );

    if ( this.jackpotsTimer ) {
      this.jackpotsTimer.stop( true );
      this.jackpotsTimer.destroy( true );
      this.jackpotsTimer = null;
    }
    this.jackpotsTimer = this.game.time.create( true );
    this.jackpotsTimer.add( Phaser.Timer.SECOND * server.CONFIG.JACKPOT_STATUS_UPDATE_TIME, this._updateJackpotStatuses, this );
    this.jackpotsTimer.start();
  }
  _setupSparklesAnimation () {
    if ( server.projectType === 2 ) {
      if ( this.video ) { this.video.stop(); this.video.destroy( true ); } this.video = null;
      this.video = this.game.add.video( 'BGVideo' );
      this.video.video.setAttribute( 'webkit-playsinline', 'webkit-playsinline' );
      this.video.video.setAttribute( 'playsinline', 'playsinline' );
      this.video.video.muted = true;
      this.videoSprite = this.video.addToWorld( this.game.world.centerX, this.game.world.centerY, 0.5, 0.5, this.game.width / this.video.width, this.game.height / this.video.height );
      this.video.play( true );
      this.localGarbage.push( this.video );
      this.localGarbage.push( this.videoSprite );
    } else {
      let animationSprite = this.game.make.sprite( 0, 0, 'SparklesAnimation' );
      animationSprite.scale.set( this.game.width / animationSprite.width, this.game.height / animationSprite.height );
      let framesAmount = this.game.cache.getFrameCount( 'SparklesAnimation' );
      let frames = Phaser.Animation.generateFrameNames( 'SparklesAnimation_', 0, framesAmount, '.png', 4 );
      animationSprite.animations.add( 'SparklesAnimation', frames, 30 );
      animationSprite.animations.play( 'SparklesAnimation', framesAmount * 0.5, true );
      animationSprite.animations.currentAnim.onComplete.add( function () { animationSprite.destroy( true ); animationSprite = null; }, this );
      this.add.existing( animationSprite );
      this.localGarbage.push( animationSprite );
    }

    // let animationSprite = this.game.make.sprite( 0, 0, 'SparklesAnimation' );
    // animationSprite.scale.set( this.game.width / animationSprite.width, this.game.height / animationSprite.height );
    // let framesAmount = this.game.cache.getFrameCount( 'SparklesAnimation' );
    // let frames = Phaser.Animation.generateFrameNames( 'SparklesAnimation_', 0, framesAmount, '.png', 4 );
    // animationSprite.animations.add( 'SparklesAnimation', frames, 30 );
    // animationSprite.animations.play( 'SparklesAnimation', framesAmount * 0.5, true );
    // animationSprite.animations.currentAnim.onComplete.add( function () { animationSprite.destroy( true ); animationSprite = null; }, this );
    // this.add.existing( animationSprite );
    // this.localGarbage.push( animationSprite );
  }
  descreasTimer () {
    let diff = 1000;
    if ( server.CONFIG.TOURNAMENT_TIMELEFT < 1000 ) diff = server.CONFIG.TOURNAMENT_TIMELEFT;
    server.CONFIG.TOURNAMENT_TIMELEFT = parseInt( server.CONFIG.TOURNAMENT_TIMELEFT ) - ( diff || 0 );
    diff = null;
  }
  _setupTimer () {
    if ( server.CONFIG.TOURNAMENT_TIMELEFT === 0 ) return;
    let time = server.CONFIG.TOURNAMENT_TIMELEFT;

    let dd = Math.floor( time / 1000 / 60 / 60 / 24 ); time -= dd * 1000 * 60 * 60 * 24;
    this.daysNum = ( '0' + dd ).slice( -2 );

    let hh = Math.floor( time / 1000 / 60 / 60 ); time -= hh * 1000 * 60 * 60;
    this.hrsNum = ( '0' + hh ).slice( -2 );

    let mm = Math.floor( time / 1000 / 60 ); time -= mm * 1000 * 60;
    this.minsNum = ( '0' + mm ).slice( -2 );

    if ( this.daysNumText ) this.daysNumText.setText( this.daysNum ? this.daysNum.substring( 0, 1 ) : 0, false );
    if ( this.daysNum2Text ) this.daysNum2Text.setText( this.daysNum ? this.daysNum.substring( 1, 2 ) : 0, false );
    if ( this.hrsNumText ) this.hrsNumText.setText( this.hrsNum ? this.hrsNum.substring( 0, 1 ) : 0, false );
    if ( this.hrs2NumText ) this.hrs2NumText.setText( this.hrsNum ? this.hrsNum.substring( 1, 2 ) : 0, false );
    if ( this.minsNumText ) this.minsNumText.setText( this.minsNum ? this.minsNum.substring( 0, 1 ) : 0, false );
    if ( this.minsNum2Text ) this.minsNum2Text.setText( this.minsNum ? this.minsNum.substring( 1, 2 ) : 0, false );

    time = null;
    dd = null;
    hh = null;
    mm = null;
  }
}
